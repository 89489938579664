<template>
    <v-card class="pa-2">
        <v-card-title class="justify-center">Work In Progress</v-card-title>
        <v-card-text class="text-center">
            <v-icon size="128" class="nolo-dark-grey--text">mdi-close</v-icon>
            <div class="nolo-subtitle pt-6">This area is incomplete</div>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        name: "Wip"
    }
</script>

<style scoped>

</style>

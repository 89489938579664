const FULFILMENT_TYPE_DELIVERY = 'Delivery';
const FULFILMENT_TYPE_COLLECTION = 'Collection';

const MAX_STORE_RESULTS = 8;
const MAX_DISTANCE_ALLOWED = 60;

import CalcDistance from '../components/Maps/CalcDistance';
import PointInPolygon from 'robust-point-in-polygon';

function storesWithDist(allStores, customerLocation) {
    let result = [];

    for (let s in allStores) {
        let el = JSON.parse(JSON.stringify(allStores[s]));
        el.distance = CalcDistance.getDistanceFromLatLonInKm(customerLocation, el.location);
        result.push(el);
    }
    return result;
}

function storeCanDeliver(store) {
    return store.fulfilment_types.indexOf(FULFILMENT_TYPE_DELIVERY) >= 0;
}

function storeCanCollect(store) {
    return store.fulfilment_types.indexOf(FULFILMENT_TYPE_COLLECTION) >= 0;
}

function storesCanDeliver(stores, deliveryLocation) {
    let can = false;
    for (let s in stores) {
        let canDeliver = storeCanDeliver(stores[s]);
        if (canDeliver) {
            let inArea = false;
            // are we in the delivery zone though?
            for (let i in stores[s].deliveryArea) {
                if (!Array.isArray(stores[s].deliveryArea[i]) || !stores[s].deliveryArea[i].length) {
                    // not a delivery area
                } else {
                    inArea |= (PointInPolygon(stores[s].deliveryArea[i], [deliveryLocation.lat, deliveryLocation.lng]) <= 0);
                }
            }
            canDeliver &= inArea;
        }
        can |= canDeliver;
    }
    return can;
}

function storesCanCollect(stores) {
    let can = false;
    for (let s in stores) {
        can |= storeCanCollect(stores[s]);
    }
    return can;
}

// eslint-disable-next-line no-unused-vars
function isLocationInsideDeliveryArea(store, location) {

    // TODO implement this

    // function isMarkerInsidePolygon(marker, poly) {
    //     var polyPoints = poly.getLatLngs();
    //     var x = marker.getLatLng().lat, y = marker.getLatLng().lng;
    //
    //     var inside = false;
    //     for (var i = 0, j = polyPoints.length - 1; i < polyPoints.length; j = i++) {
    //         var xi = polyPoints[i].lat, yi = polyPoints[i].lng;
    //         var xj = polyPoints[j].lat, yj = polyPoints[j].lng;
    //
    //         var intersect = ((yi > y) != (yj > y))
    //             && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
    //         if (intersect) inside = !inside;
    //     }
    //
    //     return inside;
    // };

    return true;
}

function storesByDistance(allStores, customerLocation, count) {
    return storesWithDist(allStores, customerLocation)
        .filter(el => {
            if (el.name === 'Acrelec Lab') return false;
            if (el.invisible == true) return false;
            return el.distance !== null && el.distance < MAX_DISTANCE_ALLOWED;
        })
        .sort((a, b) => {
            return a.distance > b.distance ? 1 : (
                a.distance < b.distance ? -1 : 0
            );
        })
        .slice(0, count);
}

export default {
    storesByDistance: storesByDistance,
    storeCanDeliver: storeCanDeliver,
    storeCanCollect: storeCanCollect,
    storesCanDeliver: storesCanDeliver,
    storesCanCollect: storesCanCollect,
    isLocationInsideDeliveryArea: isLocationInsideDeliveryArea,
    FULFILMENT_TYPE_DELIVERY: FULFILMENT_TYPE_DELIVERY,
    FULFILMENT_TYPE_COLLECTION: FULFILMENT_TYPE_COLLECTION,
    MAX_STORE_RESULTS: MAX_STORE_RESULTS
}

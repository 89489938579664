<template>
    <v-container class="d-flex justify-center">
        <v-row no-gutters class="inbound-container">
            <v-col cols="12" class="text-center text-md-left">
                <div class="nolo-h3 white--text text-center text-md-left">{{ $t('readyToOrder')}}</div>
                <v-btn block large color="white primary--text" class="nolo-white-button elevation-0 my-8"
                       @click="continueWithNOLOOrdering()">
                    {{ $t('orderType.Collection')}}
                </v-btn>
                <a :href="deliverooLink" @click="deliverooOpenExternal" class="text-decoration-none" target="_blank">
                    <v-btn block large color="white deliveroo--text" class="my-8 elevation-0">
                        {{ $t('btn.deliveroo') }}
                    </v-btn>
                </a>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import APINoquApp from "../../api/APINoquApp";
    import Gtag from "../../mixins/Gtag";

    export default {
        name: "StartOrder",
        mixins: [Gtag],
        data() {
            return {
                loading: false,
                postcode: ''
            }
        },
        mounted() {
            this.clearBasket();
        },
        methods: {
            deliverooOpenExternal() {
                this.gtagDeliverooEvent();

                APINoquApp.openExternalUrl(this, this.$t('url.deliveroo'));
            },
            continueWithNOLOOrdering() {
                this.$router.push('/store-list')

            }
        },
        computed: {
            deliverooLink() {
                return this.isAppMode && APINoquApp.isInNoquAndroidApp()  ? '' : this.$t('url.deliveroo');
            }
        }
    }
</script>

<style>
    .theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined).nolo-white-button {
        background-color: white !important;
    }
</style>

<style scoped>


</style>

<template>
    <page-layout :loading="loading">
        <v-container>
            <v-row class="justify-center">
                <v-col cols="12" class="account-container">
                    <p><a @click="goBack()" v-if="back" class="mb-8 secondary--text font-weight-bold"><v-icon class="mr-0">mdi-chevron-left</v-icon>{{ back }}</a></p>
                    <p v-if="title" class="nolo-h3 text-center text-sm-left mb-6 pl-sm-3">{{ title }}</p>
                    <slot></slot>
                </v-col>
            </v-row>
        </v-container>
    </page-layout>
</template>

<script>
    export default {
        name: "PageLayoutNolo",
        props: {
            loading: {type: Boolean, default: false},
            title: {type: String, default: ''},
            back: {type: String, default: '' }
        },
        methods: {
            goBack() {
                this.$router.back();
            }
        }
    }
</script>

<style scoped>

</style>

import _ApiHelper from "./_ApiHelper";
import Constants from "./Constants";

const OrderProgress = {
    OrderProgress: {
        INBOUND: 0,
        MENU: 10,
        CHECKOUT: 20,
        PAYMENT: 30,
        COMPLETING: 40,
        FAILED: 41,
        COMPLETE: 42,
    }
};

function getProgressText(progress) {

    let result = '#' + progress;

    Object.keys(OrderProgress.OrderProgress).forEach(key => {
        if (OrderProgress.OrderProgress[key] === progress) {
            result = key;
        }
    })

    return result;

}


/*
{"stage_id":2,"stage_name":"Accepted","stage_desc":"Your order has been accepted by the store","order_type":"Collection","journey":[3,2,5,1],"tube_id":1,"order_status_id":5,"fulfilment_datetime":"Today, 11:35","mins_to_complete":9}

https://fiveguysui.lineten.net/index.php?route=account/order/get_stage/preparing&order_id=172425
 */

function getFlatMenu(vue, storeId, orderType) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'GET',
                url: '/apiui/order/flatmenu/' + encodeURI(storeId) + '/' + encodeURI(orderType),
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

function getOrderStatus(vue, orderId) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'GET',
                url: '/apiui/order/orderStatus?order_id=' + encodeURI(orderId),
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

function establishSession(vue, recaptchaToken) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/order/establishSession',
                data: {
                    session: vue.$store.getters['session/all'],
                    urls: {
                        back: window.location.origin + '/#' + vue.$router.resolve({name: 'order'}).route.fullPath,
                        success: window.location.origin + '/#' + vue.$router.resolve({name: 'checkout-payment-success'}).route.fullPath,
                        fail: window.location.origin + '/#' + vue.$router.resolve({name: 'checkout-payment-fail'}).route.fullPath,
                    },
                    recaptchaToken: recaptchaToken
                },
                headers: _ApiHelper.httpHeadersWithToken(vue)
            })
            .then(function (response) {
                _ApiHelper.sessionTracker(vue, response);
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

// eslint-disable-next-line no-unused-vars
function handoffToPayment(vue) {            // set order progress
    vue.$store.commit('session/orderProgress', OrderProgress.OrderProgress.PAYMENT);

    window.location = vue.$baseUrl + '/apiui/order/handoffToPayment?nolo_session=' + vue.$store.getters['session/sessionId'];
}

function isValidOrderType(orderType) {
    return typeof Constants.OrderType[orderType] !== 'undefined';
}

function isInboundComplete(store) {

    if (store.getters['session/storeId'] === null) return false;
    if (store.getters['session/fulfilmentType'] === null) return false;
    if (store.getters['session/orderType'] === null) return false;

    return true;
}

function isNothingToAskForAtCheckout(store) {

    // if we aren't logged in we'll ask for guest details
    if (store.getters['session/token'] === null) return false;

    // if it's curbside we'll ask for vehicle
    if (store.getters['session/orderType'] === Constants.OrderType.Curbside) return false;

    // otherwise we'll not be asking for anything
    return true;
}

export default {
    Constants: {
        ...OrderProgress,
        OrderStage: {
            AWAITING_ACCEPT: 3,
            ACCEPTED: 2,
            COMPLETE: 1,
            PREPARING: 5,
            REJECTED: 4,
            REFUNDED: 6,
            CANCELLED: 7
        },
        OrderStatus: {
            COMPLETE: 5,
            DENIED: 8
        },
        OrderType: Constants.OrderType
            },
    getFlatMenu: getFlatMenu,
    establishSession: establishSession,
    handoffToPayment: handoffToPayment,
    getOrderStatus: getOrderStatus,
    getProgressText: getProgressText,
    isInboundComplete: isInboundComplete,
    isNothingToAskForAtCheckout: isNothingToAskForAtCheckout,
    isValidOrderType: isValidOrderType
}

import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app'
import handoff from './modules/handoff'
import session from './modules/session'
import layout from './modules/layout'

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    app,
    session,
    layout,
    handoff
  },
  strict: debug
})

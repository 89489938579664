import { render, staticRenderFns } from "./StoresMap.vue?vue&type=template&id=2b28f9c6&scoped=true&"
import script from "./StoresMap.vue?vue&type=script&lang=js&"
export * from "./StoresMap.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b28f9c6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})

import VueI18n from 'vue-i18n';
import locale from './locales/language';

const dateTimeFormats = {
    'en': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'fr': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
    'de': {
        short: {
            year: 'numeric', month: 'short', day: 'numeric'
        },
        long: {
            year: 'numeric', month: 'short', day: 'numeric',
            weekday: 'short', hour: 'numeric', minute: 'numeric'
        }
    },
};

const numberFormats = {
    'en': {
        currency: {
            style: 'currency', currency: 'GBP'
        },
        currencyNoDecimal: {
            style: 'currency', currency: 'GBP', minimumFractionDigits: 0, maximumFractionDigits: 0
        }
    },
    'de': {
        currency: {
            style: 'currency', currency: 'EUR'
        },
        currencyNoDecimal: {
            style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0
        }
    },
    'fr': {
        currency: {
            style: 'currency', currency: 'EUR'
        },
        currencyNoDecimal: {
            style: 'currency', currency: 'EUR', minimumFractionDigits: 0, maximumFractionDigits: 0
        }
    }
};

// now languages, load the language if it's defined in the VUE_APP_LOCALE otherwise load "en"
// the english language will be replaced with the locale when we load the app/config api call.

const i18n = new VueI18n({
    locale: process.env.VUE_APP_LOCALE || 'en', // set locale
    numberFormats,
    fallbackLocale: 'en',
    dateTimeFormats,
    messages: locale, // set locale messages
});

export {
    i18n
}

import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n);

const messages = {
    en: require('./en.json'),
    es: require('./es.json'),
    fr: require('./fr.json'),
    de: require('./de.json')
};

export default messages;

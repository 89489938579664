<template>
    <v-overlay :color="background" :opacity="0.70" :value="value" :z-index="10">
        <v-container class="text-center">
            <lottie-animation path="./_/img/burger-loop.json"
                              :width="192"
                              :height="192" />
            <div class="nolo-h6 mt-8 mx-8 black--text">{{ message }}</div>
            <div class="nolo-p1 mt-8 mx-8 black--text">{{ subMessage }}</div>
        </v-container>
    </v-overlay>

</template>

<script>
    import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

    export default {
        name: "BouncingBurgerOverlay",
        components: { LottieAnimation },
        props: {
            value: { type: Boolean, required: true },
            message: { type: String, default: '' },
            subMessage: { type: String, default: '' },
            background: { type: String, default: 'white' }
        }
    }
</script>

<style scoped>

</style>

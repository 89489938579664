<template>
    <v-layout column :class="colorAndPadding" class="fill-height checkerboard-top">
        <BouncingBurgerOverlay :value="loading"/>
        <div v-if="!loading">
            <div class="mt-3"><a @click="goBack()" v-if="back" class="secondary--text font-weight-bold">
                <v-icon class="mr-0">mdi-chevron-left</v-icon>
                {{ back }}</a></div>
            <slot></slot>
        </div>
    </v-layout>
</template>

<script>
    import BouncingBurgerOverlay from "../UI/BouncingBurgerOverlay";

    export default {
        name: "PageLayout",
        components: {BouncingBurgerOverlay},
        props: {
            color: {type: String, default: 'nolo-light-grey'},
            appLike: {type: Boolean, default: false},
            loading: {type: Boolean, default: false},
            back: {type: String, default: ''}
        },
        watch: {
            appLike: {
                immediate: true,
                handler(newValue) {
                    this.$store.commit('layout/appLike', newValue);
                }
            }
        },
        methods: {
            goBack() {
                this.$router.back();
            }
        },
        computed: {
            colorAndPadding() {
                return this.color + ' ' + (this.appLike ? ' overflow-hidden' : '');
            }
        }
    }
</script>

<style scoped>

</style>

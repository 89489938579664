<template>
    <v-card tile class="mb-6 store" :id="'store-' + store.id">
        <v-container>
            <v-row no-gutters class="py-2">
                <v-col cols="12">
                    <v-img width="24" class="float-left" :src="getMarkerNo(resultNo)"/>
                    <v-row dense class="nolo-body pl-2">
                        <v-col cols="8" class="nolo-subtitle"> {{store.name}}</v-col>
                        <v-col cols="4" class="text-no-wrap text-right">{{ distanceAway(store) }}</v-col>
                        <v-col cols="12">
                            <p class="nolo-p3"><a :href="store.mapLink" target="_fiveGuysMap"
                                                  class="font-weight-bold secondary--text text-decoration-underline">{{
                                store.address}}</a></p>
                            <div v-if="store.online">
                                <div v-if="openInfo.open">
                                    <span class="font-weight-black text-uppercase">{{ $t('openNow')  }}</span> {{
                                    closesAt
                                    }}
                                </div>
                                <div v-else>
                                    <span class="font-weight-bold text-uppercase">{{ $t('closedNow')  }}</span> {{
                                    opensAt
                                    }}
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" v-if="hasCurbside && !openInfoCS.curbsideSame && store.online">
                            <div v-if="openInfoCS.open">
                                <span class="font-weight-bold text-uppercase">{{ $t('openNowCurbside')  }}</span> {{
                                closesAtCurbside }}
                            </div>
                            <div v-else>
                                <span class="font-weight-bold text-uppercase">{{ $t('closedNowCurbside')  }}</span> {{
                                opensAtCurbside }}
                            </div>
                        </v-col>

                    </v-row>
                </v-col>
            </v-row>

            <div v-if="store.online">
                <v-row dense v-if="hasCurbside" class="pt-4">
                    <v-col cols="12" md="6">
                        <v-btn block depressed :disabled="!hasCollect || !isOnline" class="primary" v-if="isLoggedIn"
                               @click="gtagCollectEvent();$emit('store-select', {storeId: store.id, orderType: 'Collection', externalStoreLink: null})"><span v-if="hasCollectPlus">{{ $t('btn.collectplus') }}</span><span v-else>{{ $t('btn.collect') }}</span>
                        </v-btn>

                      <v-btn block depressed :disabled="!hasCollect || !isOnline" class="primary" v-else
                             @click="gtagCollectEvent();$emit('store-select', {storeId: store.id, orderType: 'Collection', externalStoreLink: store.externalStoreLink + '&orderType=ClickAndCollect'})"><span v-if="hasCollectPlus">{{ $t('btn.collectplus') }}</span><span v-else>{{ $t('btn.collect') }}</span>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-btn block v-if="hasCurbside && isLoggedIn" color="secondary"
                               @click="gtagCurbsideEvent();$emit('store-select', {storeId: store.id, orderType: 'Curbside', externalStoreLink: null})" outlined>Curbside
                        </v-btn>
                      <v-btn block v-else-if="hasCurbside" color="secondary"
                             @click="gtagCurbsideEvent();$emit('store-select', {storeId: store.id, orderType: 'Curbside', externalStoreLink: store.externalStoreLink + '&orderType=Curbside'})" outlined>Curbside
                      </v-btn>
                    </v-col>

                    <v-col v-if="hasCollectPlus" cols="12" class="d-flex nolo-subcopy align-center pt-3 pl-3">
                        {{ $t('message.collectPlus') }}
                    </v-col>
                    <v-col cols="12" class="d-flex nolo-subcopy align-center pt-3 pl-3">
                        <v-img width="24" class="shrink mr-2" contain :src="require('../assets/icon-car.svg')"/>
                        {{ $t('message.clickCurbside') }}
                    </v-col>
                </v-row>
                <v-row dense v-else class="py-3">
                    <v-col cols="12">
                        <v-btn block depressed class="primary" v-if="isLoggedIn"
                               @click="gtagCollectEvent();$emit('store-select', {storeId: store.id, orderType: 'Collection', externalStoreLink: null})"><span v-if="hasCollectPlus">{{ $t('btn.collectplus') }}</span><span v-else>{{ $t('btn.collect') }}</span>
                        </v-btn>
                      <v-btn block depressed class="primary" v-else
                        @click="gtagCollectEvent();$emit('store-select', {storeId: store.id, orderType: 'Collection', externalStoreLink: store.externalStoreLink + '&orderType=ClickAndCollect'})"><span v-if="hasCollectPlus">{{ $t('btn.collectplus') }}</span><span v-else>{{ $t('btn.collect') }}</span>
                      </v-btn>
                    </v-col>
                    <v-col v-if="hasCollectPlus" cols="12" class="d-flex nolo-subcopy align-center pt-3 pl-3">
                        {{ $t('message.collectPlus') }}
                    </v-col>
                </v-row>

                <v-row v-if="hasJusteatLink && cmsDomain.includes('fiveguys.co.uk')" class="py-1">
                  <v-col cols="12" md="12">
                    <v-btn block color="secondary deliveroo--text" :href="store.justeatLink" target="_blank"
                           @click="openExternalLink(hasJusteatLink)" outlined> <v-img width="24" class="shrink mr-2" contain :src="require('../assets/justeat.png')"/> {{ $t('btn.justeat') }}
                    </v-btn>
                  </v-col>
                </v-row>

                <v-row v-if="hasDeliverooLink" class="py-1">
                  <v-col cols="12" md="12">
                    <v-btn block v-if="hasDeliverooLink" color="secondary deliveroo--text" :href="store.deliverooLink" target="_blank"
                          @click="openExternalLink(hasDeliverooLink)" outlined> <v-img width="24" class="shrink mr-2" contain :src="require('../assets/deliveroo.png')"/> {{ $t('btn.deliveroo') }}

                    </v-btn>
                  </v-col>
                </v-row>

              <v-row v-if="hasLieferandoLink" class="py-1">
                <v-col cols="12" md="12">
                  <v-btn block color="secondary deliveroo--text" :href="store.lieferandoLink" target="_blank"
                         @click="openExternalLink(hasLieferandoLink)" outlined> <v-img width="24" class="shrink mr-2" contain :src="require('../assets/lieferando.png')"/> {{ $t('btn.lieferando') }}
                  </v-btn>
                </v-col>
              </v-row>

              <v-row v-if="hasJusteatLink && !cmsDomain.includes('fiveguys.co.uk')" class="py-1">
                <v-col cols="12" md="12">

                  <v-btn block color="secondary deliveroo--text" :href="store.justeatLink" target="_blank"
                         @click="openExternalLink(hasJusteatLink)" outlined> <v-img width="24" class="shrink mr-2" contain :src="require('../assets/justeat.png')"/> {{ $t('btn.justeat') }}
                  </v-btn>
                </v-col>
              </v-row>

              <v-row v-if="hasUbereatsLink" class="py-1">
                <v-col cols="12" md="12">

                  <v-btn block color="secondary deliveroo--text" :href="store.ubereatsLink" target="_blank"
                         @click="openExternalLink(hasUbereatsLink)" outlined> <v-img width="24" class="shrink mr-2" contain :src="require('../assets/ubereats.png')"/> {{ $t('btn.ubereats') }}
                  </v-btn>
                </v-col>
              </v-row>

              <v-row v-if="hasFoodpandaLink" class="py-1">
                <v-col cols="12" md="12">

                  <v-btn block color="secondary deliveroo--text" :href="store.foodpandaLink" target="_blank"
                         @click="openExternalLink(hasFoodpandaLink)" outlined> <v-img width="24" class="shrink mr-2" contain :src="require('../assets/foodpanda.png')"/> {{ $t('btn.foodpanda') }}
                  </v-btn>
                </v-col>
              </v-row>

              <v-row v-if="hasWoltLink" class="py-1">
                <v-col cols="12" md="12">

                  <v-btn block color="secondary deliveroo--text" :href="store.woltLink" target="_blank"
                         @click="openExternalLink(hasWoltLink)" outlined> <v-img width="24" class="shrink mr-2" contain :src="require('../assets/wolt.png')"/> {{ $t('btn.wolt') }}
                  </v-btn>
                </v-col>
              </v-row>

              <v-row v-if="hasGlovoLink" class="py-1">
                <v-col cols="12" md="12">

                  <v-btn block color="secondary deliveroo--text" :href="store.glovoLink" target="_blank"
                         @click="openExternalLink(hasGlovoLink)" outlined> <v-img width="24" class="shrink mr-2" contain :src="require('../assets/glovo.png')"/> {{ $t('btn.glovo') }}
                  </v-btn>
                </v-col>
              </v-row>

            </div>
            <div v-else class="nolo-mid-grey px-2 py-4 text-center">
                {{ store.offlineReason ? store.offlineReason : $t('message.defaultOfflineReason') }}
            </div>
        </v-container>
    </v-card>
</template>

<script>

    import MapMarkers from "./Maps/MapMarkers";
    import APIStore from "../api/APIStore";
    import Gtag from "../mixins/Gtag";
    import APINoquApp from "@/api/APINoquApp";

    export default {
        name: "StoreCard",
        mixins: [Gtag],
        props: {
            resultNo: {type: [String, Number], required: true},
            store: {type: Object, required: true}
        },
        methods: {
            getMarkerNo(resultNo) {
                return MapMarkers.getMarkerNo(resultNo);
            },
            distanceAway(store) {

                let distance = (store.distance / (this.distanceInKms ? 1 : 1.6)).toFixed(1);

                if (distance < 0.1) distance = '0.1';

                return distance + (this.distanceInKms ? ' km' : ' mi');
            },
            openExternalLink(url) {

              this.gtagDeliverooEvent();
              APINoquApp.openExternalUrl(this, url);
            },
            isLoggedIn() {
              return this.$store.getters['session/token'] !== null;
            }
        },
        computed: {
            distanceInKms() {
                if (typeof process.env.VUE_APP_DISTANCE_IN_KM !== 'undefined' && process.env.VUE_APP_DISTANCE_IN_KM === 'true') {
                    return true;
                } else {
                    return false;
                }
            },
            openInfo() {
                return APIStore.openingInfo(this.store, false);
            },
            openInfoCS() {
                return APIStore.openingInfo(this.store, true);
            },
            isOnline() {
                return this.store.online;
            },
            hasCollect() {
                return this.store.curbsideAvailable;
            },
            hasCurbside() {
                return this.store.curbsideAvailable;
            },
            hasDeliverooLink() {
              return this.store.deliverooLink;
            },
          hasFoodpandaLink() {
            return this.store.foodpandaLink;
          },
          hasGlovoLink() {
            return this.store.glovoLink;
          },
          hasLieferandoLink() {
            return this.store.lieferandoLink;
          },
          hasJusteatLink() {
            return this.store.justeatLink;
          },
          hasUbereatsLink() {
            return this.store.ubereatsLink;
          },
          hasWoltLink() {
            return this.store.woltLink;
          },
            hasCollectPlus() {
                let distance = (this.store.distance / (this.distanceInKms ? 1 : 1.6)).toFixed(1);
                return (this.store.collectPlus.enabled === true  && distance < this.store.collectPlus.radius / 1000);
            },
            opensAt() {
                if (this.openInfo.opening) {
                    return this.$t('opensAt', {when: APIStore.formatTime(this.openInfo.openDate)})
                } else {
                    return '';
                }
            },
            closesAt() {
                return this.$t('closesAt', {when: APIStore.formatTime(this.openInfo.closeDate)})
            },
            opensAtCurbside() {
                if (this.openInfo.opening) {
                    return this.$t('opensAt', {when: APIStore.formatTime(this.openInfoCS.openDate)})
                } else {
                    return '';
                }
            },
            closesAtCurbside() {
                return this.$t('closesAt', {when: APIStore.formatTime(this.openInfoCS.closeDate)})
            },

            deliverooLink() {
                return this.isAppMode && APINoquApp.isInNoquAndroidApp()  ? '' : this.$t('url.deliveroo');
            }

        }

    }
</script>

<style scoped>

    .store {
        margin: auto;
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15) !important;
    }

    @media (min-width: 480px) {
        .store {
            min-width: 350px;
            width: 350px;
        }
    }

    @media (min-width: 800px) {
        .store {
            margin: initial;
        }
    }

</style>

<template>
    <div>
    <bouncing-burger-overlay :value="true">hello</bouncing-burger-overlay>
        xxxx
    </div>
</template>

<script>
    import BouncingBurgerOverlay from "../../components/UI/BouncingBurgerOverlay";
    export default {
        name: "PageWaitLoading",
        components: {BouncingBurgerOverlay},
        data() {
            return {
            }
        },
        watch: {
            appReady: {
                immediate: true,
                handler() {
                    this.$router.replace({name: 'start-order'});
                }
            }
        },
        computed: {
            appReady() {
                return this.$store.getters['app/ready'];
            }
        }
    }
</script>

export default {
    "name": "Gtag",
    data() {
        return {
            deliverooEvent: 'Deliveroo2',
            clickCollectEvent: 'Click-Collect2',
            curbsideEvent: 'Curbside'
        }
    },
    computed: {
        isGtagTrackingEnabled() {
            if (this.$store.getters['app/cookiePolicy'] == '2') {
                return false;
            }
            return !!process.env.VUE_APP_GOOGLE_ANALYTICS_GTAG;
        },
    },
    methods: {
        gtagDeliverooEvent() {
            if (this.isGtagTrackingEnabled) {
                this.$gtag.event(this.deliverooEvent, {
                    'event_category': this.deliverooEvent,
                    'event_label': 'FiveGuys click',
                    'value': 1
                });
            }
        },
        gtagCollectEvent() {
            if (this.isGtagTrackingEnabled) {
                this.$gtag.event(this.clickCollectEvent, {
                    'event_category': this.clickCollectEvent,
                    'event_label': 'FiveGuys click',
                    'value': 1
                });
            }
        },
        gtagCurbsideEvent() {
            if (this.isGtagTrackingEnabled) {
                this.$gtag.event(this.curbsideEvent, {
                    'event_category': this.curbsideEvent,
                    'event_label': 'FiveGuys click',
                    'value': 1
                });
            }
        },
    }
}

function getMarkerNo(markerNo, current) {
    let marker = require('../../assets/MapMarkers/MapMarker1.svg');
    switch (markerNo) {
        case -1:
            marker = require('../../assets/MapMarkers/FiveGuysLocation.svg');
            break;
        case 0:
            marker = require('../../assets/MapMarkers/CustomerLocation.png');
            break;
        case 1:
            marker = current ? require('../../assets/MapMarkers/MapMarker1_Red.svg') : require('../../assets/MapMarkers/MapMarker1.svg');
            break;
        case 2:
            marker = current ? require('../../assets/MapMarkers/MapMarker2_Red.svg') : require('../../assets/MapMarkers/MapMarker2.svg');
            break;
        case 3:
            marker = current ? require('../../assets/MapMarkers/MapMarker3_Red.svg') : require('../../assets/MapMarkers/MapMarker3.svg');
            break;
        case 4:
            marker = current ? require('../../assets/MapMarkers/MapMarker4_Red.svg') : require('../../assets/MapMarkers/MapMarker4.svg');
            break;
        case 5:
            marker = current ? require('../../assets/MapMarkers/MapMarker5_Red.svg') : require('../../assets/MapMarkers/MapMarker5.svg');
            break;
        case 6:
            marker = current ? require('../../assets/MapMarkers/MapMarker6_Red.svg') : require('../../assets/MapMarkers/MapMarker6.svg');
            break;
        case 7:
            marker = current ? require('../../assets/MapMarkers/MapMarker7_Red.svg') : require('../../assets/MapMarkers/MapMarker7.svg');
            break;
        case 8:
            marker = current ? require('../../assets/MapMarkers/MapMarker8_Red.svg') : require('../../assets/MapMarkers/MapMarker8.svg');
            break;
    }
    return marker;

}

export default {
    getMarkerNo: getMarkerNo
}

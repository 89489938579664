<template>
    <v-dialog persistent :value="value" @cancel="cancel" max-width="480">
        <v-card tile class="px-2 py-6" v-if="value">
            <v-card-text class="pb-0" v-html="cookieText"></v-card-text>
            <v-card-actions class="pb-0">
              <v-btn @click="$emit('input', '1')" block class="wrap-it" color="primary white--text">{{ cookieButton }}</v-btn>
            </v-card-actions>
            <v-card-actions class="pb-0">
              <v-btn @click="$emit('input', '2')" block class="wrap-it" color="primary white--text">{{ cookieButtonRequired }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: 'cookie-policy-dialog',
        props: {
            value: {type: Boolean, default: false },
        },
        methods: {
            cancel() {
                this.$emit('input', false);
            }
        },
        computed: {
            cookieInfo() {
                return this.$store.getters['app/cookies'];
            },
            cookieText() {
                return this.cookieInfo.content;
            },
            cookieButton() {
                return this.cookieInfo.cta;
            },
            cookieButtonRequired() {
              return this.cookieInfo.essential_only_cta;
            }
        }
    }
</script>
<style scoped>
.wrap-it {
    max-width: 100%;
    white-space: normal;
}
.wrap-it >>> .v-btn__content {
    max-width: 100%;
}
</style>

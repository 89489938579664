import _ApiHelper from "./_ApiHelper";

function setMarketing(vue, newValue) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/customer/setMarketingOptIn',
                data: {
                    marketingOptIn: newValue
                },
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

function getOrder(vue, orderId) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'GET',
                url: '/apiui/customer/order/' + orderId,
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

function getZones(vue) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'GET',
                url: '/apiui/customer/zones',
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

function getPreviousOrders(vue) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'GET',
                url: '/apiui/customer/previousOrders',
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

function reorder(vue, orderId) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'GET',
                url: '/apiui/customer/pseudoOrder/' + encodeURI(orderId),
                headers: _ApiHelper.httpHeadersWithToken(vue)
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

function defaultCustomer(vue) {
    // default the customer
    vue.$store.commit('session/customer',
        {
            "customer": {
                "email": null,
                "firstName": null,
                "lastName": null,
                "phoneNumber": null,
                "vehicles": [],
                "marketingOptIn": false,
                "addressId": null,
                "addresses": [],
                "paymentAddress": null,
                "recentOrders": []
            }
        }
    );
}

function getCustomer(vue) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'GET',
                url: '/apiui/customer/customer',
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                vue.$store.commit('session/customer', response.data.data.customer);
                resolve(response);
            })
            .catch(function (error) {
                defaultCustomer(vue);
                reject(error);
            })
    });
}


function forgotPassword(vue, email, url) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/customer/forgotPassword',
                data: {
                    email: email,
                    url: url
                },
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}


function validateRecoveryToken(vue, token) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/customer/validateToken',
                data: {
                   token: token,
                },
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}



function resetPasswordFromToken(vue, token, password) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/customer/resetPasswordFromToken',
                data: {
                    token: token,
                    password: password,
                },
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}


function validatePassword(vue, password) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/customer/validatePassword',
                data: {
                    password: password
                },
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

function emailInUse(vue, email) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/customer/emailInUse',
                data: {
                    email: email
                },
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

function changePassword(vue, oldPassword, newPassword) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/customer/changePassword',
                data: {
                    oldPassword: oldPassword,
                    newPassword: newPassword
                },
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

function closeAccount(vue, password) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/customer/closeAccount',
                data: {
                    oldPassword: password
                },
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}


function updatePersonalDetails(vue, personalDetails) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/customer/updatePersonalDetails',
                data: personalDetails,
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

function register(vue, customerDetails) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/customer/register',
                data: customerDetails,
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

function updateAddress(vue, address, makeDefault) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/customer/updateAddress',
                data: {
                    address: address,
                    makeDefault: makeDefault
                },
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

function updateVehicle(vue, vehicle) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/customer/updateVehicle',
                data: {
                    vehicle: vehicle
                },
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

function removeVehicle(vue, vehicleId) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'DELETE',
                url: '/apiui/customer/updateVehicle',
                data: {
                    vehicleId: vehicleId
                },
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

function removeAddress(vue, addressId) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'DELETE',
                url: '/apiui/customer/updateAddress',
                data: {
                    addressId: addressId
                },
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

function login(vue, email, password, rememberMe, recaptchaToken) {

    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/customer/login',
                data: {
                    email: email,
                    password: password,
                    rememberMe: rememberMe,
                    recaptchaToken: recaptchaToken
                }
            },
            _ApiHelper.httpOptionsWithToken(vue)
        ).then(function (response) {
            if (response.data.success) {
                vue.$store.commit('app/permToken', {
                    email: email,
                    rememberMe: response.data.data.rememberMe
                });
                vue.$store.commit('session/token', response.data.data.token);
                vue.$store.commit('session/customer', response.data.data.customer);
            } else {
                vue.$store.commit('app/permToken', null);
                vue.$store.commit('session/token', null);
                defaultCustomer(vue);
            }
            resolve(response);
        }).catch(function (error) {
            reject(error);
        })
    });
}

function logout(vue) {

    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/customer/logout',
                data: {}
            },
        ).then(function (response) {
            vue.$store.commit('app/permToken', null);
            vue.$store.commit('session/token', null);
            vue.$store.commit('session/sessionId', response.data.session);
            defaultCustomer(vue);
        }).catch(function (error) {
            reject(error);
        })
    });
}

export default {
    getCustomer: getCustomer,
    login: login,
    logout: logout,
    setMarketing: setMarketing,
    getOrder: getOrder,
    getPreviousOrders: getPreviousOrders,
    validatePassword: validatePassword,
    changePassword: changePassword,
    closeAccount: closeAccount,
    updatePersonalDetails: updatePersonalDetails,
    forgotPassword: forgotPassword,
    getZones: getZones,
    updateAddress: updateAddress,
    removeAddress: removeAddress,
    updateVehicle: updateVehicle,
    removeVehicle: removeVehicle,
    register: register,
    emailInUse: emailInUse,
    reorder: reorder,
    defaultCustomer: defaultCustomer,
    validateRecoveryToken: validateRecoveryToken,
    resetPasswordFromToken: resetPasswordFromToken
}

<template>
    <v-layout column dark class="black justify-center align-center checkerboard-bottom">
        <v-layout column class="pt-4 pb-5 py-md-8">

            <v-layout class="mb-4 mb-md-6 wrap justify-center shrink">
                <v-flex shrink v-for="link in appLinks.footer" :key="link.name">
                    <a class="text-decoration-none nolo-mid-grey--text text-no-wrap mx-2 mx-md-5 footer-link"
                       :href="link.link" :target="link.target" v-html="link.name"></a>
                </v-flex>
            </v-layout>

            <v-divider dark/>

            <v-layout class="justify-center px-1 mt-5 mt-md-6 wrap" v-if="!isAppMode">
                <a class="mx-1 mx-md-3 text-decoration-none nolo-mid-grey--text footer-link"
                   :class="{'is-current' : link.active}"
                   v-for="link in appLinks.countries" :key="link.country"
                   :href="link.link" :target="link.target">{{link.name}}</a>
            </v-layout>

            <v-layout class="my-5 my-md-6 justify-center">
                <a :href='localeTwitter' target="_blank" class="mx-2">
                    <v-img width="32" height="32" contain :src="require('../../assets/Twitter.png')"/>
                </a>
                <a :href='localeFacebook' target="_blank" class="mx-2">
                    <v-img width="32" height="32" contain :src="require('../../assets/Facebook.png')"/>
                </a>
                <a :href='localeInsta' target="_blank" class="mx-2">
                    <v-img width="32" height="32" contain :src="require('../../assets/Instagram.png')"/>
                </a>
            </v-layout>

            <v-layout class="justify-center nolo-mid-grey--text">
                &copy; 2021 Five Guys
            </v-layout>

        </v-layout>
        <v-chip color="grey darken-4" class="version white--text" x-small @click="clickedVersion">{{ version }}</v-chip>

    </v-layout>

</template>

<script>
    export default {
        name: "AppFooter",
        data() {
            return {
                debugCount: 0,
                localeTwitter: process.env.VUE_APP_LOCALE_TWITTER,
                localeFacebook: process.env.VUE_APP_LOCALE_FACEBOOK,
                localeInsta: process.env.VUE_APP_LOCALE_INSTA
            }
        },
        methods: {
            clickedVersion() {
                this.debugCount += 1;
                this.$store.commit('app/versionInfo', this.debugCount >= 8);
            }
        },
        computed: {
            version() {
                return process.env.VERSION;
            },
            appLinks() {
                return this.$store.getters['app/links'];
            }
        }
    }
</script>

<style scoped>

    .is-current {
        color: #fff;
        font-weight: 700;
        border-bottom: thin solid #fff
    }

    .footer-link {
        line-height: 24px;
    }

    @media (min-width: 800px) {
        .footer-link {
            line-height: 32px;
        }
    }

    .checkerboard-bottom {
        font-size: 10px;
    }

    @media (min-width: 800px) {
        .checkerboard-bottom {
            font-size: 14px;
        }
    }

    .checkerboard-bottom::before {
        display: block;
        background-repeat: repeat;
        content: "";
        width: 100%;
        height: 48px;
        min-height: 48px;
        background-image: url("/_/img/checkerboard-mobile.png");
    }

    @media (min-width: 1200px) {
        .checkerboard-bottom::before {
            height: 60px;
            min-height: 60px;
            background-image: url("/_/img/checkerboard.png");
        }
    }

    .version {
        position: absolute;
        right: 10px;
        bottom: 10px;
        opacity: 0.5;
    }

</style>

import JSCookie from 'js-cookie/src/js.cookie'
import { bootstrap } from 'vue-gtag'

let bootedAnalytics = false;

function cookieFix() {
    if (JSCookie.get('eu_cookie_ack') !== undefined) {
        JSCookie.remove('eu_cookie_ack');
    }

    let cookie5G = JSCookie.get('5g_cookies');
    if (cookie5G !== undefined && cookie5G.length > 10) {
        // Invalid cookie being sent on backend side (but necessary for legacy)
        JSCookie.remove('5g_cookies', { domain: 'order.fiveguys.co.uk' });
        JSCookie.remove('5g_cookies', { domain: 'order.fiveguys.de' });
        JSCookie.remove('5g_cookies', { domain: 'order.fiveguys.fr' });
        JSCookie.remove('5g_cookies', { domain: 'order.fiveguys.es' });
        JSCookie.remove('5g_cookies', { domain: 'order.fiveguys-jv.lineten.io' });
        JSCookie.remove('5g_cookies', { domain: 'order.fiveguys-jv-de.lineten.io' });
        JSCookie.remove('5g_cookies', { domain: 'order.fiveguys-jv-fr.lineten.io' });
        JSCookie.remove('5g_cookies', { domain: 'order.fiveguys-jv-es.lineten.io' });

        // Fetch correct echidna cookie
        cookie5G = JSCookie.get('5g_cookies');
    }
    if (cookie5G === undefined) return;

    if (cookie5G.length < 3) return;

    JSCookie.remove('5g_cookies');
}

function checkCookiePolicyAck(cookies, dflt) {

    let result = dflt;
    if (cookies === null) return '0';

    let cookie = JSCookie.get(cookies.cookie, getOptions(cookies));
    if (typeof cookie !== 'undefined') {
        result = cookie;
    }

    _handleAnalyticsStart(result);
    return result;

}

function _handleAnalyticsStart(cookieVal) {

    // If the cookie state is set to 1 then this is all so enabled everything.
    if (cookieVal === '1' && !bootedAnalytics) {
        bootAnalytics();
    }

}

function bootAnalytics() {

    bootedAnalytics = true;
    bootstrap(); // start up Gtag

    // eslint-disable-next-line no-unused-vars
    let description = 'Five Guys ' + process.env.VUE_APP_LOCALE;
    switch (process.env.VUE_APP_LOCALE) {
        case 'en':
            description = 'Five Guys UK';
            break;
        case 'fr':
            description = 'Five Guys France';
            break;
        case 'es':
            description = 'Five Guys Spain';
            break;
        case 'de':
            description = 'Five Guys Germany';
            break;
    }

    // load atreemo

    let head = document.getElementsByTagName('head')[0];
    let scriptInit = document.createElement('script');
    scriptInit.async = false;
    scriptInit.defer = false;
    scriptInit.id = 'atreemo-init';
    scriptInit.src='https://tracking.atreemo.com/Scripts/TrackingInit.js';
    scriptInit.type = 'text/javascript';
    scriptInit.addEventListener('load', function() {

        let scriptCore = document.createElement('script');
        scriptCore.async = false;
        scriptCore.defer = false;
        scriptCore.id = 'atreemo-core';
        scriptCore.src='https://tracking.atreemo.com/Scripts/Tracking.js';
        scriptCore.type = 'text/javascript';
        scriptCore.addEventListener('load', function() {

            let scriptStart = document.createElement('script');
            scriptStart.type = 'text/javascript';
            scriptStart.async = false;
            scriptStart.defer = false;
            scriptStart.innerHTML = 'AtreemoTrackingLbr.init(["' + description + ',https://tools.news.fiveguys.co.uk/WebEcastsFiveGuys"]);'
            head.append(scriptStart);

        });
        head.append(scriptCore);

    });
    head.append(scriptInit);


//     <script type="text/javascript" src="https://tracking.atreemo.com/Scripts/TrackingInit.js"></script>
//     <% if (process.env.VUE_APP_LOCALE === 'en') { %>
//     <script type="text/javascript">AtreemoTrackingLbr.init(["Five Guys UK,https://tools.news.fiveguys.co.uk/WebEcastsFiveGuys"]);</script>
//         <% }
//     if (process.env.VUE_APP_LOCALE === 'de') { %>
//     <script type="text/javascript">AtreemoTrackingLbr.init(["Five Guys Germany,https://tools.news.fiveguys.co.uk/WebEcastsFiveGuys"]);</script>
//         <% }
//     if (process.env.VUE_APP_LOCALE === 'es') { %>
//     <script type="text/javascript">AtreemoTrackingLbr.init(["Five Guys Spain,https://tools.news.fiveguys.co.uk/WebEcastsFiveGuys"]);</script>
//         <% }
//     if (process.env.VUE_APP_LOCALE === 'fr') { %>
//     <script type="text/javascript">AtreemoTrackingLbr.init(["Five Guys France,https://tools.news.fiveguys.co.uk/WebEcastsFiveGuys"]);</script>
//         <% } %>
// <script type="text/javascript" src="https://tracking.atreemo.com/Scripts/Tracking.js"></script>

}

function setCookiePolicyAck(cookies, state) {

    if (cookies === null) return false;

    JSCookie.set(cookies.cookie, state, getOptions(cookies));

    _handleAnalyticsStart(state, 'setcpa');
    return true;

}

function clearCookiePolicyAck(cookies) {

    if (cookies === null) return false;

    JSCookie.remove(cookies.cookie, getOptions(cookies));
    return true;
}

function isLocalhost() {
    return window.location.hostname === 'localhost';
}

function getOptions(cookies) {
    let domain = cookies.domain;
    if (domain === 'fiveguys.lineten.com') domain = 'fiveguysui.lineten.net';
    if (isLocalhost()) domain = 'localhost';
    return {expires: cookies.frequency, domain: domain, path: '/'};
}


export default {
    checkCookiePolicyAck: checkCookiePolicyAck,
    setCookiePolicyAck: setCookiePolicyAck,
    clearCookiePolicyAck: clearCookiePolicyAck,
    cookieFix: cookieFix
}

<template>
    <v-card>
        <v-card-title class="justify-center">
            Version Info
        </v-card-title>
        <v-card-text class="text-center">
            <v-container>
                <v-divider class="mb-4"/>
                <v-row>
                    <v-col class="d-flex">
                        <v-img height="32" contain
                               :src="require('../assets/lineten.png')"/>
                    </v-col>
                    <v-col class="align-center justify-center text-no-wrap d-flex">
                        <v-row>
                            <v-col cols="12">
                                Lineten NOLO UI
                            </v-col>
                            <v-col cols="6" class="text-right">Echidna<br/>Echidna API<br/>NOLO Core</v-col>
                            <v-col cols="6" class="text-left">{{version}}<br/>{{appVersions.echidnaAPI}}<br/>{{appVersions.noloCore}}
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-divider class="mt-6 mb-2"/>
                <v-row dense no-gutters v-show="showDebugPanel">
                    <v-col cols="12" md="6" class="text-left mb-4">
                        <v-checkbox dense color="info" v-model="showDebugPanel" label="Show debug panel" hide-details/>
                    </v-col>
                    <v-col cols="12" md="6" class="text-left mb-4">
                        <v-checkbox dense color="info" v-model="showGuard" label="Show guarding steps" hide-details/>
                    </v-col>
                    <v-col cols="12" md="6" class="text-left mb-4">
                        <v-checkbox dense color="info" v-model="showTimeTweaker" label="Show time tweaker"
                                    hide-details/>
                    </v-col>
                    <v-col cols="12" md="6" class="text-left mb-4">
                        <v-checkbox dense color="info" v-model="showLoginStatus" label="Show logged in icon"
                                    hide-details/>
                    </v-col>
                    <v-col cols="12" class="text-left">
                        <v-divider class="mt-6 mb-6"/>
                    </v-col>
                    <v-col cols="12" class="text-left mb-4">
                        <router-link :to="{ name: 'debug-session'}" class="text-decoration-none"
                                     @click.native="$emit('dismiss')">
                            <v-btn color="info" class="mt-3 font-weight-regular" small>Show Session</v-btn>
                        </router-link>
                    </v-col>
                    <v-col cols="12" class="text-left">
                        <v-divider class="mt-6 mb-6"/>
                    </v-col>
                    <v-col cols="12" class="text-left d-flex">
                        <v-btn class="grow mr-1" @click="selectLanguage('en')" small>EN</v-btn>
                        <v-btn class="grow ml-1" @click="selectLanguage('de')" small>DE</v-btn>
                        <v-btn class="grow ml-1" @click="selectLanguage('es')" small>ES</v-btn>
                        <v-btn class="grow ml-1" @click="selectLanguage('fr')" small>FR</v-btn>
                        <v-btn class="grow ml-1" @click="selectLanguage('xx')" small>XX</v-btn>
                    </v-col>
                    <v-col cols="12" class="text-left">
                        <v-divider class="mt-6 mb-4"/>
                    </v-col>
                    <v-col cols="12" class="text-left">
                        <v-text-field v-model="url" @keydown.enter="goDebugUrl" dense placeholder="URL" type="url"/>
                    </v-col>
                </v-row>
            </v-container>

        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn small outlined class="px-12" color="nolo-dark-grey"
                   @touchstart="dismissPressed" @touchend="dismissReleased"
                   @mousedown="dismissPressed" @mouseup="dismissReleased" @>Dismiss
            </v-btn>

        </v-card-actions>

    </v-card>
</template>

<script>
    export default {
        name: "VersionInfo",
        data() {
            return {
                dismissPressedAt: 0,
                url: ''
            }
        },
        methods: {
            goDebugUrl() {
                window.location = this.url;
            },
            selectLanguage(lang) {
                this.$i18n.locale = lang;
            },
            dismissPressed() {
                this.dismissPressedAt = new Date().getTime();
            },
            dismissReleased() {
                let now = new Date().getTime();
                let duration = (now - this.dismissPressedAt) / 1000.0;
                if (duration > 3) {
                    this.showDebugPanel = true;
                } else {
                    this.$emit('dismiss');
                }
            }
        },
        computed: {
            showDebugPanel: {
                get() {
                    return this.$store.getters['app/showDebugPanel'];
                },
                set(newValue) {
                    return this.$store.commit('app/showDebugPanel', newValue);
                }
            },
            showGuard: {
                get() {
                    return this.$store.getters['app/showGuard'];
                },
                set(newValue) {
                    return this.$store.commit('app/showGuard', newValue);
                }
            },
            showTimeTweaker: {
                get() {
                    return this.$store.getters['app/showTimeTweaker'];
                },
                set(newValue) {
                    return this.$store.commit('app/showTimeTweaker', newValue);
                }
            },
            showLoginStatus: {
                get() {
                    return this.$store.getters['app/showLoginStatus'];
                },
                set(newValue) {
                    return this.$store.commit('app/showLoginStatus', newValue);
                }
            },
            appVersions() {
                return this.$store.getters['app/versions'];
            },
            version() {
                return process.env.VERSION;
            },
        }
    }
</script>

<style scoped>

</style>

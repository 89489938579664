<template>
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 24" class="pt-2">
        <path d="M191.218 13.3023c.506.8372.782 1.814.782 2.9768 0 2.5581-.966 4.4651-2.853 5.7674C187.307 23.3488 184.73 24 181.464 24c-1.427 0-2.761-.186-4.049-.6047-.598-.186-1.151-.4186-1.703-.6976-.506-.2791-1.012-.6047-1.426-.9303-.874-.6511-1.518-1.4883-2.024-2.4186-.23-.3721-.369-.7907-.507-1.2558-.138-.4186-.184-.8837-.23-1.3023h6.672c.092.3721.276.7442.46 1.0233.23.3255.506.5581.874.7907.69.4186 1.426.6511 2.301.6511.506 0 .92-.0465 1.334-.093.414-.093.782-.1861 1.104-.3721h-.046c.69-.3721 1.058-.8837 1.058-1.5814 0-.7442-.414-1.3023-1.242-1.6744-.138-.0465-.276-.1396-.414-.1861-.138-.0465-.276-.093-.46-.186-.322-.093-.736-.2326-1.15-.3256-.414-.1395-.921-.2325-1.427-.3721-.644-.186-1.288-.3256-1.84-.4651s-1.058-.2791-1.518-.4651c-.414-.1396-.875-.2791-1.289-.5116-.414-.1861-.874-.4186-1.288-.6977v-.0465c-.414-.2326-.782-.5117-1.104-.8372-.322-.3256-.598-.7442-.874-1.1628-.506-.8372-.737-1.8605-.737-3.1163 0-2.3256.875-4.093 2.623-5.2558C176.31.6512 178.703 0 181.648 0c1.518 0 2.852.186 4.049.6047 1.104.372 2.07.8372 2.898 1.4883.368.3256.736.6977 1.012 1.0233.322.372.552.7907.783 1.2093.046.1395.138.279.184.4186.046.1395.092.279.184.4186.092.279.184.5116.276.7907.092.279.138.5581.184.8372h-6.58c-.184-.6512-.552-1.2093-1.196-1.5814h-.046c-.552-.279-1.242-.4651-2.07-.4651-.783 0-1.473.1395-1.979.372-.506.2326-.782.6978-.782 1.3024 0 .7907.414 1.3488 1.242 1.721.736.372 1.887.6976 3.497 1.0697h-.046c.69.186 1.288.3256 1.84.5116.552.186 1.059.3256 1.473.4651.828.2791 1.702.6512 2.53 1.2093.966.4652 1.611 1.1163 2.117 1.907z"
              fill="#D21033"></path>
        <path d="M169.455.6047l-8.328 14.2791c0 .0465 0 .0465-.046.0465v8.4186h-6.947v-8.2325l-.046-.0465L145.76.6512h7.729l3.911 7.7675c.092.1395.184.186.276.186.138 0 .23-.0465.276-.186l3.911-7.7675h7.592V.6047z"
              fill="#D21033"></path>
        <path d="M127.908 16.0931c.092.5581.276 1.0233.552 1.4884.322.4651.736.7907 1.242 1.0232.506.2326 1.15.3721 1.979.3721 1.334 0 2.346-.4186 2.99-1.2558.322-.4186.506-.8837.644-1.4884.138-.5581.23-1.2093.23-1.9069V.6047h6.948V13.535c0 1.9069-.23 3.4883-.644 4.7907-.46 1.3023-1.058 2.3721-1.933 3.2093-.874.7907-1.978 1.3953-3.312 1.8139-1.335.4186-2.991.6047-4.923.6047-.921 0-1.795-.0465-2.577-.1396-.782-.093-1.518-.2325-2.162-.4186h-.046c-1.335-.3721-2.439-.9302-3.313-1.7209v-.0465c-.92-.7442-1.61-1.814-2.071-3.2093-.23-.6512-.368-1.4419-.506-2.2326-.138-.8372-.184-1.7209-.184-2.7442V.6047h6.948v13.721c0 .3255 0 .6511.046.9302 0 .3256.046.6046.092.8372z"
              fill="#D21033"></path>
        <path d="M115.76 11.1638v12.3256h-4.278l-.277-2c0-.0931-.046-.1396-.092-.2326-.092-.0465-.138-.093-.23-.093-.138 0-.23.0465-.276.1395-.322.3721-.736.7442-1.15 1.1163-.414.3256-.92.6511-1.472.8837-.552.2326-1.151.3721-1.841.5116-.69.1396-1.426.1861-2.254.1861-.736 0-1.472-.0465-2.163-.1861-.69-.1395-1.38-.279-2.0701-.5116-1.2883-.4651-2.3925-1.2093-3.4507-2.186a12.0856 12.0856 0 01-1.3803-1.6745c-.4141-.6046-.7362-1.3023-1.0583-2.0465-.5521-1.4418-.8281-3.1628-.8281-5.1162 0-.9303.046-1.7675.184-2.6047.138-.8372.3221-1.628.5981-2.372v-.0466c.2761-.7442.5982-1.4419 1.0122-2.093.3681-.6512.8282-1.2558 1.3343-1.814.5061-.5581 1.1043-1.0232 1.7484-1.4418.6442-.4186 1.3343-.7907 2.0245-1.1163.69-.279 1.472-.5116 2.3-.6512.829-.1395 1.703-.2325 2.623-.2325 2.807 0 5.153.7441 7.132 2.186 1.794 1.3023 2.944 3.2558 3.496 5.8605h-6.579c-.184-.4652-.368-.8372-.644-1.1628-.23-.3256-.552-.6512-.874-.9303-.737-.558-1.657-.8372-2.669-.8372-1.61 0-2.807.6977-3.589 2-.736 1.2558-1.15 2.9768-1.15 5.1628 0 .9768.092 1.814.23 2.5582.138.7442.46 1.4418.874 2.093.368.6512.92 1.1163 1.611 1.4884.69.3721 1.472.5116 2.484.5116.92 0 1.795-.2791 2.577-.8372h.046c.414-.2791.736-.6047 1.012-.9768s.46-.7441.598-1.2093c.046-.279-.046-.3721-.322-.3721h-3.037v-4.279h9.8z"
              fill="#D21033"></path>
        <path d="M77.8491 18.6046v4.8372H59.123V.6511h18.266v4.8372H66.3926c-.23 0-.322.093-.322.3256v3.3489c0 .2325.092.3255.322.3255h9.2481v4.8372h-9.2481c-.23 0-.322.0931-.322.3256v3.6744c0 .2326.092.3256.322.3256h11.4565v-.0465z"
              fill="#D21033"></path>
        <path d="M55.1665.6047l-7.8217 22.7907h-7.1776L32.4375.6047h6.9935l4.0029 15.3489c.046.186.138.279.3221.279.184 0 .276-.093.3221-.279L48.219.6047h6.9475z"
              fill="#D21033"></path>
        <path d="M28.4807.6047h-6.9475v22.7907h6.9475V.6047z" fill="#D21033"></path>
        <path d="M17.3918.6047V5.442H7.2696c-.23 0-.322.093-.322.3255v4.2791c0 .2326.092.3256.322.3256h8.0057v4.8372H7.2696c-.23 0-.322.093-.322.3256v7.9069H0V.6512h17.3918V.6047z"
              fill="#D21033"></path>
        <path d="M169.455.6047h-7.592l-3.911 7.7675c-.046.1395-.138.186-.276.186-.092 0-.184-.0465-.276-.186L153.489.6047h-7.729l8.328 14.4186s0 .0466.046.0466v8.279h6.947v-8.3721l.046-.0465L169.455.6047z"
              fill="#D21033"></path>
        <path d="M136.604 23.3954c1.334-.4186 2.438-1.0232 3.312-1.8139.829-.8372 1.473-1.907 1.933-3.2093.46-1.3024.644-2.9303.644-4.7907V.6047h-6.948v13.721c0 .6976-.092 1.3488-.23 1.9069-.138.5582-.368 1.0698-.644 1.4884-.644.8372-1.656 1.2558-2.99 1.2558-.829 0-1.519-.093-1.979-.3721-.506-.2325-.92-.5581-1.242-1.0232-.276-.4651-.46-.9768-.552-1.4884v.0465c-.046-.2791-.092-.5116-.092-.8372 0-.2791-.046-.6046-.046-.9302V.6047h-6.948v12.8838c0 .9767.046 1.9069.184 2.7441.092.8372.276 1.5814.506 2.2326.507 1.3488 1.197 2.4186 2.071 3.2093v.0465c.874.7907 1.978 1.3488 3.313 1.7209h.046c.69.1861 1.38.3256 2.162.4186.782.0931 1.656.1396 2.577.1396 1.886 0 3.542-.2326 4.923-.6047z"
              fill="#D21033"></path>
        <path d="M96.207 21.1163c1.0123.9767 2.1625 1.7209 3.4508 2.186.6902.2326 1.3342.3721 2.0702.5117.69.1395 1.427.186 2.163.186.828 0 1.564-.0465 2.254-.186.69-.1396 1.288-.2791 1.841-.5117.552-.279 1.058-.5581 1.472-.8837.414-.3256.828-.6977 1.15-1.1163 0-.093.092-.1395.276-.1395.046 0 .138.0465.23.093s.092.1395.092.2326l.276 2h4.279V11.1628h-9.754v4.4186h3.037c.276 0 .368.1395.322.3721-.092.4186-.322.8372-.598 1.2093-.276.3721-.644.6977-1.012.9767h-.046c-.783.5582-1.657.8372-2.577.8372-.966 0-1.794-.186-2.485-.5116-.69-.3721-1.242-.8837-1.61-1.4884-.414-.6511-.736-1.3488-.874-2.093s-.2302-1.5814-.2302-2.5581c0-2.1861.3682-3.907 1.1502-5.1628.828-1.3488 2.025-2 3.589-2 1.012 0 1.932.279 2.668.8372.323.279.599.6047.875.9302.23.3256.46.7442.644 1.1628h6.579c-.506-2.6046-1.656-4.5581-3.497-5.8604C109.964.7442 107.572 0 104.811 0c-.92 0-1.794.093-2.623.2326-.828.1395-1.564.372-2.3002.6511-.7361.3256-1.4263.6512-2.0244 1.1163-.6442.4186-1.1963.8837-1.7484 1.4419-.5061.5581-.9662 1.1628-1.3343 1.814-.3681.651-.7361 1.3488-1.0122 2.093v.0464c-.2761.7442-.5061 1.535-.5981 2.3721-.1381.8373-.1841 1.721-.1841 2.6047 0 1.9535.2761 3.6744.8282 5.1163.3221.7442.6441 1.3953 1.0582 2.0465.3681.5581.8282 1.1163 1.3343 1.5814z"
              fill="#D21033"></path>
        <path d="M77.85 23.3954v-4.7907H66.4395c-.23 0-.3221-.093-.3221-.3255v-3.6745c0-.2325.0921-.3255.3221-.3255h9.248V9.442h-9.248c-.23 0-.3221-.093-.3221-.3256V5.7675c0-.2325.0921-.3255.3221-.3255h10.9964V.6047h-18.266v22.7907H77.85z"
              fill="#D21033"></path>
        <path d="M55.1665.6047H48.219l-4.1409 15.3489c-.0461.2325-.1841.3256-.3681.3256-.1841 0-.2761-.0931-.3221-.2791L39.385.6512h-6.9475l7.7297 22.7907h7.1776L55.1665.6047z"
              fill="#D21033"></path>
        <path d="M28.4807.6047h-6.9475v22.7907h6.9475V.6047z" fill="#D21033"></path>
        <path d="M17.3918 5.442V.6047H0v22.7907h6.9475v-7.9069c0-.2326.092-.3256.322-.3256h8.0058v-4.7907H7.2696c-.23 0-.322-.093-.322-.3256v-4.279c0-.2326.092-.3256.322-.3256h10.1222z"
              fill="#D21033"></path>
    </svg>
</template>

<script>
    export default {
        name: "SiteLogo"
    }
</script>

<style scoped>

</style>

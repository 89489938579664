<template>
    <page-layout color="primary" :app-like="greaterThanSM" :loading="loading">
        <v-container class="fill-height mixed-fluid">
            <v-row class="align-md-center">
                <v-col cols="12" md="5" lg="5" class="fill-height">
                        <v-img class="hidden-sm-and-down" contain
                            :src="require('../../assets/bag.jpg')"/>
                        <v-img class="hidden-md-and-up"
                               :src="require('../../assets/bag_centered.jpg')" contain max-height="22vh"/>
                </v-col>
                <v-col cols="12" md="6" lg="5">
                    <slot></slot>
                </v-col>
            </v-row>
        </v-container>
    </page-layout>
</template>

<script>
    export default {
        name: "PageLayoutWithSideImage",
        props: {
            loading: {type: Boolean, default: false}
        },
        computed: {
            greaterThanSM() {
                return false;
            }
        }
    }
</script>

<style scoped>

@media(max-width: 1439px) {
    .mixed-fluid {
        max-width: 100vw !important;
    }
}

</style>

<template>
  <div class="nolo-p2 mt-1 text-decoration-underline" :class="{'modifier-product-info-text' : this.modifier, 'product-info-text' : !this.modifier}"
       v-if="this.productInfo !== null && this.productInfo !== ''"
       @click="showProductInfo($event)">
    <div v-if="this.modifier" class="product-info-text-margin"></div>
    {{ $t('btn.productInfo') }}
    <v-dialog :value="visibleProductInfo" width="600" persistent @click:outside="visibleProductInfo=false;">
      <v-card>
        <v-card-title class="product-info-title">
          <h6 class="product-info-title-text">
            {{ $t('btn.productInfo') }}
          </h6>
          <h6><span class="close-product-info" @click="visibleProductInfo = false;">X</span></h6>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="text-left">
                <vue-markdown>
                  {{ this.productInfo }}
                </vue-markdown>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueMarkdown from 'vue-markdown';
import Vue from "vue";
Vue.component('vue-markdown', VueMarkdown);

export default {
  name: "ShopProductInfo",
  data() {
    return {
      visibleProductInfo: false,
    }
  },
  mixins: [VueMarkdown],
  mounted() {
    if(this.productInfo){
      this.$emit('increase-card-size');
    }
  },
  props: {
    productInfo:  {type: String},
    modifier:     {type: Boolean},
  },
  methods: {
    showProductInfo(event) {
      this.visibleProductInfo = true;
      event.stopPropagation();
    }
  }

}
</script>

<style scoped>

.product-info-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  position: static;
  height: 52px;
  left: 0px;
  top: 0px;
  background: #D21033;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  margin: 0px 0px;
  color: #FFFFFF;
  font-size: 20px;
  font-family: Helvetica;
  line-height: 28px;
  letter-spacing: 0.0025em;
}

.product-info-title-text {
  position: static;
  height: 28px;
  left: 12px;
  top: 12px;
  text-transform: uppercase;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

.close-product-info {
  cursor: pointer;
}

.modifier-product-info-text {
  position: static;
  height: 20px;
  left: 0;
  top: 112px;
  width: fit-content;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.product-info-text {
  position: absolute;
  height: 20px;
  left: 16px;
  top: 122px;
  width: fit-content;
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}

.product-info-text-margin{
  width: 28px;
  display: inline-block;
}
</style>

<template>
    <v-container class="d-flex justify-center">
        <v-row no-gutters class="inbound-container">
            <v-col cols="12" class="d-flex text-center text-md-left">
                <v-card dark class="transparent" flat>
                    <div class="nolo-h3 pb-4">{{ $t('skipTheQueue') }}</div>
                    <v-card light flat>
                        <v-text-field outlined v-model="postcode"
                                      class="centered-input"
                                      hide-details @keydown.enter="lookupPostcode"
                                      label="" :placeholder="$t('placeholder.enterPostcode')"/>
                    </v-card>
                    <v-btn block color="white primary--text" class="mt-6 mb-7" large
                           :disabled="disablePostcode" :depressed="disablePostcode" elevation="0"
                           @click="lookupPostcode">{{ $t('btn.findFiveGuys') }}
                    </v-btn>
                    <v-btn block outlined color="white black--text"
                           :disabled="loading" :loading="loading"
                           @click="locatorButtonPressed">
                        {{ $t('btn.useLocation') }}
                        <v-icon right small>mdi-crosshairs-gps</v-icon>
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import APIStore from '../../api/APIStore';
    import StoreUtil from "../../plugins/StoreUtil";

    export default {
        name: "StartOrder",
        data() {
            return {
                loading: false,
                postcode: '',
                response: 'xxxx',
                versionInfo: false,
                debugCount: 0,
            }
        },
        computed: {
            stores() {
                return this.$store.getters['app/stores'];
            },
            disablePostcode() {
                return this.loading || this.postcode.length === 0;
            },
        },
        methods: {
            setGPSLocation(loc, description) {
                this.$store.commit('session/gpsLocation', loc);
                this.$store.commit('session/location', description);
                this.whereNext(this.stores, loc);

            },
            whereNext(allStores, customerLocation) {
                let stores = StoreUtil.storesByDistance(allStores, customerLocation, StoreUtil.MAX_STORE_RESULTS);
                if (stores.length === 0) {
                    this.appShowErrorDialog(this.$t('error.noStoresNearby'));
                    return;
                }

                //if (StoreUtil.storesCanDeliver(stores, customerLocation)) {
                //    this.$router.push({name: 'start-order-type'}); // NE-2393 disable the "type" page
                //} else {
                    this.$router.push('/store-list');
                //}
            },
            lookupPostcode() {

                if (this.loading) return; // already busy

                if (this.postcode === '') return;

                if(typeof window.fbq != 'undefined') {
                  window.fbq('track', 'FindLocation');
                }

                if (this.postcode.toLowerCase() === 'luke-eddie') {
                    this.debugCount += 1;
                    this.$store.commit('app/versionInfo', this.debugCount >= 5);
                    return;
                }

                this.loading = true;

                // query for "stratford" should default to "westfield stratford", not "stratford upon avon"
                if (this.postcode.toLowerCase() === 'stratford') {
                    this.postcode = 'westfield stratford'
                }

                const me = this;
                APIStore.getLocation(this, this.postcode)
                    .then(response => {
                        me.setGPSLocation({
                            lat: response.data.data.latitude,
                            lng: response.data.data.longitude
                        }, this.postcode);
                    })
                    .catch(() => {
                        this.appShowOops();
                    })
                    .finally(() => { me.loading = false; });
            },
            locatorButtonPressed() {

                const me = this;
                me.loading = true;

                if(typeof window.fbq != 'undefined') {
                  window.fbq('track', 'FindLocation');
                }

                navigator.geolocation.getCurrentPosition(
                    position => {
                        me.loading = false;
                        me.setGPSLocation({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        }, me.$t('currentLocation'));
                    },
                    error => {
                        me.loading = false;
                        me.appShowErrorDialog(error.message, 'error');
                        console.log(error.message);
                    },
                )
            }
        }
    }
</script>

<style scoped>

    .centered-input >>> fieldset {
        border: 1px solid var(--v-nolo-dark-grey-base) !important;
    }

    .centered-input >>> input {
        text-align: center;
        text-transform: uppercase;
    }

    .centered-input >>> input::placeholder {
        text-align: center;
        text-transform: none;
    }

    .theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
        background-color: var(--v-disabled-base) !important;
        color: var(--v-nolo-dark-grey-base) !important;
    }

</style>

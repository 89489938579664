<script>
    import InputRules from "../plugins/InputRules";
    import APINoquApp from "../api/APINoquApp";
    import Reorder from "../plugins/Reorder";
    import APICustomer from "../api/APICustomer";
    import {i18n} from '../i18n';

    export default {
        methods: {
            reorderIfRequired(allowBreakfast) {
                const me = this;
                return new Promise(function (resolve, reject) {
                    if (me.reorderId !== null) {
                        APICustomer.reorder(me, me.reorderId)
                            .then(response => {
                                // eslint-disable-next-line no-unused-vars
                                let hasBreakfast = response.data.data.hasBreakfast;
                                if (hasBreakfast && !allowBreakfast) {
                                    reject(i18n.t('error.reorderBreakfastNotAllowed'));
                                } else {
                                    let reorder = Reorder.reorder(me, response.data.data.pseudoBasket);
                                    me.newUpsells(reorder.upsells);
                                    me.newBasket(reorder.basket);
                                    resolve();
                                }
                            })
                            .catch((error) => {
                                console.log(error);
                                reject(i18n.t('error.unknownError'));
                            })
                            .finally(() => {
                                me.reorderId = null;
                            })
                    }

                });
            },
            clearBasket() {
                this.$store.commit('session/basket', []);
                this.$store.commit('session/basket', []);
                this.$store.commit('session/orderTotals', []);
                this.$store.commit('session/upsells', JSON.parse(JSON.stringify(this.$t('upsells'))));
            }
            ,
            appShowErrorDialog(message, status, okFunction) {
                if (typeof status === 'undefined') status = 'error';
                this.$store.dispatch('app/showErrorDialog', {status: status, message: message, okFunction: okFunction});
            }
            ,
            appShowOops(message) {
                if (typeof message === 'undefined') message = 'Oops, something went wrong';
                this.$store.commit('app/oopsMessage', message);
                this.$store.commit('app/oopsDialog', true);
            }
            ,
            wip() {
                this.$router.push({name: 'wip'});
            },
            sessionUndefined(variable) {
                if (typeof this.$store.getters['session/' + variable] === 'undefined') return true;
                // noinspection RedundantIfStatementJS
                if (this.$store.getters['session/' + variable] === null) return true;
                return false;
            },
            appHandoffSuccessful(data) {
                this.$store.commit('session/orderId', data.orderId);
                this.$store.commit('session/orderTotals', data.orderTotals);
            }
        },
        computed: {
            reorderId: {
                get() {
                    return this.$store.getters['session/reorder'];
                },
                set(newValue) {
                    this.$store.commit('session/reorder', newValue);
                }
            },
            cmsDomain() {
                return 'https://' + this.$store.getters['app/cookies'].domain;
            },
            appCurrency() {
                return this.$store.getters['session/currency'];
            },
            appLanguage() {
                return this.$store.getters['session/language'];
            },
            now() {
                return this.$store.getters['app/now'];
            },
            initialSiteLoad() {
                return this.$store.getters['app/initialSiteLoad'];
            },
            isAppMode() {
                return APINoquApp.isInNoquApp();
            },
            inputRules() {
                return InputRules;
            },
            isLoggedIn() {
                return this.$store.getters['session/token'] !== null;
            },
            isXs() {
                return this.$vuetify.breakpoint.name === 'xs';
            },
        }
    }
</script>

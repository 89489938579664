function sessCurrency(vue) {
    return vue.$store.getters['session/currency'];
}

function getModifierGroupById(menu, modifierGroupId) {
    for (let mg in menu.modifierGroups) {
        if (menu.modifierGroups[mg].id === modifierGroupId) return menu.modifierGroups[mg];
    }
    return null;
}

function findMenuModifier(menu, modifierId) {
    for (let m in menu.modifiers) {
        if (menu.modifiers[m].id === modifierId) return menu.modifiers[m];
    }
    return null;
}

// eslint-disable-next-line no-unused-vars
function getModifiers(salesItem, modifiers, menu, currency) {

    let codeToGroupXref = {};
    let basketModifiers = {};
    for (let i in salesItem.modifierGroups) {
        let modifierGroupId = salesItem.modifierGroups[i];
        let modifierGroup = getModifierGroupById(menu, modifierGroupId);
        codeToGroupXref[modifierGroup.code] = modifierGroup;
        basketModifiers[modifierGroupId] = {
            id: modifierGroup.id,
            groupCode: modifierGroup.code,
            groupName: modifierGroup.name,
            modifiers: [],
            valid: true
        }
    }

    for (let m in modifiers) {

        if (typeof codeToGroupXref[modifiers[m].group] === 'undefined') {
            // topping group doesn't exist
            continue;
        }

        // eslint-disable-next-line no-unused-vars
        let children = codeToGroupXref[modifiers[m].group].children;
        for (let c in children) {
            let menuModifier = findMenuModifier(menu, children[c]);
            if (menuModifier.code === modifiers[m].modifier) {
                let modifier = {
                    quantity: 1,
                    id: menuModifier.id,
                    code: menuModifier.code,
                    name: menuModifier.name,
                    unitPrice: menuModifier.price === null ? null : menuModifier.price[currency]
                }
                let modifierGroupId = codeToGroupXref[modifiers[m].group].id;
                basketModifiers[modifierGroupId].modifiers.push(modifier);
            }
        }

    }

    return basketModifiers;

}

function getItemByCode(code, modifiers, quantity, menu, currency) {

    for (let si in menu.salesItems) {
        let salesItem = menu.salesItems[si];
        if (salesItem.code === code) {
            return getBasketItem(salesItem, modifiers, quantity, menu, currency);
        }
    }

    return null;
}

function getBasketItem(salesItem, modifiers, quantity, menu, currency) {

    let basketModifiers = getModifiers(salesItem, modifiers, menu, currency);

    // eslint-disable-next-line no-unused-vars
    let siPrice = salesItem.price[currency];
    let basketItem = {
        id: salesItem.id,
        code: salesItem.code,
        name: salesItem.name,
        price: siPrice,
        modifierPrice: 0,
        quantity: quantity,
        lineTotal: siPrice * quantity,
        modifiers: basketModifiers,
        categoryCode: getCatCodeForSalesItemId(salesItem.id, menu)
    };

    return basketItem;

}

function getCatCodeForSalesItemId(salesItemId, menu) {

    for (let c in menu.categories) {

        if (menu.categories[c].salesItems.indexOf(salesItemId) >= 0) {
            return menu.categories[c].code;
        }
    }
    return null;

}

function setUpsellByCode(upsells, code, quantity) {

    for (let u in upsells) {
        if (upsells[u].product === code) {
            upsells[u].quantity = quantity;
        }
    }

}

function reorder(vue, pseudoBasket) {

    let reorderBasket = [];
    let reorderUpsells = JSON.parse(JSON.stringify(vue.$t('upsells')));

    let menu = vue.$store.getters['app/flatMenu'];

    for (let i in pseudoBasket) {
        let item = pseudoBasket[i];

        let menuItem = getItemByCode(item.code, item.modifiers, item.quantity, menu, sessCurrency(vue));
        if (menuItem === null) {
            setUpsellByCode(reorderUpsells, item.code, item.quantity);
        } else {
            reorderBasket.push(menuItem);
        }
    }

    return {basket: reorderBasket, upsells: reorderUpsells};

}

export default {
    reorder: reorder
}

<template>
    <v-app :class="layoutClasses">

        <app-header v-if="ready"/>

        <div class="time-tweaker" v-if="showTimeTweaker">
            <v-layout class="align-center py-1 px-3">
                <span class="headline primary white--text px-4 rounded-pill">{{ now.date }} {{ now.time }}</span>
                <v-slider class="ml-3" hide-details track-color="grey"
                          @keydown.right="updateMinsToAddNow(5)"
                          @keydown.left="updateMinsToAddNow(-5)"
                          :min="0" :max="1200" step="5" v-model="appMinsToAddToNow"/>
            </v-layout>
        </div>

        <v-main v-touch="{ right: handleSwipeRight }" v-if="ready">
            <router-view/>
        </v-main>
        <app-footer class="nolo-footer" v-if="ready"/>

        <oops-dialog v-model="oops"/>

      <BouncingBurgerOverlay :message="$t('message.waitingCookiesOT')" :value="oneTrustCookies && showCookiePolicy"/>

      <cookie-policy-dialog @input="setCookiePolicy"
                            :value="showCookiePolicy"
                            v-if="ready && cookiesReady && !oneTrustCookies"/>

        <error-dialog :error="errorDialog" v-model="showErrorDialog"/>

        <v-dialog v-model="versionInfo" max-width="480" persistent>
            <version-info @dismiss="versionInfo = false" />
        </v-dialog>

        <v-dialog v-if="updatePopupValidation" width="320" persistent>
            <v-card>
                <v-card-text class="py-3">
                    <v-container>
                        <v-row>
                            <v-col class="text-center">
                                <site-logo width="240"/>
                                <v-icon size="156" color="nolo-dark-grey"
                                >mdi-autorenew</v-icon>
                                <div class="nolo-subtitle text-center pt-2 pb-4">{{$t('message.updateAvailable')}}</div>
                                <v-btn depressed block :disabled="swUpdatedStarted" :loading="swUpdatedStarted" color="secondary white--text" @click="refreshApp">
                                    Update now
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-overlay color="white" :opacity="1" :value="!ready">
            <v-container class="text-center">
                <lottie-animation path="./_/img/burger-loop.json"
                                  :width="350"
                                  :height="350"/>
                <div class="nolo-h6 mt-8 mx-8 black--text"></div>
            </v-container>
        </v-overlay>

    </v-app>
</template>

<script>

    import SoftwareUpdate from "./mixins/SoftwareUpdate";
    import APIApp from './api/APIApp';
    import APICustomer from './api/APICustomer';
    import APIStore from './api/APIStore';
    import OopsDialog from "./components/OopsDialog";
    import ErrorDialog from "./components/ErrorDialog";
    import CookiePolicyDialog from "./components/CookiePolicyDialog";
    import SiteLogo from "./components/UI/SiteLogo";
    import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
    import VersionInfo from "./components/VersionInfo";
    import BouncingBurgerOverlay from "./components/UI/BouncingBurgerOverlay";

    export default {
        name: 'App',
        components: {
            VersionInfo,
            SiteLogo,
            OopsDialog,
            ErrorDialog,
            CookiePolicyDialog,
            LottieAnimation,
            BouncingBurgerOverlay
        },
        data: () => ({
            swUpdatedStarted: false,
            updatePopupValidation: false,
            cookiesAccepted: false,
            oneTrustLoaded: false,
            oneTrustHasCookieButCookieIsNotSetInEchidna: false,
            checkingOneTrustInterval: null,
        }),
        mixins: [
            SoftwareUpdate
        ],
        mounted() {
            this.scheduleUpdateNow();

            const me = this;
            APIStore.getStores(this)
                .catch(() => {
                    // Do nothing
                });
            APIApp.getConfig(this)
                .then(() => {
                    APICustomer.getCustomer(me)
                        .catch(() => {
                            // Default the customer so the app will start. This is a little hacky
                            APICustomer.defaultCustomer(me);
                        })
                })
                .catch(() => {
                    // Default the customer so the app will start. This is a little hacky
                    APICustomer.defaultCustomer(me);
                });
        },
        watch: {
            ready: {
                immediate: true,
                handler(newValue) {
                    this.$store.commit('app/ready', newValue);
                }
            },
          oneTrustLoaded: function () {
              //one Trust has loaded
              clearInterval(this.checkingInterval);
              let localThis = this;
              let deleteCookies = function () {
                let cookies = document.cookie.split(";");
                for (let i = 0; i < cookies.length; i++) {
                  let cookie = cookies[i];
                  let eqPos = cookie.indexOf("=");
                  let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                  document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
                }
              };

              let acceptAllCookies = function () {
                localThis.setCookiePolicy('1');
              };
              let acceptOnlyStrictlyNecessaryCookies = function () {
                deleteCookies(); // if user has accepted cookies before and now is rejecting we need to delete the other ones
                localThis.setCookiePolicy('2');
              };

              APIApp.getOneTrustSettings(this)
                .then((response) => {
                  let settings = response.data.data.settings;
                  if (document.getElementById(settings['id-accept-all'])) {
                    document.getElementById(settings['id-accept-all']).onclick = acceptAllCookies;
                  }
                  if(document.getElementById(settings['id-allow-all'])) {
                    document.getElementById(settings['id-allow-all']).onclick = acceptAllCookies;
                  }

                  let rejectElements = document.getElementsByClassName(settings['class-reject-all']);
                  document.getElementById(settings['id-reject-all']).onclick = acceptOnlyStrictlyNecessaryCookies;
                  for (let i = 0; i < rejectElements.length; i++) {
                    rejectElements[i].addEventListener('click', acceptOnlyStrictlyNecessaryCookies, false);
                  }
                  let adjustCookiePolicy = function () {
                      let performanceSwitch = document.getElementById(settings['id-performance-cookies']);
                      if (performanceSwitch && performanceSwitch.hasAttribute('checked')) {
                        acceptAllCookies();
                      } else {
                        acceptOnlyStrictlyNecessaryCookies();
                    }
                  };
                  if (localThis.oneTrustHasCookieButCookieIsNotSetInEchidna) {
                    //If one trust has cookies (cookies were accepted elsewhere then we still need to honor it)
                    adjustCookiePolicy();
                  } else {
                    acceptOnlyStrictlyNecessaryCookies();
                  }
                  //We only care about performance cookies if it's enable then cookie policy is 1 otherwise 2
                  let confirmElements = document.getElementsByClassName(settings['class-confirm-choices']);
                  for (let i = 0; i < confirmElements.length; i++) {
                    confirmElements[i].addEventListener('click', adjustCookiePolicy, false);
                  }
                })
                .catch(() => {
                  //If it fails hard code to the defaults
                  if (document.getElementById('onetrust-accept-btn-handler')) {
                    document.getElementById('onetrust-accept-btn-handler').onclick = acceptAllCookies;
                  }
                  if (document.getElementById('accept-recommended-btn-handler')) {
                    document.getElementById('accept-recommended-btn-handler').onclick = acceptAllCookies;
                  }

                  let rejectElements = document.getElementsByClassName("ot-pc-refuse-all-handler");
                  if(document.getElementById('onetrust-reject-all-handler')) {
                    document.getElementById('onetrust-reject-all-handler').onclick = acceptOnlyStrictlyNecessaryCookies;
                  }
                  for (let i = 0; i < rejectElements.length; i++) {
                    rejectElements[i].addEventListener('click', acceptOnlyStrictlyNecessaryCookies, false);
                  }

                  //We only care about performance cookies if it's enable then cookie policy is 1 otherwise 2
                  let adjustCookiePolicy = function () {
                    let performanceSwitch = document.getElementById('ot-group-id-C0002');
                    if (performanceSwitch && performanceSwitch.hasAttribute('checked')) {
                      acceptAllCookies();
                    } else {
                      acceptOnlyStrictlyNecessaryCookies();
                    }
                  };
                  if (localThis.oneTrustHasCookieButCookieIsNotSetInEchidna) {
                    //If one trust has cookies (cookies were accepted elsewhere then we still need to honor it)
                    adjustCookiePolicy();
                  } else {
                    acceptOnlyStrictlyNecessaryCookies();
                  }
                  let confirmElements = document.getElementsByClassName("save-preference-btn-handler");
                  for (let i = 0; i < confirmElements.length; i++) {
                    confirmElements[i].addEventListener('click', adjustCookiePolicy, false);
                  }
                });
          }
        },
        methods: {
            scheduleUpdateNow() {
                const me = this;
                let now = new Date();
                var currentDateSeconds = now.getSeconds();
                if (currentDateSeconds === 0) {
                    setInterval(me.updateNow, 60000);
                } else {
                    setTimeout(function () {
                        me.scheduleUpdateNow();
                    }, (60 - currentDateSeconds) * 1000);
                }

                this.updateNow();
            },
            updateNow() {
                this.$store.commit('app/now');
            },
            handleSwipeRight(ev) {
                if (ev.offsetX > 100) this.$router.back();
            },
            refreshApp() {
                this.swUpdatedStarted = true;

                window.location.reload()
                window.setTimeout(function () {
                  location.href = location.pathname;
                }, 2000);
            },
            updateMinsToAddNow(mins) {
                console.log('here');
                this.appMinsToAddToNow = Math.min(Math.max(this.appMinsToAddToNow + mins, 0), 1200)
            },
            setCookiePolicy(value) {
                this.cookiePolicy = value;
            },
            showUpdatePopup: function() {
              this.updatePopupValidation = true;
            },
        },
        computed: {
            appMinsToAddToNow: {
                get() { return this.$store.getters['app/appMinsToAddToNow']},
                set(newValue) { return this.$store.commit('app/appMinsToAddToNow', newValue)}
            },
            version() {
                return process.env.VERSION;
            },
            locale() {
                return process.env.VUE_APP_LOCALE;
            },
            showGuard() {
                    return this.$store.getters['app/showGuard'];
                },
            showTimeTweaker() {
                    return this.$store.getters['app/showTimeTweaker'];
                },
            showLoginStatus() {
                    return this.$store.getters['app/showLoginStatus'];
            },
            versionInfo: {
                get() { return this.$store.getters['app/versionInfo'] },
                set(newValue) { this.$store.commit('app/versionInfo', newValue); }
            },
            hasConfig() {
                return this.$store.getters['app/cookies'] !== null;
            },
            hasCustomer() {
                return this.$store.getters['session/customer'] !== null;
            },
            hasStores() {
                if (typeof this.$store.getters['app/stores'] === 'undefined') return false;
                if (this.$store.getters['app/stores'] === null) return false;

                return Object.keys(this.$store.getters['app/stores']).length > 0;
            },
            ready() {
                return this.hasStores && this.hasCustomer && this.hasConfig;
            },
            oops: {
                set: function (value) {
                    this.$store.commit('app/oopsDialog', value);
                },
                get: function () {
                    return this.$store.getters['app/oopsDialog'];
                }
            },
            oneTrustCookies(){
              if (this.$store.getters['app/cookieMode'] === 'ONE_TRUST') {
                if(!document.getElementById('auto-block-script')){
                  let oneTrustScript = document.createElement('script');
                  oneTrustScript.setAttribute("id", "auto-block-script");
                  oneTrustScript.setAttribute('src', process.env.VUE_APP_ONE_TRUST_AUTO_BLOCK_SRC);
                  document.head.appendChild(oneTrustScript);
                }
                if(!document.getElementById('ot-sdk-stub-script')) {
                  let oneTrustScript2 = document.createElement('script');
                  oneTrustScript2.setAttribute('src', process.env.VUE_APP_ONE_TRUST_OT_SDK_STUB);
                  oneTrustScript2.setAttribute('data-domain-script', process.env.VUE_APP_ONE_TRUST_OT_SDK_STUB_DATA_DOMAIN_SCRIPT);
                  oneTrustScript2.setAttribute('id', 'ot-sdk-stub-script');
                  oneTrustScript2.setAttribute('data-document-language', 'true');
                  document.head.appendChild(oneTrustScript2);
                }
                return true;
              }
              if (this.$store.getters['app/cookieMode'] === 'STANDARD') {
                clearInterval(this.checkingInterval);
              }
              return false;
            },
             cookiesReady() {
                 return this.$store.getters['app/cookies'] !== null;
             },
            showCookiePolicy() {
                return this.cookiePolicy !== '1' && this.cookiePolicy !== '2';
            },
            cookiePolicy: {
                set: function (value) {
                    this.$store.commit('app/cookiePolicy', value);
                },
                get: function () {
                    return this.$store.getters['app/cookiePolicy'];
                }
            },
            errorDialog: {
                set: function (value) {
                    this.$store.commit('app/errorDialog', value);
                },
                get: function () {
                    return this.$store.getters['app/errorDialog'];
                }
            },
            showErrorDialog: {
                set: function (value) {
                    this.$store.commit('app/errorDialog', {...this.showErrorDialog, show: value});
                },
                get: function () {
                    return this.$store.getters['app/errorDialog'].show;
                }
            },
            layoutClasses() {
                let classes = '';
                if (this.$store.getters['layout/appLike']) {
                    classes += ' app-like';
                } else {
                    classes += ' not-app-like'
                }
                classes += ' ' + this.appLanguage;
                return classes;
            }
        },
        created: function() {
            this.$on('refreshApp', this.refreshApp);
            let localThis = this;
            this.checkingInterval = setInterval(function(){
              if (document.getElementById('onetrust-accept-btn-handler') || document.getElementsByClassName('ot-floating-button__open').length > 0) {
                localThis.oneTrustLoaded = true;
                if(document.getElementsByClassName('ot-floating-button__open').length > 0){
                  //Cookie policy was already accepted in one trust but we don't have it in echidna
                  localThis.oneTrustHasCookieButCookieIsNotSetInEchidna = true;
                }
              }
            }, 500);
        }
    };
</script>
function OptanonWrapper() { }

<!--suppress CssUnusedSymbol -->
<style>

    .time-tweaker {
        position: absolute;
        top: 80px;
        left: 0;
        background-color: rgba(255,255,255,0.85);
        outline-color: var(--v-primary-base);
        width: 500px;
        z-index: 1000000;
    }

    .app-like .v-main {
        height: 100vh;
        overflow: hidden;
    }

    .not-app-like .v-main {
        min-height: 100vh;
    }

    .grecaptcha-badge {
        z-index: 100; /* force recaptcha over top of overlay */
    }

</style>


<script>
    export default {
        computed: {
            breakfastAllowed() {
                let fulfilmentTimeObj = this.$store.getters['session/fulfilmentTime'];
                if (fulfilmentTimeObj === null) return false;

                let fulfilmentTime = fulfilmentTimeObj.time;

                if (parseInt(fulfilmentTime) === -1) {
                    let d = new Date();
                    fulfilmentTime = d.getHours() * 100 + d.getMinutes();
                }
                return fulfilmentTime <= 1000;
            },
        }
    };
</script>

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
const theme = {
    primary: '#D21033',
    "nolo-dark-red": '#AC0D2A',
    error: '#D21033',
    success: '#72D11B',
    secondary: '#111111',
    "nolo-dark-grey": '#505050',
    "nolo-mid-grey": '#D4D4D4',
    'nolo-light-grey': '#F5F5F5',
    'warning': '#F2E828',
    disabled: '#D4D4D4',
    deliveroo: '#00CDBC'

};

export default new Vuetify({
    breakpoint: {
        thresholds: {
            xs: 480,
            sm: 800,
            md: 1200,
            lg: 1440,
        }
    },
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            light: theme
        }
    }
});

<template>
    <v-container class="d-flex justify-center">
        <v-row no-gutters class="inbound-container">
            <v-col cols="12" class="d-flex text-center text-md-left">

                <v-card dark class="start-order grow transparent" flat v-if="isLoggedIn">
                    <div class="nolo-h3 pb-4">{{ $t('helloCustomer', { name: customer.firstName}) }}</div>
                    <div v-if="hasPreviousOrders" class="nolo-h5 pb-2">{{$t('message.havePreviousOrders')}}</div>
                    <router-link :to="{name: 'start-order-location'}" class="text-decoration-none">
                        <v-btn depressed block color="white primary--text" class="my-3">
                            {{ $t('startNewOrder' )}}
                        </v-btn>
                    </router-link>
                    <v-btn @click="orderFromLastStore" v-if="hasPreviousOrders && ((previousStoreIsOnline && canReorder) || forceShowReorder)" depressed block
                           color="white primary--text" class="my-4">
                        {{ $t('btn.newOrderTypeFromWhere', { orderType: orderType, where: where }) }}
                    </v-btn>
                    <v-btn v-if="hasPreviousOrders" @click="gotoPreviousOrders" depressed block color="nolo-previous-orders" class="my-4">
                        {{ $t('btn.myPreviousOrders') }}
                    </v-btn>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>

    import Constants from "../../api/Constants";

    export default {
        name: "StartOrder",
        data() {
            return {
                loading: true,
            }
        },
        mounted() {
            this.$store.dispatch('session/resetProgress');
            if (!this.isLoggedIn) {
                this.$router.replace({name: 'start-order-location'});
                return;
            }
        },
        methods: {
            gotoPreviousOrders() {
                this.$router.push({name: 'account-previous-orders'});
            },
            orderFromLastStore() {
                if (this.customer.recentOrders[0].order_type === Constants.OrderType.Table) {
                    let store = this.$store.getters['app/stores'][this.storeId];
                    if (typeof store === 'undefined') {
                        this.$router.push({name: '404'});
                    } else {
                        this.$router.push({name: 'table-service', params: {storeId: store.code }});
                    }
                } else {
                    this.$store.commit('session/storeId', this.customer.recentOrders[0].store_id);
                    this.$store.commit('session/orderType', this.customer.recentOrders[0].order_type);
                    this.$router.push({name: 'fulfilment-time'});
                }
            }
        },
        computed: {
            storeId() {
                if (this.hasPreviousOrders) {
                    return this.customer.recentOrders[0].store_id;
                } else {
                    return null;
                }
            },
            orderType() {
                if (this.hasPreviousOrders) {
                    return this.customer.recentOrders[0].order_type;
                } else {
                    return '';
                }
            },
            where() {
                if (this.hasPreviousOrders) {
                    return this.customer.recentOrders[0].store_name;
                } else {
                    return '';
                }
            },
            hasPreviousOrders() {
                if (typeof this.customer.recentOrders === 'undefined') return false;
                return this.customer.recentOrders.length > 0;
            },
            previousStoreIsOnline() {
              return this.customer.recentOrders[0].store_is_available == 1;
            },
            canReorder() {
              return this.customer.recentOrders[0].can_reorder == 1;
            },
            forceShowReorder() {
              return this.customer.recentOrders[0].force_show_reorder == 1;
            },
            customer() {
                return this.$store.getters['session/customer'];
            }
        }
    }
</script>

<style scoped>

    .nolo-previous-orders {
        background-color: white !important;
        border: black solid 2px;
        color: black;
    }

    /*@media (max-width: 959px) {*/
    @media (max-width: 800px) {
        .start-order {
            text-align: center;
        }
    }

    .v-btn, .v-btn__content {
        white-space: normal !important;
        display: block;
    }

    .nolo-h5 {
        line-height: 1.1;
    }

</style>

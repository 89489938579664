// TODO: IMPORTANT - I feel we shouldn't read / write session as a big chunk of data we should do this (like app.js) on a field by field basis.

const LS_SESS_APP = 'ss_app';

import APIOrder from "../../api/APIOrder";
import {i18n} from '../../i18n';

const ssSession = sessionStorage.getItem(LS_SESS_APP) === null ? {} : JSON.parse(sessionStorage.getItem(LS_SESS_APP));

function updateSession() {
    sessionStorage.setItem(LS_SESS_APP, JSON.stringify(state));
}

const state = {
    maxOrderValue: 100,
    maxOrderMains: 6,
    isChecked: false,
    isPaid: false,
    isPaymentRejected: false,
    isFailed: false,
    isComplete: false,
    sessionId: null,
    reorder: null,
    token: null,
    storeId: null,
    tableNumber: null,
    orderType: null,
    orderId: null,
    orderTotals: [],
    orderProgress: APIOrder.Constants.OrderProgress.INBOUND,
    gpsLocation: null,
    location: null,
    fulfilmentDate: null,
    fulfilmentTime: null,
    fulfilmentTimes: null,
    latestFulfilmentTime: null,
    customer: null,
    currency: 'GBP',
    language: 'en-GB',
    basket: [],
    basketQuantities: [],
    upsells: [],
    guest: {},
    vehicle: null,
    guestMarketing: false,
    ...ssSession
};

// getters
const getters = {
    maxOrderValue: state => state.maxOrderValue,
    maxOrderMains: state => state.maxOrderMains,
    cookieMode: state => state.cookieMode,
    isChecked: state => state.isChecked,
    isPaid: state => state.isPaid,
    isPaymentRejected: state => state.isPaymentRejected,
    isFailed: state => state.isFailed,
    isComplete: state => state.isComplete,
    sessionId: state => state.sessionId,
    reorder: state => state.reorder,
    token: state => state.token,
    gpsLocation: state => state.gpsLocation,
    location: state => state.location,
    fulfilmentTime: state => state.fulfilmentTime,
    fulfilmentTimes: state => state.fulfilmentTimes,
    latestFulfilmentTime: state => state.latestFulfilmentTime,
    fulfilmentDate: state => state.fulfilmentDate,
    storeId: state => state.storeId,
    tableNumber: state => state.tableNumber,
    orderType: state => state.orderType,
    orderId: state => state.orderId,
    orderTotals: state => state.orderTotals,
    orderProgress: state => state.orderProgress,
    customer: state => state.customer,
    currency: state => state.currency,
    language: state => state.language,
    basket: state => state.basket,
    basketQuantities: state => state.basketQuantities,
    guest: state => state.guest,
    vehicle: state => state.vehicle,
    guestMarketing: state => state.guestMarketing,
    upsells: state => state.upsells,
    all: state => state
};

// mutations
const mutations = {
    isChecked(state, payload) {
        state.isChecked = payload;
        updateSession();
    },
    isPaid(state, payload) {
        state.isPaid = payload;
        updateSession();
    },
    isPaymentRejected(state, payload) {
        state.isPaymentRejected = payload;
        updateSession();
    },
    isFailed(state, payload) {
        state.isFailed = payload;
        updateSession();
    },
    isComplete(state, payload) {
        state.isComplete = payload;
        updateSession();
    },
    sessionId(state, payload) {
        state.sessionId = payload;
        updateSession();
    },
    reorder(state, payload) {
        state.reorder = payload;
        updateSession();
    },
    gpsLocation(state, payload) {
        state.gpsLocation = payload;
        updateSession();
    },
    token(state, payload) {
        state.token = payload;
        updateSession();
    },
    location(state, payload) {
        state.location = payload;
        updateSession();
    },
    fulfilmentDate(state, payload) {
        state.fulfilmentDate = payload;
        updateSession();
    },
    fulfilmentTime(state, payload) {
        state.fulfilmentTime = payload;
        updateSession();
    },
    fulfilmentTimes(state, payload) {
        state.fulfilmentTimes = payload;
        updateSession();
    },
    storeId(state, payload) {
        state.storeId = payload;
        updateSession();
    },
    orderType(state, payload) {
        state.orderType = payload;
        updateSession();
    },
    tableNumber(state, payload) {
        state.tableNumber = payload;
        updateSession();
    },
    orderId(state, payload) {
        state.orderId = payload;
        updateSession();
    },
    orderTotals(state, payload) {
        state.orderTotals = payload;
        updateSession();
    },
    orderProgress(state, payload) {
        // console.log('OrderProgress: Was ' +  APIOrder.getProgressText(state.orderProgress) + ', Set to ' + APIOrder.getProgressText(payload));
        state.orderProgress = payload;
        updateSession();
    },
    customer(state, payload) {
        state.customer = payload;
        updateSession();
    },
    currency(state, payload) {
        state.currency = payload;
        updateSession();
    },
    language(state, payload) {
        state.language = payload;
        updateSession();
    },
    basket(state, payload) {
        state.basket = payload;
        updateSession();
    },
    basketQuantities(state, payload) {
        state.basketQuantities = payload;
        updateSession();
    },
    upsells(state, payload) {
        state.upsells = payload;
        updateSession();
    },
    guest(state, payload) {
        state.guest = payload;
        updateSession();
    },
    vehicle(state, payload) {
        state.vehicle = payload;
        updateSession();
    },
    guestMarketing(state, payload) {
        state.guestMarketing = payload;
        updateSession();
    },
    maxOrderValue(state, payload) {
        state.maxOrderValue = payload;
        updateSession();
    },
    maxOrderMains(state, payload) {
        state.maxOrderMains = payload;
        updateSession();
    },
    latestFulfilmentTime(state, payload) {
        state.latestFulfilmentTime = payload;
        updateSession();
    }
};

// actions
const actions = {
    resetProgress({commit}) {
        commit('isChecked', false);
        commit('isPaid', false);
        commit('isPaymentRejected', false);
        commit('isFailed', false);
        commit('isComplete', false);
        commit('reorder', null);
        commit('basket', []);
        commit('orderId', null);
        commit('fulfilmentDate', null);
        commit('fulfilmentTime', null);
        commit('orderTotals', null);
        commit('basketQuantities', []);
        commit('upsells', JSON.parse(JSON.stringify(i18n.t('upsells'))));
        commit('orderProgress', APIOrder.Constants.OrderProgress.INBOUND);
    },
    logout({commit, dispatch}) {
        commit('customer', {
            "email": null,
            "firstName": null,
            "lastName": null,
            "phoneNumber": null,
            "vehicles": [],
            "marketingOptIn": false,
            "addressId": null,
            "addresses": [],
            "paymentAddress": null,
            "recentOrders": []
        });
        commit('token', null)
        dispatch('resetProgress');
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

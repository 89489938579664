<template>
    <page-layout-nolo>
        <v-container class="fill-height pa-8 align-md-center justify-center inbound-container-page">
            <v-row>
                <v-col cols="12" class="nolo-h3 text-center mb-6 pl-6">
                    {{ $t('whenCollect')}}
                </v-col>
            </v-row>
            <v-row v-if="isLoading">
                <v-col cols="12" sm="6">
                    <v-skeleton-loader height="14" type="image" class="my-1" width="30"/>
                    <v-skeleton-loader height="48" type="image"/>
                </v-col>
                <v-col cols="12" sm="6">
                    <v-skeleton-loader height="14" type="image" class="my-1" width="40"/>
                    <v-skeleton-loader height="48" type="image"/>
                </v-col>
            </v-row>
            <v-row v-show="!isLoading">
                <v-col cols="12" sm="6">
                    <native-select v-model="pickedDate" @change="changedDate" :disabled="isLoading"
                                   item-value="date"
                                   :items="validDates" item-text="desc"
                                   :label="$t('label.day')"></native-select>
                </v-col>
                <v-col cols="12" sm="6">
                    <native-select v-model="pickedTime" :disabled="isLoading"
                                   item-value="time"
                                   :items="availableTimes" item-text="desc"
                                   :label="$t('label.time')"></native-select>
                </v-col>
            </v-row>
            <v-btn class="mt-4" @click="orderMyFood"
                   large color="primary white--text" depressed
                   :disabled="invalidForm" block>{{ $t('btn.orderMyFood') }}
            </v-btn>
        </v-container>

    </page-layout-nolo>
</template>

<script>
    import APIStores from '../../api/APIStore';
    import NativeSelect from "../../components/UI/NativeSelect";
    import FulfilmentTime from "../../plugins/FulfilmentTime";

    export default {
        name: "FulfilmentTime",
        components: {NativeSelect},
        data() {
            return {
                dayNo: 0,
                pickedDate: null,
                pickedTime: null,
                isLoading: true,
                fulfilmentDays: [],
                fulfilmentTimes: [],
            }
        },
        mounted() {
            const me = this;
            this.pickedDate = this.sessFulfilmentDate;
            this.pickedTime = this.sessFulfilmentTime;
            APIStores.getFulfilmentTimesFor(this, this.storeId, this.orderType)
                .then((response) => {
                    me.fulfilmentDays = response.data.data.fulfilmentDays;
                    me.fulfilmentTimes = response.data.data.fulfilmentTimes;
                    me.setDefaultTimes();
                    me.isLoading = false;
                })
            .catch( () => {
              me.appShowErrorDialog(me.$t('error.noFulfillmentTimes'),'error', me.$router.push({name: 'start-order'}))
          });
        },
        methods: {
            getDefaultDayNo(sessDefault) {
                if (typeof sessDefault === 'undefined' || sessDefault === null) {
                    return 0;
                } else {
                    let matchDate = sessDefault.date;
                    for (let d in this.fulfilmentDays) {
                        if (this.fulfilmentDays[d].date === matchDate) return this.fulfilmentDays[d].index;
                    }
                    return 0;
                }
            },
            getDefaultTime(sessDefault) {
                // // eslint-disable-next-line no-debugger
                // debugger;
                if (typeof sessDefault !== 'undefined' && sessDefault !== null) {
                    let matchTime = sessDefault.time;
                    for (let t in this.availableTimes) {
                        if (this.availableTimes[t].time === matchTime) return this.availableTimes[t];
                    }
                }

                try {
                    return this.availableTimes[0];
                } catch {
                    return null;
                }
            },
            changedDate() {
                if (this.pickedDate !== null) {
                    this.dayNo = this.pickedDate.index;
                    this.pickedTime = this.getDefaultTime(this.pickedTime);
                } else {
                    this.pickedTime = null;
                }
            },
            setDefaultTimes() {
                const me = this;
                this.dayNo = this.getDefaultDayNo(this.sessFulfilmentDate);
                this.pickedDate = this.validDates.find(el => el.index === me.dayNo) ?? null;
                this.pickedTime = this.getDefaultTime(this.sessFulfilmentTime) ?? null;
            },
            orderMyFood() {
                this.$store.commit('session/fulfilmentDate', this.pickedDate);
                this.$store.commit('session/fulfilmentTime', this.pickedTime);
                this.$store.commit('session/fulfilmentTimes', this.fulfilmentTimes[this.dayNo]);
                this.$store.commit('session/latestFulfilmentTime', this.latestFulfilmentTime);

                this.$router.push('/order');
            }
        },
        computed: {
            availableTimes() {
                this.now; // access this so Vue knows
                this.fulfilmentTimes; // access this so Vue knows

                if (this.isManipulatingToday) {
                    return FulfilmentTime.trimFulfilment(this.fulfilmentTimes[this.dayNo], this.now);
                } else {
                    return this.fulfilmentTimes[this.dayNo];
                }
            },
            isManipulatingToday() {
                if (this.dayNo !== 0) return false;
                if (this.now.date28 === null) return false;
                if (typeof this.fulfilmentDays[this.dayNo] === 'undefined') return false;
                return this.now.date28 >= this.fulfilmentDays[this.dayNo].date;
            },
            validDates() {
                return this.fulfilmentDays.filter(el => {
                    return this.fulfilmentTimes[el.index].length > 0;
                });
            },
            invalidForm() {
                if (this.isLoading) return true;
                return this.availableTimes.length === 0
                    || this.pickedTime === null
                    || this.pickedDate <= this.now.date28;
            },
            storeId() {
                return this.$store.getters['session/storeId']
            },
            orderType() {
                return this.$store.getters['session/orderType']
            },
            sessFulfilmentDate() {
                return this.$store.getters['session/fulfilmentDate'];
            },
            sessFulfilmentTime() {
                return this.$store.getters['session/fulfilmentTime'];
            },
            latestFulfilmentTime() {
                let times = this.fulfilmentTimes[this.dayNo];
                if (times.length === 0) return null;

                return times[times.length - 1].time;
            }
        }

    }
</script>

<style scoped>

.theme--light.v-btn.v-btn--disabled {
  color: #fff !important
}

</style>

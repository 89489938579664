/* eslint-disable no-console */

import {register} from 'register-service-worker'

if (process.env.NODE_ENV === 'production') {
    if (process.env.VUE_APP_SERVICE_WORKER === 'false') {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.getRegistrations().then(function (registrations) {
                for (let registration of registrations) {
                    registration.unregister().then(function () {
                        window.location.reload()
                    });
                }
            }).catch(function (err) {
                console.log('Service Worker de-registration failed: ', err);
            });
        }
    } else {
        register(`${process.env.BASE_URL}service-worker.js`, {
            ready() {
                console.log('Echidna - checking domain.');
                if(window.location.origin.includes("order.fiveguys.co.uk")) {
                    console.log('Echidna - redirection NXG.');
                    window.location.href = 'https://order.fiveguys.co.uk/findpostcode';
                }
                //console.log('Echidna is being served from cache by a service worker.')
            },
            registered() {
                console.log('Service worker has been registered.')
            },
            cached() {
                console.log('Content has been cached for offline use.')
            },
            updatefound() {
                console.log('New content is downloading.')
            },
            updated(registration) {
                document.dispatchEvent(
                    new CustomEvent('swUpdated', {detail: registration})
                );
                caches.keys().then(function(names) {
                    for (let name of names)
                        caches.delete(name);
                });
                console.log('New content is available; please refresh.')
            },
            offline() {
                console.log('No internet connection found. App is running in offline mode.')
            },
            error(error) {
                console.error('Error during service worker registration:', error)
            }
        })
    }
}

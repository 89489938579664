<template>

    <v-layout class="qty">
        <v-btn icon color="var(--v-secondary-base)"
               :x-large="xLarge" :large="large" :small="small" :x-small="xSmall"
               :disabled="isMinDisabled"
               @click="changeQty(-1)">
            <v-icon :x-large="xLarge" :large="large" :small="small" :x-small="xSmall">mdi-minus-circle-outline</v-icon>
        </v-btn>
        <div style="color: var(--v-secondary-base);"
             class="qtyText text-center font-weight-black align-self-center flex-grow-1"
             :class="small || xSmall || smallText ? ' small' : xLarge || large ? ' nolo-h5' : ''">{{ localValue }}
        </div>
        <v-btn icon color="var(--v-secondary-base)"
               :x-large="xLarge" :large="large" :small="small" :x-small="xSmall"
               :disabled="localValue >= max || disabled || disabledInc"
               @click="changeQty(1)">
            <v-icon :x-large="xLarge" :large="large" :small="small" :x-small="xSmall">mdi-plus-circle-outline</v-icon>
        </v-btn>
    </v-layout>

</template>

<script>

    export default {
        name: "ShopQuantity",
        props: {
            xSmall: {type: Boolean, default: false},
            small: {type: Boolean, default: false},
            smallText: {type: Boolean, default: false},
            large: {type: Boolean, default: false},
            xLarge: {type: Boolean, default: false},
            disabled: {type: Boolean, default: false},
            disabledInc: {type: Boolean, default: false},
            cancelOnZero: {type: Boolean, default: false},
            value: {type: Number, default: 1},
            min: {type: Number, default: 1},
            max: {type: Number, default: 99},
            itemIdx: {type: Number, default: 0}
        },
        data() {
            return {
                localValue: this.value
            }
        },
        watch: {
            value: {
                immediate: true,
                handler(newValue) {
                    this.localValue = newValue;
                }
            }
        },
        methods: {
            changeQty(howMuch) {
                this.localValue += howMuch;
                if (this.localValue === 0 && this.cancelOnZero) {
                    this.$emit('cancel');
                }
                this.localValue = Math.min(this.max, Math.max(this.min, this.localValue));
                this.$emit('input', this.localValue);
                this.$emit('change', this.localValue);
                this.$emit('gtmChangeEvent', {howMuch: howMuch, itemIdx: this.itemIdx});
            }
        },
        computed: {
            isMinDisabled() {
                return !this.canReduce || this.disabled;
            },
            canReduce() {
                return this.localValue > (this.cancelOnZero ? 0 : this.min);
            }
        }
    }
</script>

<style scoped>

    .qtyText {
        -webkit-user-select: none;
        user-select: none;
    }
    .qtyText.small {
        font-size: 16px;
    }

    .theme--light.v-btn.v-btn--disabled {
        background-color: transparent !important;
    }

</style>

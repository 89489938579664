import _ApiHelper from "./_ApiHelper";

function getConfig(vue) {

    let permToken = vue.$store.getters['app/permToken'];
    let permEmail = null;
    let rememberMe = null;
    try {
        permEmail = permToken.email;
        rememberMe = permToken.rememberMe;
    } catch {
        // do nothing
    }

    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/app/config',
                data: {
                    email: permEmail,
                    rememberMe: rememberMe
                },
                headers: _ApiHelper.httpHeadersWithToken(vue)
            })
            .then(function (response) {
                _ApiHelper.sessionTracker(vue, response);
                vue.$store.commit('session/token', response.data.data.token);
                vue.$store.commit('app/links', response.data.data.links);
                vue.$store.commit('app/versions', response.data.data.versions);
                vue.$store.commit('app/cookies', response.data.data.cookie);
                vue.$store.commit('app/cookieMode', response.data.data.cookieMode);
                vue.$store.commit('session/maxOrderMains', response.data.data.limits.maxOrderMains);
                vue.$store.commit('session/maxOrderValue', response.data.data.limits.maxOrderValue);
                vue.$store.commit('session/language', response.data.data.language);
                vue.$store.commit('session/currency', response.data.data.currency);
                vue.$i18n.locale = _ApiHelper.simpleLanguage(response.data.data.language);
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}


function getOneTrustSettings(vue) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'GET',
                url: '/apiui/app/getOneTrustSettings',
                headers: _ApiHelper.httpHeadersWithToken(vue)
            },
        )
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

export default {
    getConfig: getConfig,
    getOneTrustSettings: getOneTrustSettings
}

const TIME_PADDING = 16;
import { format } from 'date-fns'

function trimFulfilment(fulfilmentTimes, now) {

    // you shouldn't be able to select a time in the next 25 mins - that's ASAP.
    let latestASAPOrderTime = new Date(now.jsDate);

    let latestASAPOrderTimeAscii = format(latestASAPOrderTime.setMinutes(latestASAPOrderTime.getMinutes() + TIME_PADDING), 'YYYY-MM-DD HH:mm');
    // eslint-disable-next-line no-unused-vars
    return fulfilmentTimes.filter(el => {
        return (el.time === -1 && now.date + ' ' + now.time <= el.last) || el.datetime > latestASAPOrderTimeAscii;
    });

}

function validateFulfilmentTime(time, fulfilmentTimes, now) {

    // time: { time: 1800, desc: "18:00" }

    let availableTimes = trimFulfilment(fulfilmentTimes, now);

    let hasAsap = false;
    for (let i in availableTimes) {
       if (availableTimes[i].time === -1) {
           hasAsap = true;
       }
       if (availableTimes[i].time === time.time) {
           return availableTimes[i];
       }
    }

    if (hasAsap) {
        return availableTimes[0];
    }

    return null;

}

export default {
    trimFulfilment: trimFulfilment,
    validateFulfilmentTime: validateFulfilmentTime
}

<template>

    <v-row class="pa-3 pb-0 mb-0">
        <v-col cols="12" class="pl-4 pr-1 pr-sm-4" v-if="true === false">
            <v-row>
                <v-col cols="12">
                    <div class="nolo-p2 font-weight-bold">{{ $t('anySauces') }}</div>
                    <div class="nolo-p3 pb-1 black--text">
                        {{ $t('hint.anySaucesChoose') }} <strong>{{ $t('hint.anySaucesHowMany') }}</strong> {{ $t('hint.anySaucesFavourites') }}
                    </div>
                </v-col>
            </v-row>
            <v-row no-gutters v-for="upsell in localUpsells" :key="upsell.name">
                <v-col cols="7" class="align-center d-flex">
                    <div class="nolo-p2 font-weight-black text-uppercase sauce">{{upsell.name}}</div>
                </v-col>
                <v-col cols="5" class="pl-3">
                    <shop-quantity :min="0" :max="6" v-model="upsell.quantity" @change="changed" :large="bigButtons" :disabledInc="!anyRoomLeft"></shop-quantity>
                </v-col>
            </v-row>
        </v-col>
    </v-row>

</template>

<script>
    export default {
        name: "ShopUpsells",
        data() {
            return {
                localUpsells: []
            };
        },
        watch: {
            upsells: {
                immediate: true,
                handler: function (newValue) {
                    this.localUpsells = JSON.parse(JSON.stringify(newValue));
                }
            }
        },
        methods: {
            changed() {
                this.$emit('change', this.localUpsells);
            }
        },
        props: {
            upsells: { type: Array, required: true },
            bigButtons: {type: Boolean, default: false }
        },
        computed: {
            upsellCount() {
                let items = 0;
                this.upsells.forEach(el => items += el.quantity);
                return items;
            },
            anyRoomLeft() {
                return this.upsellCount < 6;
            }
        }
    }
</script>

<style scoped>

.sauce {
    -webkit-user-select: none;
    user-select: none;
}

</style>


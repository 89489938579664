

<template>
    <v-card tile :class="classForItem"
            @mouseover="isHovering = true"
            @mouseout="isHovering = false"
    >
        <v-container class="fill-height">
            <v-row no-gutters dense class="fill-height pa-1" @click="selectedItem">

                <v-col :cols="item.imageUrl ? 7 : 12" class="fill-height">
                    <div class="shop-item-title nolo-subtitle" v-bind:class="{ 'pr-9': !item.imageUrl }">{{ item.name[language] }}</div>
                    <div class="shop-item-desc mid-grey--text nolo-p3 nolo-dark-grey--text mt-2" v-html="item.description[language]"></div>
                    <shop-product-info :modifier="false" :productInfo="item.productInfo[language]" @increase-card-size="increaseCardSize()"/>
                </v-col>

                <v-col cols="5" v-if="item.imageUrl">
                    <v-img cover class="itemImg"
                           :src="item.imageUrl"
                    />
                    <v-spacer></v-spacer>
                </v-col>

                <v-avatar v-if="onOrder > 0" class="shop-item-on-order" size="24" color="nolo-dark-grey white--text">{{onOrder}}</v-avatar>

                <div class="shop-item-price text-right px-1 white--text">
                    {{ item.available ? priceFormat(item.price) : $t('label.unavailable')}}
                </div>

            </v-row>
        </v-container>
    </v-card>

</template>

<script>
    import ShopCurrency from "../../mixins/ShopCurrency";
    import ShopProductInfo from "@/components/Shop/ShopProductInfo";

    export default {
        name: "ShopItem",
        components: {ShopProductInfo},
      data() {
            return {
                isHovering: false,
            }
        },
        props: {
            flatMenu: {type: Object, required: true},
            itemId: {type: String, required: true},
            language: {type: String, required: true},
            currency: {type: String, required: true},
            cardStyle: {type: String, required: true},
            onOrder: {type: Number, default: 0}
        },
        mixins: [ShopCurrency, ShopProductInfo],
        methods: {
            selectedItem() {
                if (this.item.available) {
                    this.$emit('selected-item', this.item);
                }
            },
          increaseCardSize(){
              this.cardStyle = "shop-item-with-product-info";
              this.$emit('increase-card-size', this.item);
          }
        },
        computed: {
            classForItem() {
                let className = this.cardStyle;
                className += this.isHovering ? ' elevation-8' : ' elevation-0';
                className += this.onOrder ? ' shop-on-order' : ' shop-not-on-order';
                className += this.item.available ? '' : ' shop-unavailable';
                return className;
            },
            item() {
                let idx = null;
                for (let i in this.flatMenu.salesItems) {
                    if (this.flatMenu.salesItems[i].id === this.itemId) {
                        idx = i;
                        break;
                    }
                }
                return this.flatMenu.salesItems[idx];
            }
        }
    }
</script>

<!--suppress CssUnusedSymbol, CssUnknownProperty -->
<style scoped>

    .shop-item {
        margin: auto;
        height: 144px;
        cursor: pointer;
        box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15) !important;
    }

    .shop-item-with-product-info {
      margin: auto;
      height: 154px;
      cursor: pointer;
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15) !important;
    }

    @media (min-width: 480px) {
        .shop-item {
            min-width: 346px;
            width: 346px;
            margin: initial;
        }
    }

    .shop-item.shop-unavailable {
        opacity: 0.45;
        cursor: default;
        filter: grayscale(1);
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.10) !important;
    }

    .shop-item-on-order {
        position: absolute;
        bottom: 12px;
        right: 12px;
        font-size: 14px !important;
        line-height: 20px;
        font-weight: 700;
    }

    .shop-item-price {
        position: absolute;
        top: 12px;
        right: -4px;
        height: 22px;
        min-width: 49px;
        font-size: 14px !important;
        line-height: 14px;
        padding-top: 5px;
        padding-left: 10px !important;
        font-weight: 700;
        background-image: url("/_/img/price-label.svg");
        background-size: cover;
        background-position-y: center;
    }

    @media (max-device-width: 480px) {
        .shop-item-price {
            padding-top: 4px;
        }
    }

    .shop-item-title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .shop-item-desc {
        height: 60px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .itemImg {
        position: absolute;
        width: 112px;
        height: 112px;
        right: 20px;
        top: 16px;
    }

    .shop-on-order .container {
        outline: 1px solid var(--v-nolo-dark-grey-base);
    }

</style>

<template>
    <v-container class="pa-0">
        <div class="title d-flex align-center" :class="!showErrors ? 'pb-6' : ''">
            <v-row no-gutters class="d-flex align-center">
                <v-col cols="12" md="8">
                    <span class="text-uppercase font-weight-black">{{ modifierGroup.name[language] }}</span>
                </v-col>
                <v-col cols="12" md="3" offset-md="1"
                       v-if="modifierGroup.code === 'toppings'"
                       class="text-md-right pr-2">
                    <v-btn outlined block :class="allTheWay ? 'black white--text': ''"
                           @click="clickedAllTheWay">{{$t('btn.allTheWay')}}
                    </v-btn>
                </v-col>
                <v-col cols="12" md="4" class="pr-2 pt-2 pt-md-0" v-if="modifierGroup.code === 'mixes'">
                    <div class="justify-md-end d-flex">
                        <div class="d-flex nolo-p2 font-weight-black nolo-dark-grey--text text-uppercase align-center mixIns"
                             v-ripple="{ class: `nolo-mid-grey--text` }"
                             @click="preConfigClear">
                            <v-icon class="pr-1 nolo-dark-grey--text">mdi-close-circle</v-icon>
                            {{$t('btn.clearMixins')}}
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
        <div class="nolo-p2" v-if="showErrors"
             :class="showErrors && !valid ? 'error--text font-weight-bold' : 'nolo-dark-grey--text'">Choose
            {{ rangeText }} {{ modifierGroup.allowMultiples ?
            ', multiples allowed' : '' }}
        </div>
        <p class="nolo-dark-grey--text caption" v-if="modifierGroup.description[language]">
            {{modifierGroup.description[language]}}</p>
        <shop-mg-radio v-if="mgType === 'radio'"
                       @change="handleChangedValue"
                       :pre-config="preConfig"
                       @clear-pre-config="preConfig = null"
                       :show-errors="showErrors"
                       :allow-multiples="modifierGroup.allowMultiples"
                       :min="modifierGroup.minimumChildren"
                       :max="modifierGroup.maximumChildren"
                       :modifierGroup="modifierGroup" :language="language" :currency="currency"
                       @new-price="newPrice"
                       class="mb-2"
        />
        <shop-mg-checkbox v-if="mgType === 'checkbox'"
                          @change="handleChangedValue"
                          :pre-config="preConfig"
                          @clear-pre-config="preConfig = null"
                          :show-errors="showErrors"
                          :allow-multiples="modifierGroup.allowMultiples"
                          :min="modifierGroup.minimumChildren"
                          :max="modifierGroup.maximumChildren"
                          :modifierGroup="modifierGroup" :language="language" :currency="currency"
                          @new-price="newPrice"
                          class="mb-4"
        />
    </v-container>
</template>

<script>

    import ShopMgRadio from "./ShopMgRadio";
    import ShopMgCheckbox from "./ShopMgCheckbox";

    export default {
        name: "ShopModifierGroup",
        data() {
            return {
                preConfig: null,
                modifierPrice: 0,
                valid: false,
                modifiers: []
            }
        },
        components: {ShopMgRadio, ShopMgCheckbox},
        props: {
            flatMenu: {type: Object, required: true},
            modifierGroupId: {type: String, required: true},
            language: {type: String, required: true},
            currency: {type: String, required: true},
            showErrors: {type: Boolean, default: false},
            clear: {type: String, default: ''},
            initConfig: {type: Array, required: true}
        },
        watch: {
            clear(newValue) {
                if (newValue === this.modifierGroupId) {
                    this.preConfigClear();
                    this.$emit('cleared');
                }
            }
        },
        mounted() {
            this.handleInitConfig();
        },
        methods: {
            handleInitConfig() {
                let items = [];
                for (let i in this.modifierGroup.items) {
                    let item = this.modifierGroup.items[i];
                    let parts = item.code.split(':');
                    if (this.initConfig.indexOf(parts[0]) >= 0) {
                        items.push(parseInt(i));
                    }
                }
                this.preConfig = items;
            },
            clickedAllTheWay() {
                if (this.allTheWay) {
                    this.preConfigClear();
                } else {
                    this.preConfigAllTheWay();
                }
            },
            preConfigClear() {
                this.preConfig = [];
            },
            preConfigAllTheWay() {
                this.preConfig = JSON.parse(JSON.stringify(this.configAllTheWay));

                // // Surely a better way to stop handleChangedValue clearing this immediately...
                // setTimeout(() => this.allTheWay = true, 200);
            },
            getItemsFor(config) {
                let items = [];
                for (let i in this.modifierGroup.items) {
                    let item = this.modifierGroup.items[i];
                    let parts = item.code.split(':');
                    if (config.indexOf(parts[0]) >= 0) {
                        items.push(parseInt(i));
                    }
                }
                return items;
            },
            preConfigStandard() {
                this.preConfig = this.configStandard;
            },
            newPrice(price) {
                this.$emit('new-price', {id: this.modifierGroupId, price: price});
            },
            handleChangedValue(changeItem) {
                this.modifiers = changeItem.modifiers;
                this.$emit('change', changeItem);
                this.valid = changeItem.valid;
            }
        },
        computed: {
            configStandard() {
                return this.getItemsFor(['lettuce', 'grilledonions', 'grilledmushrooms', 'tomato', 'greenpeppers']);
            },
            configAllTheWay() {
                return this.getItemsFor([
                    'ketchup',
                    'mayo',
                    'mustard',
                    'lettuce',
                    'pickles',
                    'tomato',
                    'grilledonions',
                    'grilledmushrooms'
                ]);
            },
            allTheWay() {
                let mods = [];
                for (let i in Object.keys(this.modifiers)) {
                    let code = this.modifiers[i].code;
                    let parts = code.split(':');
                    code = parts[0];

                    mods.push(code);
                }
                let selItems = this.getItemsFor(mods);
                return selItems.join(',') === this.configAllTheWay.join(',');
            },
            rangeText() {
                if (this.modifierGroup.minimumChildren === this.modifierGroup.maximumChildren) return this.modifierGroup.maximumChildren;
                if (this.modifierGroup.minimumChildren === 0) return 'up to ' + this.modifierGroup.maximumChildren;
                return this.modifierGroup.minimumChildren + ' - ' + this.modifierGroup.maximumChildren;
            },
            mgType() {
                if (!this.modifierGroup.allowMultiples && this.modifierGroup.minimumChildren <= 1 && this.modifierGroup.maximumChildren === 1) {
                    return 'radio'
                }
                return 'checkbox'
            },
            modifierGroup() {
                const me = this;
                let modifierGroup = {
                    ...this.flatMenu.modifierGroups.find(el => el.id === me.modifierGroupId),
                    items: []
                };
                for (let idx in modifierGroup.children) {
                    let childId = modifierGroup.children[idx];
                    let child = (modifierGroup.groupMode === 'SALES_ITEMS')
                        ? this.flatMenu.salesItems.find(el => el.id === childId)
                        : this.flatMenu.modifiers.find(el => el.id === childId)
                    modifierGroup.items.push(child);
                }

                return modifierGroup;
            }
        }
    }
</script>

<style scoped>

    .mixIns {
        cursor: pointer;
        -webkit-user-select: none;
        user-select: none;
    }

</style>

<template>
    <v-row no-gutters class="storeList">
        <v-col v-if="stores.length">
            <store-card @store-select="payload => $emit('store-select', payload)" :result-no="storeIdx + 1" :store="store" v-for="(store, storeIdx) in stores" :key="store.id"/>
        </v-col>
        <v-col v-else>
            <v-skeleton-loader v-for="r in 2" :key="r"
                    class="mb-3"
                    type="article, actions"
            ></v-skeleton-loader>
        </v-col>
    </v-row>
</template>

<script>
    import StoreCard from "./StoreCard";

    export default {
        name: "StoreList",
        components: {StoreCard},
        props: {
            stores: {type: Array, required: true}
        }
     }
</script>

<style scoped>

    @media (min-width: 800px) {
        .storeList {
            height: calc(100vh - 386px);
        }
    }

</style>


<script>
    import APINoquApp from "../api/APINoquApp";
    import Gtag from "./Gtag";
    import Gtm from "./Gtm";

    export default {
        data: function () {
            return {
                basket: [],
                upsells: [],
                selectedItemShow: false,
                selectedItem: null,
                selectedCategoryCode: null,
                selectedPreConfig: [],
                selectedQuantity: 1,
                selectedBasketIndex: null
            }
        },
        mounted() {
            this.basket = this.sessBasket;
            this.upsells = this.sessUpsells;
        },
        mixins: [Gtag, Gtm],
        methods: {
            initialConfig(code) {
                if (code === 'veg' || code === 'cveg') {
                    return {
                        toppings:
                            [
                                'lettuce',
                                'grilledonions',
                                'grilledmushrooms',
                                'tomato',
                                'greenpeppers',
                            ]
                    };
                }

                if (code === 'blt') {
                    return {
                        toppings: [
                            'lettuce',
                            'tomato',
                            'mayo',
                        ]
                    };
                }

                return {};
            },
            getCategoryByCode(code) {
                for (let i in this.appFlatMenu.categories) {
                    if (this.appFlatMenu.categories[i].code === code) return this.appFlatMenu.categories[i];
                }
                return null;
            },
            getItemByCode(code) {
                for (let i in this.appFlatMenu.salesItems) {
                    if (this.appFlatMenu.salesItems[i].code === code) return this.appFlatMenu.salesItems[i];
                }
                return null;
            },
            selectItem(item) {
                this.selectedItem = item.item;
                this.selectedCategoryCode = item.category.code;
                this.selectedQuantity = 1;
                this.selectedPreConfig = this.initialConfig(item.item.code);
                this.selectedBasketIndex = null;
                this.selectedItemShow = true;
                this.gtmViewItemEvent(item);
            },
            // eslint-disable-next-line no-unused-vars
            editItem(itemIdx) {
                let basketItem = this.basket[itemIdx];
                let category = this.getCategoryByCode(basketItem.categoryCode);
                this.selectedItem = this.getItemByCode(basketItem.code);
                this.selectedCategoryCode = category.code;
                this.selectedQuantity = basketItem.quantity;
                let preConfig = {};
                Object.keys(basketItem.modifiers).forEach(key => {
                    let mgMods = [];
                    for (let i in basketItem.modifiers[key].modifiers) {
                        let code = basketItem.modifiers[key].modifiers[i].code;
                        let parts = code.split(':');
                        code = parts[0];
                        mgMods.push(code);
                    }
                    if (mgMods.length) {
                        preConfig[basketItem.modifiers[key].groupCode] = mgMods;
                    }
                });
                this.selectedPreConfig = preConfig;

                this.selectedBasketIndex = itemIdx;
                this.selectedItemShow = true;
            },
            storeBasket() {
                this.sessBasket = JSON.parse(JSON.stringify(this.basket));
            },
            newBasket(basket) {
                this.basket = basket;
                this.storeBasket();
            },
            newUpsells(upsells) {
                this.upsells = upsells;
                this.sessUpsells = JSON.parse(JSON.stringify(this.upsells));
            },
            addedItem(item) {
                this.gtmAddToCartEvent(item)

                if (this.selectedBasketIndex === null) {
                    this.basket.push(item);
                } else {
                    this.$set(this.basket, this.selectedBasketIndex, item);
                    // this.basket = [...this.basket];
                }
                this.storeBasket();
            },
        },
        watch: {
            basket(newValue) {
                this.sessBasket = JSON.parse(JSON.stringify(newValue));
            },
            selectedItemShow: {
                handler(newValue) {
                    APINoquApp.swipeToRefreshEnabled(this, !newValue);
                }
            }
        },
        computed: {
            sessBasket: {
                get() {
                    return this.$store.getters['session/basket'];
                },
                set(newValue) {
                    this.$store.commit('session/basket', newValue);
                }
            },
            sessBasketQuantities: {
                get() {
                    return this.$store.getters['session/basketQuantities'];
                },
                set(newValue) {
                    this.$store.commit('session/basketQuantities', newValue);
                }
            },
            appFlatMenu: {
                get() {
                    return this.$store.getters['app/flatMenu'];
                },
                set(newValue) {
                    this.$store.commit('app/flatMenu', newValue);
                }
            },
            sessUpsells: {
                get() {
                    return this.$store.getters['session/upsells'];
                },
                set(newValue) {
                    this.$store.commit('session/upsells', newValue);
                }
            },
            basketQuantities() {
                let qtys = {};
                for (let b in this.basket) {
                    let item = this.basket[b];
                    if (typeof qtys[item.id] === 'undefined') {
                        qtys[item.id] = 0;
                    }
                    qtys[item.id] += item.quantity;
                }
                return qtys;
            },
        }
    };
</script>

<template>
    <v-container class="pa-0">
        <label class="nolo-p3">{{label}}</label>
        <v-input class="d-block native-select">
            <select id="input-select" v-model="localValue" v-if="haveItems">
                <option :value="item" v-for="item in items" :selected="item === localValue" :key="item.value">
                    {{item[itemText]}}
                </option>
            </select>
        </v-input>
    </v-container>

</template>

<script>
    export default {
        name: "NativeSelect",
        data() {
            return {
                localValue: {},
            }
        },
        props: {
            items: {type: Array, default: () => []},
            label: {type: String, default: ''},
            itemText: {type: String, default: 'text'},
            itemValue: {type: String, default: 'value'},
            value: {required: true}
        },
        watch: {
            localValue(newValue) {
                this.$emit('input', newValue);
                this.$emit('change', newValue);
            },
            value: {
                immediate: true,
                handler(newValue) {
                    if (typeof(newValue) === 'undefined') {
                        this.localValue = null;
                    } else {
                        let result = null;
                        for (let v in this.items) {
                            if (JSON.stringify(this.items[v]) === JSON.stringify(newValue)) result = this.items[v];
                        }
                        this.localValue = result;
                        if (result === null ** newValue !== null) this.$emit('input', result);
                    }
                }
            }
        },
        methods: {
            objectForValue(value) {
                const me = this;
                let result = this.items.filter(el => el[me.itemValue] === value);
                if (result.length === 0) return null;
                return result[0];
            }
        },
        computed: {
            haveItems() {
                return this.items.length > 0;
            }
        }
    }
</script>

<style scoped>

    .native-select {
        display: flex;
        background-color: white;
        height: 48px;
        background-clip: border-box;
        border: 1px solid #999;
        border-radius: 4px;
        align-content: center;
    }

    .native-select:hover {
        border-color: var(--v-nolo-dark-grey-base);
    }

    .native-select:focus-within {
        border-color: var(--v-primary-base);
        box-shadow: 0 0 0 1px var(--v-primary-base);
        color: var(--v-primary-base);
    }

    .native-select >>> .v-input__slot::before {
        content: "\F0140";
        font: normal normal normal 24px/1 "Material Design Icons";
        top: 10px;
        right: 12px;
        position: absolute;
        pointer-events: none;
        transform: rotate(0deg);
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
    }

    /*.native-select:focus-within >>> .v-input__slot::before {*/
    /*    transform: rotate(180deg);*/
    /*    color: var(--v-primary-base);*/
    /*}*/

    .native-select select {
        appearance: none;
        background-color: transparent;
        border: none;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;
        align-self: center;
        height: 48px;
        min-width: 100%;
        padding: 0 10px 4px 10px;
        cursor: pointer;
        outline: none;
    }

    .native-label {
        left: 0;
        right: auto;
        position: absolute;
    }

</style>

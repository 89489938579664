<template>
    <page-layout-with-side-image>
        <transition name="fade" mode="out-in">
            <router-view/>
        </transition>

    </page-layout-with-side-image>
</template>

<script>
    export default {
        name: "PageStartOrder",
        data() {
            return {
                loading: true,
            }
        },
        mounted() {
            this.clearBasket();
        },
    }
</script>

<style scoped>

    .nolo-previous-orders {
        background-color: white !important;
        border: black solid 2px;
        color: black;
    }

    /*@media (max-width: 959px) {*/
    @media (max-width: 800px) {
        .start-order {
            text-align: center;
        }
    }

    .v-btn, .v-btn__content {
        white-space: normal !important;
        display: block;
    }

    .nolo-h5 {
        line-height: 1.1;
    }

</style>

export default {
    "name": "Gtm",
    computed: {
        isGTMTrackingEnabled() {
            if (this.$store.getters['app/cookiePolicy'] == '2') {
                return false;
            }
            return !!process.env.VUE_APP_GOOGLE_TAG_MANAGER;
        }
    },
    methods: {
        gtmViewItemListEvent(items, category) {
            if (this.isGTMTrackingEnabled) {
                let menuItems = [];

                for (const currentCategory of category) {
                    for (const categoryItem of currentCategory.salesItems) {
                        let foundItem = items[categoryItem];
                        menuItems.push({
                            "item_id": foundItem.code,
                            "item_name": Object.values(foundItem.name)[0],
                            "item_category": Object.values(currentCategory.name)[0],
                            "price": Object.values(foundItem.price)[0] / 100
                        });
                    }
                }

                if (this.isGTMTrackingEnabled) {
                    this.$gtm.trackEvent({ ecommerce: null });
                    this.$gtm.trackEvent({
                        event: 'view_item_list',
                        category: 'ecommerce',
                        label: 'view_item_list',
                        ecommerce: {
                            //item_list_id: category ? category.code : "full_menu",
                            //item_list_name: category ? Object.values(category.name)[0] : "Full Menu",
                            item_list_id: "full_menu",
                            item_list_name: "Full Menu",
                            items: menuItems
                        }
                    });
                }
            }
        },
        gtmViewCartEvent(basket) {
            if (this.isGTMTrackingEnabled) {
                let currency;
                let value = 0;
                let basketItems = [];
                for (const itemLine of basket) {

                    currency = itemLine.currency;
                    value += (itemLine.price + itemLine.modifierPrice) * itemLine.quantity;
                    basketItems.push({
                        "item_list_id": itemLine.categoryCode,
                        "item_id": itemLine.code,
                        "item_name": Object.values(itemLine.name)[0],
                        "price": (itemLine.price + itemLine.modifierPrice) / 100,
                        "quantity": itemLine.quantity
                    })
                }

                this.$gtm.trackEvent({ ecommerce: null });
                this.$gtm.trackEvent({
                    event: 'view_cart',
                    category: 'ecommerce',
                    label: 'view_cart',
                    ecommerce: {
                        currency: currency,
                        value: value / 100,
                        items: basketItems
                    }
                });

            }
        },
        gtmViewItemEvent(item) {
            let itemClone = { ...item.item };
            itemClone.price = Object.values(item.item.price)[0];
            itemClone.currency = Object.keys(item.item.price)[0];
            itemClone.categoryCode = item.category.code;
            itemClone.quantity = 1;
            this.gtmSendCommonEcommerceEvent('view_item', itemClone);
        },
        gtmAddToCartEvent(item) {
            this.gtmSendCommonEcommerceEvent('add_to_cart', item);
        },
        gtmRemoveFromCartEvent(item) {
            this.gtmSendCommonEcommerceEvent('remove_from_cart', item);
        },
        gtmSendCommonEcommerceEvent(action, item) {
            if (this.isGTMTrackingEnabled) {
                this.$gtm.trackEvent({ ecommerce: null });
                this.$gtm.trackEvent({
                    event: action,
                    category: 'ecommerce',
                    label: action,
                    ecommerce: {
                        currency: item.currency,
                        value: (item.price + item.modifierPrice) * item.quantity / 100,
                        items: [
                            {
                                item_id: item.code,
                                item_name: Object.values(item.name)[0],
                                price: (item.price + item.modifierPrice) / 100,
                                item_list_id: item.categoryCode,
                                quantity: item.quantity
                            }
                        ]
                    }
                });
            }
        },
        gtmPurchaseEvent(data) {
            if (this.isGTMTrackingEnabled) {
                this.$gtm.trackEvent({ ecommerce: null });
                this.$gtm.trackEvent({
                    event: "purchase",
                    category: 'ecommerce',
                    label: "purchase",
                    ecommerce: data
                });
            }
        }
    }
}

// noinspection JSUnusedGlobalSymbols
export default {
    data() {
        return {
            registration: null,
            updateExists: false,
        }
    },
    created() {
        document.addEventListener('swUpdated', this.updateAvailable, { once: true });
    },
    methods: {
        updateAvailable(event) {
            console.log('... update avail ', event);

            this.registration = event.detail;
            this.updateExists = true;

            if (process.env.VUE_APP_AUTO_UPDATE) {
                console.log("auto updating sw");
                this.refreshApp();
            } else {
                console.log("display sw update modal");
                this.showUpdatePopup();
            }
        }
    }
}

const LS_PERM_TOKEN = 'nolo_perm_token';
const LS_LINKS = 'nolo_config_links';
const LS_FLAT_MENU = 'nolo_flat_menu';
const LS_COOKIES = 'nolo_config_cookies';
const LS_COOKIE_MODE = 'nolo_config_cookie_mode';
const LS_ONE_TRUST_SETTINGS = 'nolo_config_one_trust_settings';
const LS_COOKIE_POLICY = 'nolo_config_cookie_policy';
const LS_STORE_LIST = 'store_list';
const LS_SHOW_GUARD = 'show_guard';
const LS_SHOW_DEBUG_PANEL = 'show_debug_panel';
const LS_TIME_TWEAKER = 'show_time_tweaker';
const LS_SHOW_LOGIN = 'show_login';
const LS_APP_MINS_TO_ADD_TO_NOW = 'app_mins_to_add_to_now';

function getFromStorage(key, defaultValue) {
    try {
        let jsonVal = localStorage.getItem(key);
        if (jsonVal === null) return defaultValue;
        return JSON.parse(jsonVal);
    } catch {
        return defaultValue;
    }
}

import CookiePolicy from "../../plugins/CookiePolicy";

let cookies = getFromStorage(LS_COOKIES, null);
const state = {
    appMinsToAddToNow: getFromStorage(LS_APP_MINS_TO_ADD_TO_NOW),
    versions: {
        'echidnaAPI': '0.0.0',
        'noloCore': '0.0.0'
    },
    now: {
        jsDate: null,
        date: null,
        time: null,
        timeInt: null,
        date28: null,
        time28: null,
        time28Int: null
    },
    cookiePolicy: CookiePolicy.checkCookiePolicyAck(cookies, '0'),
    permToken: getFromStorage(LS_PERM_TOKEN, null),
    links: getFromStorage(LS_LINKS, null),
    cookies: getFromStorage(LS_COOKIES, ''),
    cookieMode: getFromStorage(LS_COOKIE_MODE, null),
    oneTrustSettings: getFromStorage(LS_ONE_TRUST_SETTINGS, null),
    stores: getFromStorage(LS_STORE_LIST, {}),
    initialSiteLoad: true,
    customer: null,
    flatMenu: getFromStorage(LS_FLAT_MENU, null),
    showDebugPanel: getFromStorage(LS_SHOW_DEBUG_PANEL, false),
    showGuard: getFromStorage(LS_SHOW_GUARD, false),
    showTimeTweaker: getFromStorage(LS_TIME_TWEAKER, false),
    showLoginStatus: getFromStorage(LS_SHOW_LOGIN, false),
    oopsDialog: false,
    errorDialog: { show: false, message: '', status: 'error'},
    oopsMessage: '',
    versionInfo: false,
    justRegistered: false,
    ready: false
};

// gettersedit
const getters = {
    all: state => state,
    now: state => state.now,
    versions: state => state.versions,
    permToken: state => state.permToken,
    links: state => state.links,
    cookies: state => state.cookies,
    cookiePolicy: state => state.cookiePolicy,
    cookieMode: state => state.cookieMode,
    oneTrustSettings: state => state.oneTrustSettings,
    stores: state => state.stores,
    initialSiteLoad: state => state.initialSiteLoad,
    customer: state => state.customer,
    flatMenu: state => state.flatMenu,
    showGuard: state => state.showGuard,
    showDebugPanel: state => state.showDebugPanel,
    showTimeTweaker: state => state.showTimeTweaker,
    showLoginStatus: state => state.showLoginStatus,
    oopsDialog: state => state.oopsDialog,
    errorDialog: state => state.errorDialog,
    oopsMessage: state => state.oopsMessage,
    versionInfo: state => state.versionInfo,
    appMinsToAddToNow: state => state.appMinsToAddToNow,
    justRegistered: state => state.justRegistered,
    ready: state => state.ready
};

function calculateNow(state) {
    let now = new Date();

    if (state.showTimeTweaker && state.appMinsToAddToNow > 0) {
        now.setMinutes(now.getMinutes() + state.appMinsToAddToNow);
    }

    let result = {};
    // payload is a JS date
    result.jsDate = now;
    result.date = now.toISOString().substring(0,10);
    result.time = now.toISOString().substring(11,16);
    result.timeInt = now.getHours() * 100 + now.getMinutes();

    // now the 28 hour clock, 2400 = midnight, 2500 = 1am, 2800 = 4am

    result.date28 = result.date;
    result.time28 = result.time;
    result.time28Int = result.timeInt;

    if (now.getHours() <= 3) {
        now.setMilliseconds(-1000 * 60 * 60 * 24); // 1 day back
        result.date28 = now.toISOString().substring(0,10);
        result.time28Int = result.timeInt + 2400;
    }

    return result;
}

function saveInLocalStorage(key, value) {

    if (state.cookiePolicy > 0) {
        localStorage.setItem(key, value);
    }

}

// mutations
const mutations = {
    now(state) {
        state.now = calculateNow(state);
    },
    permToken(state, payload) {
        state.permToken = payload;
        saveInLocalStorage(LS_PERM_TOKEN, JSON.stringify(payload));
    },
    showDebugPanel(state, payload) {
        state.showDebugPanel = payload;
        saveInLocalStorage(LS_SHOW_DEBUG_PANEL, JSON.stringify(payload));
    },
    showGuard(state, payload) {
        state.showGuard = payload;
        saveInLocalStorage(LS_SHOW_GUARD, JSON.stringify(payload));
    },
    showTimeTweaker(state, payload) {
        state.showTimeTweaker = payload;
        saveInLocalStorage(LS_TIME_TWEAKER, JSON.stringify(payload));
    },
    showLoginStatus(state, payload) {
        state.showLoginStatus = payload;
        saveInLocalStorage(LS_SHOW_LOGIN, JSON.stringify(payload));
    },
    links(state, payload) {
        state.links = payload;
        saveInLocalStorage(LS_LINKS, JSON.stringify(payload));
    },
    cookies(state, payload) {
        state.cookies = payload;
        saveInLocalStorage(LS_COOKIES, JSON.stringify(payload));
        state.cookiePolicy = CookiePolicy.checkCookiePolicyAck(state.cookies);
    },
    cookieMode(state, payload){
        state.cookieMode = payload;
        saveInLocalStorage(LS_COOKIE_MODE, JSON.stringify(payload));
    },
    oneTrustSettings(state, payload){
        state.oneTrustSettings = payload;
        saveInLocalStorage(LS_ONE_TRUST_SETTINGS, JSON.stringify(payload));
    },
    appMinsToAddToNow(state, payload) {
        state.appMinsToAddToNow = payload;
        saveInLocalStorage(LS_APP_MINS_TO_ADD_TO_NOW, JSON.stringify(payload));
        state.now = calculateNow(state);
    },
    cookiePolicy(state, payload) {
        state.cookiePolicy = payload;
        saveInLocalStorage(LS_COOKIE_POLICY, JSON.stringify(payload));
        if (payload) {
            CookiePolicy.setCookiePolicyAck(state.cookies, payload);
        } else {
            CookiePolicy.clearCookiePolicyAck(state.cookies, 0);
        }
    },
    stores(state, payload) {
        state.stores = payload;
        saveInLocalStorage(LS_STORE_LIST, JSON.stringify(payload));

    },
    customer(state, payload) {
        state.customer = payload;
    },
    flatMenu(state, payload) {
        state.flatMenu = payload;
        saveInLocalStorage(LS_FLAT_MENU, JSON.stringify(payload));
    },
    initialSiteLoad: (state, payload) => state.initialSiteLoad = payload,
    errorDialog: (state, payload) => state.errorDialog = payload,
    versions: (state, payload) => state.versions = payload,
    oopsDialog: (state, payload) => state.oopsDialog = payload,
    oopsMessage: (state, payload) => state.oopsMessage = payload,
    versionInfo: (state, payload) => state.versionInfo = payload,
    justRegistered: (state, payload) => state.justRegistered = payload,
    ready: (state, payload) => state.ready = payload,
};

// actions
const actions = {
    showErrorDialog({commit}, payload) {
        commit('errorDialog', { show: true, status: payload.status, message: payload.message, okFunction: payload.okFunction});
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

const state = {
    appLike: false,
}

// getters
const getters = {
    appLike: state =>  state.appLike
}

// mutations
const mutations = {
    appLike(state, payload) {
        state.appLike = payload;
    }
}

// actions
const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

import VueRouter from 'vue-router'

// import Home from './pages/Home'
import StartOrder from './components/Inbound/StartOrder';
import GetLocation from './components/Inbound/GetLocation';
import GetOrderType from './components/Inbound/GetOrderType';
import StoreResults from './pages/Inbound/StoreResults';
import FulfilmentTime from './pages/Inbound/FulfilmentTime';

import PageStartOrder from "./pages/Inbound/PageStartOrder";
import PageWaitLoading from "./pages/Inbound/PageWaitLoading";
import store from './store/index';
import OrderAPI from "./api/APIOrder";


const routes = [
    {path: '/', component: PageWaitLoading},
    {path: '/order-online', redirect: {name: 'start-order'}},

    {
        path: '/start-order', meta: {stage: OrderAPI.Constants.OrderProgress.INBOUND}, component: PageStartOrder,
        children: [
            {
                path: '',
                name: 'start-order',
                meta: {stage: OrderAPI.Constants.OrderProgress.INBOUND},
                component: StartOrder
            },
            {
                path: 'location',
                name: 'start-order-location',
                meta: {stage: OrderAPI.Constants.OrderProgress.INBOUND},
                component: GetLocation
            },
            {
                path: 'type',
                name: 'start-order-type',
                meta: {stage: OrderAPI.Constants.OrderProgress.INBOUND},
                component: GetOrderType
            },
        ]
    },

    {
        name: 'table-service',
        path: '/table-service/:storeId',
        meta: {stage: OrderAPI.Constants.OrderProgress.INBOUND},
        component: () => import('./pages/Inbound/TableService')
    },
    {
        name: 'table-service-change',
        path: '/table-service-change/:storeId',
        meta: {stage: OrderAPI.Constants.OrderProgress.INBOUND},
        component: () => import('./pages/Inbound/TableService')
    },

    {
        path: '/quick-start/:storeId/:orderType',
        meta: {stage: OrderAPI.Constants.OrderProgress.INBOUND},
        component: () => import('./pages/Inbound/QuickStart')
    },

    {path: '/store-list', meta: {stage: OrderAPI.Constants.OrderProgress.INBOUND}, component: StoreResults},
    {
        path: '/fulfilment-time',
        name: 'fulfilment-time',
        meta: {stage: OrderAPI.Constants.OrderProgress.INBOUND},
        component: FulfilmentTime
    },

    {
        path: '/checkout',
        meta: {stage: OrderAPI.Constants.OrderProgress.CHECKOUT},
        component: () => import('./pages/Checkout/Checkout'),
        children: [
            {
                path: '',
                name: 'checkout',
                meta: {stage: OrderAPI.Constants.OrderProgress.CHECKOUT},
                component: () => import('./components/Checkout/Guest')
            },
            {
                path: 'login',
                name: 'checkout-login',
                meta: {stage: OrderAPI.Constants.OrderProgress.CHECKOUT},
                component: () => import('./components/Checkout/OfferLogin')
            },
            {
                path: 'curbside',
                name: 'checkout-curbside',
                meta: {stage: OrderAPI.Constants.OrderProgress.CHECKOUT},
                component: () => import('./components/Checkout/Curbside')
            },
            {
                path: 'contact-store',
                name: 'checkout-contact-store',
                meta: {stage: OrderAPI.Constants.OrderProgress.CHECKOUT},
                component: () => import('./components/Checkout/ContactStore')
            },
            {
                path: 'payment-success',
                name: 'checkout-payment-success',
                meta: {stage: OrderAPI.Constants.OrderProgress.COMPLETING},
                component: () => import('./components/Checkout/PaymentSuccess')
            },
            {
                path: 'payment-fail',
                name: 'checkout-payment-fail',
                meta: {stage: OrderAPI.Constants.OrderProgress.COMPLETING},
                component: () => import('./components/Checkout/PaymentFail')
            },
            {
                path: 'payment-inflight',
                name: 'payment-inflight',
                meta: {stage: OrderAPI.Constants.OrderProgress.PAYMENT},
                component: () => import('./components/Checkout/PaymentInflight')
            }
        ]
    },

    {
        path: '/order-complete', name: 'order-complete',
        meta: {stage: OrderAPI.Constants.OrderProgress.COMPLETE},
        component: () => import('./pages/OrderCompletePage')
    },


    {path: '/account', name: 'account', component: () => import('./pages/Account/YourAccount')},
    {
        path: '/account/previous-orders',
        name: 'account-previous-orders',
        component: () => import('./pages/Account/YourPreviousOrders')
    },
    {path: '/account/personal-details', component: () => import('./pages/Account/PersonalDetails')},
    {path: '/account/address/:id', component: () => import('./pages/Account/EditAddress')},
    {path: '/account/vehicle/:id', component: () => import('./pages/Account/EditVehicle')},
    {path: '/account/order/:id', name: 'order-view', component: () => import('./pages/Account/OrderView')},
    {
        path: '/account/previous-order/:id',
        name: 'previous-order-view',
        component: () => import('./pages/Account/OrderView')
    },
    {
        path: '/account/forgot-password',
        name: 'forgot-password',
        component: () => import('./pages/Account/ForgottenPassword')
    },
    {
        path: '/account/recover-password/:token',
        name: 'recover-password',
        component: () => import('./pages/Account/RecoverPassword')
    },
    {path: '/account/register', name: 'register', component: () => import('./pages/Account/Register')},

    {
        path: '/order', meta: {stage: OrderAPI.Constants.OrderProgress.MENU},
        name: 'order', component: () => import('./pages/Ordering/Order')
    },
    {
        path: '/order/review', meta: {stage: OrderAPI.Constants.OrderProgress.MENU},
        name: 'review-order', component: () => import('./pages/Ordering/ReviewOrder')
    },

    {path: '/debug/matt-roxburgh', component: () => import('./pages/Debug/MattRoxburgh')},
    {path: '/debug/session', name: 'debug-session', component: () => import('./pages/Debug/Session')},

    {path: '/handoff', name: 'handoff', component: () => import('./pages/Checkout/Handoff')},
    {path: '/wip', name: 'wip', component: () => import('./pages/WIP')},
    {path: '/404', name: '404', component: () => import('./pages/NotFound')},
    {path: '*', redirect: '/404'},
];

const router = new VueRouter({
    routes: routes,
    // eslint-disable-next-line no-unused-vars
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0}
    }
});

function getProgress(route) {
    if (typeof route.meta === 'undefined') return null;
    if (typeof route.meta.stage === 'undefined') return null;

    return route.meta.stage;
}

router.beforeEach((to, from, next) => {

    // let fromProgress = getProgress(from);
    let toProgress = getProgress(to);
    let isChecked = store.getters['session/isChecked'];
    let isPaid = store.getters['session/isPaid'];
    let isComplete = store.getters['session/isComplete'];
    let isFailed = store.getters['session/isFailed'];

    let fromProgress = store.getters['session/orderProgress'];

    store.commit('app/initialSiteLoad', (from.name === null));

    let guardRouteReplacement = null;

    if (isComplete || isFailed) {
        // Complete or Failed orders can only go to COMPLETE, INBOUND or ACCOUNT page
        switch (toProgress) {
            case OrderAPI.Constants.OrderProgress.COMPLETE:
            case OrderAPI.Constants.OrderProgress.INBOUND:
            case null:
                break;
            default:
                guardRouteReplacement = 'order-complete';
                break;
        }
    }

    if (isPaid && !isComplete) {
        // Paid orders can only go to COMPLETE
        switch (toProgress) {
            case OrderAPI.Constants.OrderProgress.COMPLETE:
                break;
            default:
                guardRouteReplacement = 'order-complete';
                break;
        }
    }

    if (fromProgress === OrderAPI.Constants.OrderProgress.PAYMENT && to.name !== 'payment-inflight') {
        next({name: 'payment-inflight'});
        return;
    }

    if (!isChecked) {
        // unchecked pages can only go to checkout, menu or inbound and account
        switch (toProgress) {
            case OrderAPI.Constants.OrderProgress.INBOUND:
            case OrderAPI.Constants.OrderProgress.MENU:
            case OrderAPI.Constants.OrderProgress.CHECKOUT:
            case null:
                break;
            default:
                if (OrderAPI.isInboundComplete(store)) {
                    guardRouteReplacement = 'order';
                } else {
                    guardRouteReplacement = 'start-order';
                }
                break;
        }
    }

    // guard route invoked
    if (guardRouteReplacement !== null) {
        next({name: guardRouteReplacement});
        return;
    }

    // pressed back to check order screen or refresh
    if (to.name === 'checkout-contact-store' && from.name === null) {
        if (OrderAPI.isNothingToAskForAtCheckout(store)) {
            next({name: 'order'});
        } else {
            next({name: 'checkout'});
        }
        return;
    }

    store.commit('session/orderProgress', toProgress);
    next();
})
;

export {
    router
}

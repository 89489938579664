<template>
    <v-row dense class="pb-1">
        <v-col cols="12" md="6" v-for="(option, optionIdx) in filteredModifierGroupItems" class="align-center" :key="optionIdx">
            <div class="d-flex align-center mg-option"
                 v-ripple="{ class: `nolo-mid-grey--text` }"
                 :class="!option.available || (filledUp && !isSelected(optionIdx)) ? 'modifier-disabled' : ''"
                 @click="toggle(optionIdx, option.available)">

                <label class="grow nolo-p2 nolo-dark-grey--text">
                    <modifier-icon :icon="option.code" class="mr-1"/>
                    {{ option.name[language] }}
                <shop-quantity v-if="allowMultiples && isSelected(optionIdx)" :disabledInc="filledUp"
                               cancel-on-zero dense v-model="quantities[optionIdx]" @cancel="unselect(optionIdx)"/>
                <span v-if="getPriceFromPriceObj(option.price, currency, null)">+{{ priceFormat(option.price)}}</span>
                </label>
                <v-checkbox
                    @click.stop
                    :disabled="!option.available || (filledUp && !isSelected(optionIdx))"
                    :value="optionIdx"
                    v-model="selected">
                </v-checkbox>
            </div>
          <div>
            <shop-product-info :modifier="true" :productInfo="option.productInfo[language]"/>
          </div>
            <v-divider class="mr-1 mt-2 hr-end" ></v-divider>
        </v-col>
    </v-row>
</template>

<script>
    import ShopCurrency from "../../mixins/ShopCurrency";
    import ShopQuantity from "./ShopQuantity";
    import ModifierIcon from "../UI/ModifierIcon";
    import ShopMixin from "../../mixins/ShopMixin";
    import BreakfastMixin from "../../mixins/BreakfastMixin";
    import ShopProductInfo from "@/components/Shop/ShopProductInfo";

    export default {
        name: "ShopMgCheckbox",
        components: {ShopProductInfo, ModifierIcon, ShopQuantity},
        data() {
            return {
                selected: [],
                quantities: []
            }
        },
        mixins: [ShopCurrency, ShopMixin, BreakfastMixin],
        mounted() {
        },
        props: {
            modifierGroup: {type: Object, required: true},
            allowMultiples: {type: Boolean, default: false},
            value: {type: Number, default: 0},
            max: {type: Number, default: 99},
            min: {type: Number, default: 0},
            language: {type: String, required: true},
            currency: {type: String, required: true},
            preConfig: {type: Array, default: null }
        },
        watch: {
            totals: {
                immediate: true,
                handler: function () {
                    this.checkValid();
                }
            },
            price(newVal) {
                this.$emit('new-price', newVal);
            },
            preConfig: {
                immediate: true,
                handler(newValue) {
                    if (newValue === null) {
                        return;
                    }
                    this.selected = newValue;
                    this.$emit('clear-pre-config');
                }
            }
        },
        methods: {
            toggle(value, available) {
                if (!available) return;
                if (this.filledUp && !this.isSelected(value)) return;
                let selIdx = this.selected.indexOf(value);
                if (selIdx < 0) {
                    this.selected.push(value);
                } else {
                    this.selected.splice(selIdx, 1);
                }
            },
            toppingIcon(name) {
                let cl = name.toLowerCase().replace(/ /g, '-');
                if (cl.indexOf(':') >= 0) {
                    name = name.substring(cl.indexOf(':') + 1);
                }
                name = name.replace('regular', '');
                return 'topping-icon-' + name;
            },
            isSelected(idx) {
                return this.selected.reduce((carry, curr) => carry || (curr === idx), false);
            },
            unselect(idx) {
                this.selected = this.selected.filter(el => el !== idx);
            },
            checkValid() {
                let valid = (this.min <= this.totals && this.max >= this.totals);
                let modifiers = [];
                for (let i in this.selected) {
                    let optIdx = this.selected[i];
                    let qty = typeof this.quantities[optIdx] === 'undefined' ? 1 : this.quantities[optIdx];
                    modifiers.push({
                        quantity: qty,
                        id: this.modifierGroup.items[optIdx].id,
                        code: this.modifierGroup.items[optIdx].code,
                        name: this.modifierGroup.items[optIdx].name,
                        unitPrice: this.modifierGroup.items[optIdx].price,
                    });
                }

                this.$emit('change',
                    {
                        id: this.modifierGroup.id,
                        groupCode: this.modifierGroup.code,
                        groupName: this.modifierGroup.name,
                        modifiers: modifiers,
                        valid: valid
                    }
                );
            },
        },
        computed: {
            filteredModifierGroupItems() {
                // EGG BOUND! This removes egg if after 10am on fulfilment time
                if (this.modifierGroup.code === 'extrasmodifications') {
                    if (this.breakfastAllowed) {
                        return this.modifierGroup.items;
                    } else {
                        return this.modifierGroup.items.filter(el => { return el.code !== 'add-egg'});
                    }
                }

                return this.modifierGroup.items;
            },
            totals() {
                let total = 0;
                for (let i in this.selected) {
                    let optIdx = this.selected[i];
                    let qty = typeof this.quantities[optIdx] === 'undefined' ? 1 : this.quantities[optIdx];
                    total += qty;
                }
                return total;
            },
            price() {
                let price = 0;
                for (let i in this.selected) {
                    let optIdx = this.selected[i];
                    let qty = typeof this.quantities[optIdx] === 'undefined' ? 1 : this.quantities[optIdx];
                    price += this.getPriceFromPriceObj(this.modifierGroup.items[optIdx].price, this.currency, 0) * qty;
                }
                return price;
            },
            filledUp() {
                return this.totals >= this.max;
            }
        }

    }
</script>

<style scoped>

.shop-item-configure .align-center.col-md-6.col-12 {
    display: grid;
}
.mg-option {
    height: 42px;
    min-width: 0;
}
.mg-option label {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
}
.mg-option label:not(.disabled) {
    cursor: pointer;
}
.mg-option label.disabled {
    color: #9e9e9e !important;
    background-color: var(--v-nolo-light-grey-base) !important;
}
    .modifier-disabled {
        opacity: 0.6;
        filter: grayscale(1);
    }

.hr-end {
  align-self: end;
}
</style>

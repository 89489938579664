<template>
    <v-dialog :value="value" @cancel="cancel" max-width="480">
        <v-card>
            <v-card-text class="text-center pt-6">
                <v-icon size="128" :color="error.status + ' darken-1'">mdi-alert-circle-outline</v-icon>
                <div class="nolo-subtitle text-center py-10">{{ error.message }}</div>
                <v-btn color="secondary" outlined @click="cancel">OK</v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: 'error-dialog',
        props: {
            value: {type: Boolean, default: false },
            error: {type: Object, required: true }
        },
        methods: {
            cancel() {
                this.$emit('input', false);
                if (typeof this.error.okFunction === 'function') {
                    this.error.okFunction();
                }
            }
        }
    }
</script>

<template>
    <v-radio-group v-model="localValue" class="my-0" @mouseup.native="rgDispatch">
        <v-row dense class="align-center">
            <v-col cols="12" md="6" v-for="(option, optionIdx) in modifierGroup.items"
                         :key="option.id" class="align-center">
                <div class="d-flex align-center mg-option"
                     v-ripple="{ class: `nolo-mid-grey--text` }">
                    <v-radio class="pt-1" style="order: 1;" :ref="'radio' + optionIdx"
                             @xclick="handleRadioClick(optionIdx)"
                             :value="optionIdx">
                    </v-radio>
                    <label class="grow nolo-dark-grey--text nolo-p2"
                           @click="localValue = localValue === optionIdx ? null : optionIdx"
                    >
                        <modifier-icon :icon="option.code" class="mr-1"/>
                        {{option.name[language] }}
                        <span v-if="getPriceFromPriceObj(option.price, currency, null)">+{{ priceFormat(option.price)}}</span>
                    </label>
                </div>
              <shop-product-info :modifier="true" :productInfo="option.productInfo[language]"></shop-product-info>
                <v-divider class="mr-1 mt-2"></v-divider>
            </v-col>
        </v-row>
    </v-radio-group>
</template>

<script>
    import ShopCurrency from "../../mixins/ShopCurrency";
    import ModifierIcon from "../UI/ModifierIcon";
    import ShopProductInfo from "@/components/Shop/ShopProductInfo";

    export default {
        name: "ShopMgRadio",
        components: {ShopProductInfo, ModifierIcon},
        data() {
            return {
                localValue: null,
                oldValue: null
            }
        },
        mixins: [ShopCurrency],
        mounted() {
            this.checkValid();
        },
        props: {
            modifierGroup: {type: Object, required: true},
            max: {type: Number, default: 99},
            min: {type: Number, default: 0},
            language: {type: String, required: true},
            currency: {type: String, required: true},
            preConfig: {type: Array, default: null }
        },
        watch: {
            preConfig: {
                immediate: true,
                handler(newValue) {
                    if (newValue === null) {
                        return;
                    }
                    this.localValue = newValue.length === 0 ? null : newValue[0];
                    this.oldValue = this.localValue
                    this.$emit('clear-pre-config');
                    this.changedPrice();
                }
            }
        },
        methods: {
            rgDispatch() {
                window.setTimeout(this.rgClicked, 100);
            },
            rgClicked() {
                if (this.oldValue === this.localValue) this.localValue = null;
                this.oldValue = this.localValue;
                this.changedPrice();
            },
            toppingIcon(name) {
                let cl = name.toLowerCase().replace(/ /g, '-');
                if (cl.indexOf(':') >= 0) {
                    name = name.substring(cl.indexOf(':') + 1);
                }
                name = name.replace('regular', '');
                return 'topping-icon-' + name;
            },
            changedPrice() {
                let price = this.localValue === null ? 0 : this.getPriceFromPriceObj(this.modifierGroup.items[this.localValue].price, this.currency);
                this.$emit('new-price', price);
                this.checkValid();
            },
            checkValid() {
                let valid = (this.min === 0) || (this.min !== 0 && this.localValue !== null);

                let modifiers = [];
                if (this.localValue !== null) {
                    modifiers.push({
                        quantity: 1,
                        id: this.modifierGroup.items[this.localValue].id,
                        code: this.modifierGroup.items[this.localValue].code,
                        name: this.modifierGroup.items[this.localValue].name,
                        unitPrice: this.modifierGroup.items[this.localValue].price,
                    });
                }
                this.$emit('change',
                    {
                        id: this.modifierGroup.id,
                        groupCode: this.modifierGroup.code,
                        groupName: this.modifierGroup.name,
                        modifiers: modifiers,
                        valid: valid
                    }
                );
            }
        }
    }
</script>

<style scoped>

.shop-item-configure .align-center.col-md-6.col-12 {
    display: grid;
}
.mg-option {
    height: 42px;
    min-width: 0;
}
.mg-option label {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
}
.mg-option label:not(.disabled) {
    cursor: pointer;
}
.mg-option label.disabled {
    color: #9e9e9e !important;
    background-color: var(--v-nolo-light-grey-base) !important;
}

</style>

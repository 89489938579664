<template>
    <v-container class="mb-16">
        <div class="sticky-tabs">
            <v-tabs v-model="tabNo" height="56" background-color="transparent" hide-slider show-arrows center-active class="fixed-tabs-bar px-sm-4 px-md-0 px-lg-8" active-class="active-fixed-tab">
                <v-tab :key="'category-' + category.index" @click="gotoCategory(category.index)"
                       v-for="category in filteredCategories">{{ category.name[language]}}
                </v-tab>
            </v-tabs>
        </div>

        <shop-category :language="language" :currency="currency"
                       @selected-item="selectItem"
                       @scrolled-to="scrolledTo"
                       :categoryNo="category.index" :flat-menu="flatMenu"
                       :basket-quantities="basketQuantities"
                       :key="category.index"
                       v-for="category in filteredCategories"/>

    </v-container>
</template>

<script>

    import ShopCategory from './ShopCategory';
    import BreakfastMixin from "../../mixins/BreakfastMixin";
    import Gtag from "../../mixins/Gtag";
    import Gtm from "../../mixins/Gtm";

    export default {
        name: "ShopMenu",
        components: {
            ShopCategory,
        },
        data() {
            return {
                reenableScrolledToEventTimer: null,
                ignoreScrolledToEvents: false,
                tabNo: 0,
                psStyle: ''
            }
        },
        mixins: [BreakfastMixin, Gtag, Gtm],
        mounted() {
            this.gtmViewItemListEvent(this.flatMenu.salesItems, this.flatMenu.categories);
        },
        beforeDestroy() {
            if (this.reenableScrolledToEventTimer) {
                window.clearTimeout(this.reenableScrolledToEventTimer);
            }
        },
        methods: {
            enableObserver() {
                this.ignoreScrolledToEvents = false;
                this.reenableScrolledToEventTimer = null;
            },
            gotoCategory(categoryNo) {
                this.ignoreScrolledToEvents = true;
                this.$vuetify.goTo('.category-' + categoryNo, { duration: 480 });
                this.reenableScrolledToEventTimer = window.setTimeout(this.enableObserver, 480);
                //this.gtmViewItemListEvent(this.flatMenu.salesItems, this.flatMenu.categories[categoryNo]);
            },
            scrolledTo(e) {
                if (this.ignoreScrolledToEvents) return;
                //this.gtmViewItemListEvent(this.flatMenu.salesItems, this.flatMenu.categories[parseInt(e)]);
                this.tabNo = parseInt(e);
            },
            selectItem(item) {
                this.$emit('selected-item', item);
            },
        },
        props: {
            flatMenu: {type: Object, required: true},
            language: {type: String, required: true},
            currency: {type: String, required: true},
            basketQuantities: {
                type: Object, default: () => {
                }
            }
        },
        computed: {
            indexedCategories() {
                return this.flatMenu.categories.map((el, idx) => { return {...el, index: idx} });
            },
            filteredCategories() {
                if (this.breakfastAllowed) {
                    return this.indexedCategories;
                } else {
                    return this.indexedCategories.filter(el => el.code !== 'breakfast');
                }
            }
        }
    }
</script>

<style>

.shop-item-configure {
    max-height: calc(var(--vh, 1vh) * 90 - 84px);
}

@media (max-width: 799px) {
    .v-dialog {
        margin: 12px;
    }

    .v-dialog:not(.v-dialog--fullscreen) {
        max-height: calc(var(--vh, 1vh) * 97);
    }

    .shop-item-configure {
        max-height: calc(var(--vh, 1vh) * 97 - 152px);
    }
}

.background-contrasting.theme--dark {
    background-color: #181818 !important;
}

.background-contrasting.theme--light {
    background-color: #e8e8e8 !important;
}

.shop-mod-price {
    width: 80px;
    max-width: 80px;
}

.sticky-tabs {
    position: sticky;
    top: 60px;
    margin: 0 -23px;
    z-index: 6;
    background: var(--v-nolo-light-grey-base) !important;
}

.sticky-tabs .v-item-group {
    height: 52px !important;
}

@media (min-width: 600px) {
    .sticky-tabs {
        margin: 0 -16px;
    }
}

@media (min-width: 800px) {
    .sticky-tabs {
        top: 80px;
        margin: 0 -30px;
    }

    .sticky-tabs .v-item-group {
        height: 56px !important;
    }
}

@media (min-width: 1200px) {
    .sticky-tabs {
        margin: 2px -20px 0 -30px;
    }
}

.sticky-tabs .v-slide-group__next, .sticky-tabs .v-slide-group__prev {
    flex: 0 1 32px;
    min-width: 32px;
}

.sticky-tabs .v-tab {
    margin: 8px 6px 0 0;
    padding: 8px 12px;
    height: 36px;
    min-width: initial;
    border-radius: 6px;
    font-size: 14px;
    color: var(--v-primary-base) !important;
    letter-spacing: normal !important;
    text-transform: initial;
}

@media (min-width: 800px) {
    .sticky-tabs .v-tab {
        padding: 8px 12px;
        height: 40px;
    }
}

@media (min-width: 1200px) {
    .sticky-tabs .v-tab {
        font-size: 16px;
    }
}

.sticky-tabs .v-tab::before {
    border-radius: 6px;
}

.sticky-tabs .v-tab.active-fixed-tab {
    font-weight: bold;
    background: var(--v-primary-base);
    color: white !important;
}

.sticky-tabs .v-tab.active-fixed-tab:hover {
    background-color: var(--v-nolo-dark-red-base);
}

.sticky-tabs .v-tab.active-fixed-tab:hover::before {
    opacity: 0 !important;
}

</style>

<style scoped>

</style>


function isInNoquApp() {
    return navigator.userAgent.indexOf('noquapp/') >= 0;
}

function isInNoquAndroidApp() {
    return navigator.userAgent.indexOf('noquapp-android') >= 0;
}

function openExternalUrl(vue, url) {
        return new Promise(function (resolve, reject) {
            // Only Android has this problem.
            if (isInNoquAndroidApp()) {
                vue.$http(
                    {
                        method: 'GET',
                        url: '/__noquapp__/externalurl?url=' + encodeURI(url),
                    })
                    .then(function () {
                        resolve();
                    })
                    .catch(function (error) {
                        reject(error);
                    })
            } else {
                resolve();
            }
        });
}

function swipeToRefreshEnabled(vue, enable) {

    // Only Android has this problem.
    if (!isInNoquAndroidApp()) return;

    return new Promise(function (resolve, reject) {
        if (isInNoquApp()) {
            vue.$http(
                {
                    method: 'GET',
                    url: '/__noquapp__/swipetorefresh/' + (enable ? '1' : '0'),
                })
                .then(function () {
                    resolve();
                })
                .catch(function (error) {
                    reject(error);
                })
        } else {
            resolve();
        }
    });
}

export default {
    isInNoquApp: isInNoquApp,
    swipeToRefreshEnabled: swipeToRefreshEnabled,
    openExternalUrl: openExternalUrl,
    isInNoquAndroidApp: isInNoquAndroidApp
}

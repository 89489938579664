<template>

    <v-container class="pa-0">
        <v-card flat tile class="basket">
            <v-row>
                <v-col cols="12">
                    <v-row v-if="localBasket.length === 0">
                        <v-col cols="12" class="pt-2 pb-3">
                            <div class="nolo-p2 nolo-dark-grey--text text-center">{{ $t('message.emptyBasket') }}</div>
                        </v-col>
                    </v-row>
                    <v-row dense v-else>
                        <v-col cols="12" v-for="(item,itemIdx) in localBasket" :key="'b-' + itemIdx">
                            <v-row no-gutters class="px-4 nolo-p3 black--text">
                                <v-col cols="1" class="font-weight-bold">{{localBasket[itemIdx].quantity}}x</v-col>
                                <v-col cols="7" class="font-weight-bold">{{item.name[language]}}</v-col>
                                <v-col cols="4" class="text-right text-no-wrap">
                                    {{ currencyFormat(lineTotal(item))}}
                                </v-col>
                                <v-col cols="10" v-if="item.modifiers">
                                    <div v-for="(modGrp, modGrpIdx) in item.modifiers" :key="modGrpIdx">
                                        <span class="modifierItem" v-for="(mod, modIdx) in modGrp.modifiers" :key="modIdx">{{ mod.quantity === 1 ? '' : mod.quantity + 'x' }}{{mod.name[language] }}</span>
                                    </div>
                                    <div v-if="isPlain(item)">{{ $t('plain') }}</div>
                                </v-col>
                            </v-row>
                            <v-row dense v-if="itemIdx !== localBasket.length - 1">
                                <v-col cols="12">
                                    <v-divider class="my-2 dashed"></v-divider>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" v-for="(item,itemIdx) in orderedUpsells" :key="'u-' + itemIdx">
                            <v-row dense>
                                <v-col cols="12">
                                    <v-divider class="my-2 dashed"></v-divider>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="px-4 nolo-p3 black--text">
                                <v-col cols="1" class="font-weight-bold">{{item.quantity}}x</v-col>
                                <v-col cols="7" class="font-weight-bold">{{item.name}}</v-col>
                                <v-col cols="4" class="text-right text-no-wrap">
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
            <v-row v-if="!readonly">
                <v-col cols="12" class="pt-3 pb-1">
                    <v-divider></v-divider>
                </v-col>
                <v-col cols="12" class="px-6">
                    <v-btn block @click="editBasket" x-large color="secondary" outlined
                           class="elevation-0 ">
                        {{ $t('btn.editBasket') }}
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>
    </v-container>

</template>

<script>
    import ShopCurrency from "../../mixins/ShopCurrency";

    export default {
        name: "ShopBasketSummary",
        components: {
        },
        data() {
            return {
                localBasket: [],
            }
        },
        mixins: [ShopCurrency],
        watch: {
            basket: {
                immediate: true,
                handler: function (newValue) {
                    this.localBasket = newValue;
                }
            }
        },
        props: {
            basket: {type: Array, default: () => []},
            upsells: {type: Array, default: () => []},
            language: {type: String, required: true},
            currency: {type: String, required: true},
            readonly: {type: Boolean, default: false}
        },
        methods: {
            lineTotal(basketItem) {
                return basketItem.quantity * (basketItem.price + basketItem.modifierPrice);
            },
            editBasket() {
                this.$router.replace({name: 'order'})
            },
            isPlain(item) {
                for (let m in item.modifiers) {
                    if (item.modifiers[m].groupCode === 'toppings') {
                        return item.modifiers[m].modifiers.length === 0;
                    }
                }
                return false;
            }
        },
        computed: {
            orderedUpsells() {
                return this.upsells.filter(el => el.quantity > 0);
            },
            totalPrice() {
                let total = 0;
                for (let b in this.basket) {
                    total += this.lineTotal(this.basket[b]);
                }
                return total;
            },
            totalItems() {
                let total = 0;
                for (let b in this.basket) {
                    total += this.basket[b].quantity;
                }
                return total;
            },
        },
    }

</script>

<style scoped>

    @media (min-width: 800px) {
        .basket {
            width: 350px;
        }
    }

    .modifierItem + .modifierItem:before {
        content: ", ";
    }

</style>

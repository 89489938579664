function getDistanceFromLatLonInKm(loc1, loc2) {

    let lat1 = parseFloat(loc1.lat);
    let lon1 = parseFloat(loc1.lng);
    let lat2 = parseFloat(loc2.lat);
    let lon2 = parseFloat(loc2.lng);

    var R = 6371; // Radius of the earth in km
    var dLat = deg2rad(lat2-lat1);  // deg2rad below
    var dLon = deg2rad(lon2-lon1);
    var a =
        Math.sin(dLat/2) * Math.sin(dLat/2) +
        Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
        Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
     // Distance in km
    let distanceKm = R * c;

    if (isNaN(distanceKm)) return null;

    return distanceKm;
}

function deg2rad(deg) {
    return deg * (Math.PI/180)
}

export default {
    getDistanceFromLatLonInKm: getDistanceFromLatLonInKm
}

if (process.env.VUE_APP_INCLUDE_CATCHJS === 'true') {
  // Intercepting error handler and adding version number
  const catchJSErrorHandler = window.onerror;
  window.onerror = function (msg, url, lineNo, columnNo, error) {
      let newMsg = 'EUI' + process.env.VERSION + ': ' + msg;
      error.message = newMsg;
      console.log(error);

      return catchJSErrorHandler(newMsg, url, lineNo, columnNo, error);
  };

  // Suppress the GTAG Blocked error ...
  const catchJSConsoleError = console.error;
  console.error = function (msg) {
      if (typeof msg === 'string' && msg.indexOf('[vue-gtag] Ops!') === 0) {
          console.log(msg);
      } else {
          catchJSConsoleError(msg);
      }
  };
}

console.log(
  "Booting %cNOLO UI" + " from LineTen.com"
  + "%c" +  process.env.CODENAME.toUpperCase() + " V" + process.env.VERSION
  + "%c" + ' ',
  "color: white; background-color: #3897d8; padding: 4px 20px; border-radius: 10px 0 0 0; margin: 40px 0",
  "color: white; background-color: #2a3842; padding: 4px 20px;",
  "color: white; background-color: #F4D03F; padding: 4px 4px; border-radius: 0 0 10px 0 ");

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import './registerServiceWorker'
import VueRouter from "vue-router";
import Axios from 'axios'
import store from './store/index.js'
import './css/app.css';
import './css/typography.css';
import AppHeader from './components/Skeleton/AppHeader'
import AppFooter from './components/Skeleton/AppFooter'
import PageLayoutWithSideImage from "./components/Skeleton/PageLayoutWithSideImage";
import PageLayout from "./components/Skeleton/PageLayout";
import PageLayoutNolo from "./components/Skeleton/PageLayoutNolo";
import AppMixin from "./mixins/AppMixin";
import PortalVue from 'portal-vue'
import {VueReCaptcha} from "vue-recaptcha-v3";
import Wip from "./components/UI/Wip";
import ShopBasket from "./components/Shop/ShopBasket";
import ShopCategory from "./components/Shop/ShopCategory";
import ShopItem from "./components/Shop/ShopItem";
import ShopItemConfigure from "./components/Shop/ShopItemConfigure";
import ShopMenu from "./components/Shop/ShopMenu";
import ShopMgCheckbox from "./components/Shop/ShopMgCheckbox";
import ShopMgRadio from "./components/Shop/ShopMgRadio";
import ShopModifierGroup from "./components/Shop/ShopModifierGroup";
import ShopQuantity from "./components/Shop/ShopQuantity";
import ShopUpsells from "./components/Shop/ShopUpsells";
import ShopBasketSummary from "./components/Shop/ShopBasketSummary";
import CookiePolicy from './plugins/CookiePolicy'
import SmartBanner from "smart-app-banner";
import "smart-app-banner/dist/smart-app-banner.css";

CookiePolicy.cookieFix();

Vue.component('Wip', Wip);
Vue.component('ShopBasket', ShopBasket);
Vue.component('ShopBasketSummary', ShopBasketSummary);
Vue.component('ShopCategory', ShopCategory);
Vue.component('ShopItem', ShopItem);
Vue.component('ShopUpsells', ShopUpsells);
Vue.component('ShopItemConfigure', ShopItemConfigure);
Vue.component('ShopMenu', ShopMenu);
Vue.component('ShopMgCheckbox', ShopMgCheckbox);
Vue.component('ShopMgRadio', ShopMgRadio);
Vue.component('ShopModifierGroup', ShopModifierGroup);
Vue.component('ShopQuantity', ShopQuantity);

Vue.config.productionTip = false;

Vue.use(VueReCaptcha,
  {
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      loaderOptions: {
          autoHideBadge: true
      }
  })

Vue.use(PortalVue);
Vue.use(VueRouter);
Vue.component('app-header', AppHeader);
Vue.component('app-footer', AppFooter);
Vue.component('page-layout', PageLayout);
Vue.component('page-layout-with-side-image', PageLayoutWithSideImage);
Vue.component('page-layout-nolo', PageLayoutNolo);

Vue.mixin(AppMixin);

import {router} from './routes';

function getBaseUrl() {
  if (process.env.VUE_APP_HOST === 'auto') {
      if (!window.location.origin) {
          window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
      }
      return window.location.origin + window.location.pathname.replace('/index.html', '');
  } else {
      return process.env.VUE_APP_HOST;
  }
}

Vue.prototype.$baseUrl = getBaseUrl();
Vue.prototype.$http = Axios.create({
  baseURL: Vue.prototype.$baseUrl
});

import {i18n} from './i18n';
window.document.title = i18n.t('tabTitle');

// ANALYTICS: start analytics if required.

import VueGtag from 'vue-gtag';
import VueGtm from '@gtm-support/vue2-gtm';

if (typeof process.env.VUE_APP_GOOGLE_TAG_MANAGER !== 'undefined' && process.env.VUE_APP_GOOGLE_TAG_MANAGER !== '') {
    Vue.use(VueGtm, {
        id: process.env.VUE_APP_GOOGLE_TAG_MANAGER,
        vueRouter: router
    });
}

if (typeof process.env.VUE_APP_GOOGLE_ANALYTICS_GTAG !== 'undefined' && process.env.VUE_APP_GOOGLE_ANALYTICS_GTAG !== '') {
  Vue.use(VueGtag,
      {
          config: {id: process.env.VUE_APP_GOOGLE_ANALYTICS_GTAG},
          bootstrap: false
      },
      router);
}

new Vue({
  i18n,
  vuetify,
  store: store,
  router: router,
  created() {
    // here isAndroidApp localstorage value is set on index.html page 
    if(!window.localStorage.getItem('isAndroidApp')){ // this condition will execute if this is running on native android app only
      new SmartBanner({
        daysHidden: 15, // days to hide banner after close button is clicked (defaults to 15)
        daysReminder: 90, // days to hide banner after "OPEN" button is clicked (defaults to 90)
        title: "Five Guys: Order Ahead",
        author: "Five Guys JV Ltd",
        button: "OPEN",
        store: {
          // ios: "On the App Store",
          android: "On Google Play",
          // windows: "In Windows store",
        },
        price: {
          // ios: "GET",
          android: "GET",
          // windows: "FREE",
        },
        // , theme: '' // put platform type ('ios', 'android', etc.) here to force single theme on all device
        // , icon: '' // full path to icon image if not using website icon image
      });
    }
  },
  render: h => h(App)
}).$mount('#app');

const state = {
    target: null,
    direction: -1,
}

// getters
const getters = {
    target: state =>  state.target,
    direction: state =>  state.direction
}

// mutations
const mutations = {
    target(state, payload) {
        state.target = payload;
        state.direction = 1;
    },
    direction(state, payload) {
        state.direction = payload;
    }
}

// actions
const actions = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}

import {i18n} from '../i18n';
import {trim} from "leaflet/src/core/Util";

const MINIMUM_PASSWORD_LENGTH = 10;
const MINIMUM_NAME_LENGTH = 2;
const MAXIMUM_NAME_LENGTH = 32;

function $t(key, values) {
    return i18n.t(key, values);
}

function required(v) {
        return !!v || $t('error.required');
}

function requiredPassword(v) {
        return !!v || $t('error.passwordRequired');
}

function requiredPhoneNumber(v) {
        return !!v || $t('error.phoneNumberRequired');
}

function requiredEmail(v) {
        return !!v || $t('error.emailRequired');
}

function email(v) {
    return !v || /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,20})+$/.test(v) || $t('error.invalidEmail');
}

function phoneNumber(v) {
    return !v || /^(\+)?([0-9]){9,16}$/.test(v) || $t('error.invalidPhoneNumber');
}

function tableNumber(v) {
    return !v || (v.length <= 2 && parseInt(v) > 0 && parseInt(v) < 100) || $t('error.invalidTableNumber');
}

function validName(v) {
    if (v.length >= MINIMUM_NAME_LENGTH && v.length <= MAXIMUM_NAME_LENGTH) {
        return true;
    }

    return $t('error.nameRequired', {'min_length': MINIMUM_NAME_LENGTH, 'max_length': MAXIMUM_NAME_LENGTH});
}

function validPassword(v) {

    v = trim(v);
    //At least one uppercase character and one lowercase character and one digit
    //At most 128 chars
    if(!(/[A-Z]/.test(v)) || !(/[a-z]/.test(v)) || !(/[0-9]/.test(v)) || v.length > 128) {
       return $t('error.passwordDoesNotMeetRequirements');
    }
    //At least 10 chars
    return v.length >= 10 ? true : $t('error.passwordTooShort', {'length': MINIMUM_PASSWORD_LENGTH});
}


export default {
    required: required,
    email: email,
    phoneNumber: phoneNumber,
    validPassword: validPassword,
    validName: validName,
    requiredPassword: requiredPassword,
    requiredPhoneNumber: requiredPhoneNumber,
    requiredEmail: requiredEmail,
    tableNumber: tableNumber
}

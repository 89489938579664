<template>

    <v-container class="basketCase pa-0">
        <v-card flat tile class="wholeBasket pa-0">
            <v-row class="basketTop black--text">

                <!-- Where and when -->
                <v-col cols="12" v-if="review || (!brief)">
                    <v-row>
                        <v-col cols="12" class="px-6">
                            <div class="nolo-p2 font-weight-bold">{{ sessStoreName }}</div>
                            <div class="d-flex">
                                <div v-if="tableService">{{ sessOrderType }} #{{ tableNumber }}</div>
                                <div v-else>{{ sessOrderType }}
                                    <span v-if="sessFulfilmentTime !== null">{{ sessFulfilmentDate.desc }} {{ sessFulfilmentTime.desc }}</span>
                                </div>
                                <v-spacer></v-spacer>
                                <div class="nolo-body font-weight-bold"><a href="#" @click="goToFulfilment">{{ $t('btn.change') }}</a></div>
                            </div>
                        </v-col>
                        <v-col cols="12" class="py-0 mb-1">
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>
                </v-col>

                <!-- Desktop checkout button -->
                <v-col cols="12" class="px-6" v-if="!brief && !bottomCheckout">
                    <v-btn block @click="checkout" :disabled="checkoutDisabled" large color="primary" class="elevation-0">
                        {{ $t('btn.checkout', {price: currencyFormat(totalPrice)}) }}
                    </v-btn>
                    <v-alert v-if="checkoutDisabled && checkoutDisabledReason !== ''" dense class="mt-2 mb-0 mx-0 text-center" color="warning">{{ checkoutDisabledReason }}</v-alert>
                </v-col>

                <!-- Mobile checkout button -->
                <v-col cols="12" class="py-2 px-6" v-if="brief && totalPrice > 0">
                    <v-alert v-if="checkoutDisabled && checkoutDisabledReason !== ''" dense class="mt-1 mb-2 mx-0 text-center" color="warning">{{ checkoutDisabledReason }}</v-alert>
                    <v-btn block @click="checkout" x-large color="primary"
                           class="elevation-0 ">
                        {{ $t('btn.viewOrder') }}
                        <v-avatar color="nolo-dark-red white--text" :size="totalItems < 10 ? 24 : 28"
                                  class="font-weight-regular mini-checkout-items nolo-p3">{{ totalItems }}
                        </v-avatar>
                        <div class="font-weight-regular mini-checkout-price">{{ currencyFormat(totalPrice) }}</div>
                    </v-btn>
                </v-col>

            </v-row>

            <!-- Basket Content -->
            <v-row no-gutters class="basketContent black--text pt-3">
                <v-col cols="12" v-if="!brief">

                    <!-- Empty Basket -->
                    <v-row v-if="localBasket.length === 0">
                        <v-col cols="12" class="pa-4 pt-6">
                            <div class="nolo-p2 nolo-dark-grey--text text-center">{{ $t('message.emptyBasket') }}</div>
                        </v-col>
                        <v-col cols="12" class="pt-0 pb-4">
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>

                    <!-- Basket Items -->
                    <v-row class="basketItems" dense v-else>
                        <v-col cols="12" class="pt-2 pb-4" v-if="!review">
                            <v-divider></v-divider>
                        </v-col>
                        <v-col cols="12" v-for="(item,itemIdx) in localBasket" :key="itemIdx">
                            <v-row no-gutters class="px-4 nolo-p3 black--text">
                                <v-col cols="8" class="font-weight-bold">{{item.name[language]}}</v-col>
                                <v-col cols="4" class="text-right text-no-wrap">
                                    {{ currencyFormat(lineUnitTotal(item))}}
                                </v-col>
                                <v-col cols="10" v-if="item.modifiers">
                                    <div v-for="(modGrp, modGrpIdx) in item.modifiers" :key="modGrpIdx">
                                        <span class="modifierItem" v-for="(mod, modIdx) in modGrp.modifiers" :key="modIdx">{{ mod.quantity === 1 ? '' : mod.quantity + 'x' }}{{mod.name[language] }}</span>
                                    </div>
                                    <div v-if="isPlain(item)">{{ $t('plain') }}</div>
                                </v-col>
                                <v-col cols="12" class="py-3">
                                    <a @click="editItem(itemIdx)" href="javascript:void(0)" class="black--text pr-1">{{ $t('btn.editOptions') }}</a>
                                    <v-icon small>mdi-pencil-outline</v-icon>
                                </v-col>
                            </v-row>
                            <v-row no-gutters class="px-1">
                                <v-col cols="6">
                                    <shop-quantity v-model="localBasket[itemIdx].quantity" @change="basketChanged"
                                                   :itemIdx="itemIdx" :min="1" :large="bigButtons" smallText @cancel="deleteItem(itemIdx)"
                                                   @gtmChangeEvent="gtmQuantityChangeEvent"/>
                                </v-col>
                                <v-col cols="4"></v-col>
                                <v-col cols="2" class="d-flex align-center pl-1">
                                    <v-btn icon>
                                        <v-icon color="primary" @click="deleteItem(itemIdx)">mdi-delete-outline</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <v-row dense v-if="itemIdx !== localBasket.length - 1">
                                <v-col cols="12">
                                    <v-divider class="my-2 dashed"></v-divider>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="12" class="py-2">
                            <v-divider></v-divider>
                        </v-col>
                    </v-row>

                    <!-- Upsells -->
                    <v-row no-gutters>
                        <v-col cols="12">
                            <shop-upsells :upsells="upsellBasket" :big-buttons="bigButtons" @change="upsellsChanged"/>
                        </v-col>
                    </v-row>

                    <v-row no-gutters class="pl-4 pr-1 pr-sm-4 text-no-select">
                        <v-col class="shrink mr-3 py-1">
                            <v-icon large>mdi-alert-circle-outline</v-icon>
                        </v-col>
                        <v-col class="nolo-p3 py-2 black--text">
                            <i18n tag="div" path="message.allergensDisclaimer">
                                <a target="_blank" :href="'tel:' + storeTelephone" class="font-weight-bold">{{ storeTelephone }}</a>
                                <a target="_blank" :href="$t('url.allergens', {domain: cmsDomain})" class="font-weight-bold">{{ $t('link.allergenInfo') }}</a>
                                <div><br/>{{ $t('message.kcal') }}</div>
                            </i18n>
                        </v-col>
                    </v-row>

                </v-col>

                <!-- Review checkout button -->
                <v-col cols="12" class="px-4 pb-4 px-md-3" :class="brief ? 'pb-6' : ''" v-if="!brief && bottomCheckout">
                    <v-alert v-if="checkoutDisabled && checkoutDisabledReason !== ''" dense class="mt-1 mb-2 mx-0 text-center" color="warning">{{ checkoutDisabledReason }}</v-alert>
                    <v-btn block @click="checkout" :disabled="checkoutDisabled" x-large color="primary"
                           class="elevation-0 ">
                        {{ $t('btn.checkout', {price: currencyFormat(totalPrice)}) }}
                    </v-btn>
                </v-col>
            </v-row>
            </v-card>

    </v-container>

</template>

<script>
    import ShopCurrency from "../../mixins/ShopCurrency";
    import ShopQuantity from "./ShopQuantity";
    import ShopUpsells from "../../components/Shop/ShopUpsells";
    import Constants from "../../api/Constants";
    import FulfilmentTime from "../../plugins/FulfilmentTime";
    import Gtag from "../../mixins/Gtag";
    import Gtm from "../../mixins/Gtm";
    // import ShopBasketItem from "./ShopBasketItem";

    export default {
        name: "ShopBasket",
        components: {
            // ShopBasketItem,
            ShopQuantity,
            ShopUpsells,
        },
        data() {
            return {
                localBasket: [],
                upsellBasket: []
            }
        },
        mixins: [ShopCurrency, Gtag, Gtm],
        watch: {
            upsells: {
                immediate: true,
                handler: function (newValue) {
                    this.upsellBasket = newValue;
                }
            },
            basket: {
                deep: true,
                immediate: true,
                handler: function (newValue) {
                    this.localBasket = newValue;
                }
            }
        },
        props: {
            basket: {type: Array, default: () => []},
            upsells: {type: Array, default: () => []},
            store: {type: String, default: 'lab'},
            brief: {type: Boolean, default: false},
            review: {type: Boolean, default: false},
            language: {type: String, required: true},
            currency: {type: String, required: true},
            bigButtons: {type: Boolean, default: true },
            bottomCheckout: {type: Boolean, default: false },
        },
        methods: {
            isPlain(item) {
                for (let m in item.modifiers) {
                    if (item.modifiers[m].groupCode === 'toppings') {
                        return item.modifiers[m].modifiers.length === 0;
                    }
                }
                return false;
            },
            goToFulfilment() {
                if (this.$store.getters['session/orderType'] === Constants.OrderType.Table) {
                    this.$router.replace({name: 'table-service-change', params: { storeId: this.sessStore.code}});
                } else {
                    this.$router.replace({name: 'fulfilment-time'});
                }
            },
            lineUnitTotal(basketItem) {
                return (basketItem.price + basketItem.modifierPrice);
            },
            lineTotal(basketItem) {
                return basketItem.quantity * this.lineUnitTotal(basketItem);
            },
            checkout() {
                this.$emit('checkout', this.localBasket);
                if(typeof window.fbq != 'undefined') {
                  window.fbq('track', 'InitiateCheckout');
                }
            },
            basketChanged() {
                this.$emit('input', this.localBasket);
            },
            gtmQuantityChangeEvent(args) {
                let itemIdx = args.itemIdx;
                let item = this.localBasket[itemIdx];
                item.quantity = 1; // we can jump only 1 or -1 on quantity changes
                if (args.howMuch > 0) {
                    this.gtmAddToCartEvent(item);
                } else {
                    this.gtmRemoveFromCartEvent(item);
                }
            },
            // eslint-disable-next-line no-unused-vars
            upsellsChanged(newValue) {
                this.$emit('upsells', newValue);
            },
            deleteItem(itemIdx) {
                this.gtmRemoveFromCartEvent(this.localBasket[itemIdx]);
                this.localBasket.splice(itemIdx, 1);
                this.$emit('input', this.localBasket);
            },
            editItem(itemIdx) {
                this.$emit('edit-item', itemIdx);
            }
        },
        computed: {
            storeTelephone() {
                if (typeof this.sessStore === 'undefined' || !('telephone' in this.sessStore)) {
                    return '';
                }
                return this.sessStore.telephone;
            },
            checkoutDisabled() {
                  return this.checkoutDisabledReason !== null;
            },
            checkoutDisabledReason() {
                let ftValid = !this.fulfilmentTimeValid;
                if (this.totalPrice === 0) return '';
                let maxBasketPrice = parseInt(this.$store.getters['session/maxOrderValue'])  * 100;
                let maxBasketSize = this.$store.getters['session/maxOrderMains'];
                if (this.totalPrice > maxBasketPrice) return this.$t('error.exceededMaxBasketPrice', { maxPrice: this.$n(maxBasketPrice / 100, 'currencyNoDecimal')});
                if (this.totalMains > maxBasketSize) return this.$t('error.exceededMaxBasketSize', { maxSize: maxBasketSize});

                if (ftValid) {
                    return this.$t('error.fulfilmentTimeInvalid');
                }

                return null;
            },
            fulfilmentTimeValid() {
                if (this.sessFulfilmentTime === null) return false;

                // if it is not for today then the passage of time doesn't matter
                if (this.sessFulfilmentDate.date !== this.now.date28) return true;

                let time = FulfilmentTime.validateFulfilmentTime(this.sessFulfilmentTime, this.sessFulfilmentTimes, this.now);
                if (time == null || time.time !== this.sessFulfilmentTime.time) {
                    this.$store.commit('session/fulfilmentTime', time);
                }
                if (time === null) return false;

                // If the last fulfilment time is less than now then error
                // noinspection RedundantIfStatementJS
                if (this.sessLatestFulfilmentTime < this.now.time28Int) return false;

                return true;
            },
            totalMains() {
                let mains = 0;
                for (let i in this.basket) {
                    switch (this.basket[i].categoryCode.toLowerCase()) {
                        case 'burgers':
                        case 'dogs':
                        case 'sandwiches':
                            mains += this.basket[i].quantity;
                            break;
                    }
                }
                return mains;
            },
            sessStore() {
                return this.$store.getters['app/stores'][this.$store.getters['session/storeId']];
            },
            sessStoreName() {
                if (typeof this.sessStore === 'undefined') return '';
                return this.sessStore.name;
            },
            tableNumber() {
                return this.$store.getters['session/tableNumber'];
            },
            tableService() {
                return this.sessOrderTypeRaw === 'Table';
            },
            sessOrderType() {
                return this.$t('orderType.' + this.sessOrderTypeRaw);
            },
            sessOrderTypeRaw() {
                return this.$store.getters['session/orderType'];
            },
            sessFulfilmentDate() {
                let fulfilmentDate = this.$store.getters['session/fulfilmentDate'];

                return typeof fulfilmentDate === 'undefined' || fulfilmentDate === null ? {desc: ''} : fulfilmentDate;
            },
            sessFulfilmentTime() {
                let fulfilmentTime = this.$store.getters['session/fulfilmentTime'];

                return  typeof fulfilmentTime === 'undefined' || fulfilmentTime === null ? {desc: ''} : fulfilmentTime;
            },
            sessLatestFulfilmentTime() {
                let latestFulfilmentTime = this.$store.getters['session/latestFulfilmentTime'];
                return  typeof latestFulfilmentTime === 'undefined' || latestFulfilmentTime === null ? -999 : latestFulfilmentTime;
            },
            sessFulfilmentTimes() {
                let fulfilmentTimes = this.$store.getters['session/fulfilmentTimes'];
                if (typeof fulfilmentTimes === 'undefined' || fulfilmentTimes === null) return [];
                return fulfilmentTimes;
            },
            totalPrice() {
                let total = 0;
                for (let b in this.basket) {
                    total += this.lineTotal(this.basket[b]);
                }
                return total;
            },
            totalItems() {
                let total = 0;
                for (let b in this.basket) {
                    total += this.basket[b].quantity;
                }
                return total;
            },
        },
    }
</script>

<style scoped>

@media (min-width: 800px) {
    .basketCase {
        position: sticky;
        top: 136px;
        margin-top: 31px;
        width: 362px;
    }

    .wholeBasket {
        width: 350px;
    }

    .basketContent {
        max-height: 68vh;
        overflow: hidden;
        overflow-y: auto;
    }
}

@media (max-height: 800px) {
    .basketContent {
        max-height: 60vh;
    }
}

.mini-checkout-items {
    position: absolute;
    left: 0;
}

.mini-checkout-price {
    position: absolute;
    top: 0;
    right: 0;
}

.modifierItem + .modifierItem:before {
    content: ", ";
}

</style>

<script>
    export default {
        data() {
            return {
                currencyFormatter: null
            }
        },
        beforeMount() {
            this.currencyFormatter = new Intl.NumberFormat(this.language, {
                style: 'currency',
                currency: this.currency
            });
        },
        methods: {
            getPriceFromPriceObj(priceObj, currency, defaultPrice) {
                if (typeof defaultPrice === 'undefined') defaultPrice = 0;
                if (priceObj === null) return defaultPrice;
                if (typeof priceObj[currency] === 'undefined') return defaultPrice;

                return priceObj[currency];
            },
            priceFormat(values) {
                if (this.currencyFormatter === null) return values;
                let value = this.getPriceFromPriceObj(values, this.currency, 0);

                return this.currencyFormatter.format(value / 100);
            },
            currencyFormat(value) {
                if (this.currencyFormatter === null) return value;

                return this.currencyFormatter.format((value === null ? 0 : value) / 100);
            }
        },

    }
</script>

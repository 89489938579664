<template>
    <page-layout>
        <v-container fluid class="storeResults">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col cols="12" md="1" class="resultsSide flex-grow-0 flex-shrink-0 mb-0">

                    <v-row>
                        <v-col cols="12" class="px-4 pr-md-10">
                            <v-row>
                                <v-col class="nolo-h4 pt-12 px-6 pb-8">{{$t('closestFiveGuys')}}</v-col>
                            </v-row>
                            <v-card flat class="mx-2 mr-sm-0">
                                <v-row class="mx-1 mb-0">
                                    <v-col cols="8" class="text-uppercase">{{ locationDescription }}</v-col>
                                    <v-col cols="4" class="text-right">
                                        <router-link :to="{name: 'start-order-location'}">{{ $t('btn.change') }}</router-link>
                                    </v-col>
                                </v-row>
                            </v-card>
                        </v-col>
                        <v-col cols="12" class="nolo-body black--text px-6 pb-1">
                            {{ this.stores.length}} {{ $t('closeToYou') }}
                            <v-divider class="mt-1"></v-divider>
                        </v-col>
                    </v-row>
                    <v-col cols="12" class="nolo-body overflow-y-auto mt-4 mb-1" ref="storelist">
                        <store-list @store-select="storeSelected" :stores="stores" />
                    </v-col>
                </v-col>

                <v-col cols="1" class="hidden-sm-and-down mapSide flex-grow-1 pa-0" style="z-index: 1;">
                    <stores-map :customer-location="customerLocation" :stores="stores" @store-scroll-to="storeScrollTo"></stores-map>
                </v-col>

            </v-row>
        </v-container>
    </page-layout>
</template>

<script>

    import StoreUtil from "../../plugins/StoreUtil";

    import StoreList from "../../components/StoreList";
    import StoresMap from "../../components/StoresMap";

    export default {
        name: "StoreResults",
        components: {StoresMap, StoreList},
        data() {
            return {}
        },
        mounted() {
        },
        methods: {
            storeScrollTo(store) {
                this.$vuetify.goTo('#store-' + store.id, { offset: -80, container: this.$refs.storelist });
            },
            storeSelected(data) {
              if (data.externalStoreLink != null && data.externalStoreLink !== "" && !data.externalStoreLink.trim().startsWith("&orderType=") ) {
                window.location.href = data.externalStoreLink.replace(/&amp;/g, '&');
              } else {

                if(typeof window.ytag != 'undefined') {
                  //window.ytag('conversion', {'cid': '65182368-4403-4dbb-8578-b4f171d0c336', 'cv': '23.00'});
                }

                this.$store.commit('session/storeId', data.storeId);
                this.$store.commit('session/orderType', data.orderType);
                this.$router.push({name: 'fulfilment-time'});
              }
            }
        },
        computed: {
            stores() {
                return StoreUtil.storesByDistance(this.$store.getters['app/stores'], this.customerLocation, StoreUtil.MAX_STORE_RESULTS);
            },
            locationDescription() {
                return this.$store.getters['session/location'];
            },
            customerLocation() {
                return this.$store.getters['session/gpsLocation'];
            },
        }
    }
</script>


<style>

</style>

<style scoped>

.nolo-h3 {
    text-align: center;
}

@media (min-width: 800px) {
    .nolo-h3 {
        text-align: left;
    }

    .storeResults {
        padding: 0 0 0 12px;
    }
    .resultsSide {
        min-width: 390px !important;
        max-width: 390px !important;
    }
    .mapSide {
        min-width: calc(100% - 390px) !important;
        max-width: 100% !important;
    }
}

</style>

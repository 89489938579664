<template>
    <v-card class="grow d-flex flex-column">
        <v-card-text class="d-flex pa-4 pr-3 overflow-y-auto shop-item-configure text--primary" v-resize="resizedConfig">
            <v-row>
                <v-col cols="12">
                    <div class="warning text-center py-2 px-2 nolo-p3 mt-1 mb-4"
                         v-if="$te('category.caution.' + categoryCode)">
                        <v-icon small left>mdi-alert-circle-outline</v-icon>
                        {{ $t('category.caution.' + categoryCode)}}
                    </div>
                    <div class="shop-item-title">
                        <v-flex class="grow nolo-h3 text-left">{{ item.name[language] }}</v-flex>
                    </div>
                    <div>
                        <!--                    <v-img cover v-if="item.imageUrl" :src="item.imageUrl" :aspect-ratio="16/9" style="max-height: 320px;" />-->
                        <p class="nolo-dark-grey--text mt-2" v-if="item.description[language]" v-html="item.description[language]"></p>
                        <v-divider v-else class="my-4"/>
                        <shop-modifier-group @new-price="newPrice"
                                             :init-config="getPreConfigFor(modifiers[modifierGroupId].groupCode)"
                                             @change="newModifier"
                                             :clear="groupToClear"
                                             @cleared="() => groupToClear = ''"
                                             :show-errors="showErrors"
                                             :modifier-group-id="modifierGroupId" :language="language"
                                             :currency="currency"
                                             :flat-menu="flatMenu"
                                             :key="modifierGroupId"
                                             v-for="modifierGroupId in item.modifierGroups">
                        </shop-modifier-group>
                        <div class="title text-uppercase font-weight-black pb-3">{{ $t('wantMore') }}</div>
                        <div class="d-flex mb-2 px-sm-loads">
                            <shop-quantity xLarge v-model="quantity"/>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
        <v-container fluid>
            <v-row dense>
                <v-col cols="12" md="4">
                    <v-btn block outlined large color="primary" @click="$emit('close')">{{$t('btn.cancel')}}</v-btn>
                </v-col>
                <v-col cols="12" md="8">
                    <v-btn block large depressed color="primary grow" @click="addItemAsk">
                        {{ $t(editing ? 'btn.editItem' : 'btn.addItem', {price: currencyFormat(totalPrice)} )}}
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <v-dialog v-model="showAreYourSure" max-width="640">
            <v-card class="text-center">
                <v-row no-gutters>
                    <v-col cols="12" class="mt-15 mb-4">
                        <v-icon size="60">mdi-alert-circle-outline</v-icon>
                    </v-col>
                    <v-col cols="12" class="nolo-h3 text-md-lower">
                        {{ $t('noToppings') }}
                    </v-col>
                    <v-col cols="12" class="my-4">
                        {{ $t('message.justCheckingNoToppings') }}
                    </v-col>
                </v-row>
                <v-card-actions class="pb-12 pb-md-15">
                    <v-row>
                        <v-col cols="12" md="5" offset-md="1">
                            <v-btn outlined block class="wrap-it" color="secondary" @click="showAreYourSure = false">
                                {{ $t('btn.addToppings') }}
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="5">
                            <v-btn block class="wrap-it" color="primary" @click="addItem">
                                {{ $t('btn.dontAddToppings') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>

    import ShopModifierGroup from "./ShopModifierGroup";
    import ShopQuantity from "./ShopQuantity";
    import ShopCurrency from "../../mixins/ShopCurrency";

    export default {
        name: "ShopItemConfigure",
        data() {
            return {
                groupToClear: '',
                showAreYourSure: false,
                quantity: 1,
                modifierPriceTotal: 0,
                modifierPrices: [],
                modifiers: {},
                showErrors: false,
                valid: true
            }
        },
        mixins: [ShopCurrency],
        beforeMount() {
            for (let mg in this.item.modifierGroups) {
                let modifierGroupId = this.item.modifierGroups[mg];
                this.modifierPrices[modifierGroupId] = 0;
                let modifierGroup = this.flatMenu.modifierGroups.find(el => el.id === modifierGroupId);
                this.$set(this.modifiers, modifierGroupId,
                    {
                        id: modifierGroupId,
                        groupCode: modifierGroup.code,
                        code: '',
                        name: '',
                        price: 0,
                        qty: 0,
                        valid: false
                    }
                );
            }
            this.quantity = this.initQty;
        },
        props: {
            flatMenu: {type: Object, required: true},
            categoryCode: {type: String, default: 'generic'},
            item: {type: Object, required: true},
            initQty: {type: Number, required: true},
            language: {type: String, required: true},
            preConfig: {type: Object, required: true},
            currency: {type: String, required: true},
            editing: {type: Boolean, default: false}
        },
        components: {
            ShopModifierGroup,
            ShopQuantity
        },
        methods: {
            getPreConfigFor(modifierGroupCode) {
                if (typeof this.preConfig[modifierGroupCode] === 'undefined') return [];
                return this.preConfig[modifierGroupCode]
            },
            newPrice(newPrice) {
                this.$set(this.modifierPrices, newPrice.id, newPrice.price);
                this.modifierPriceTotal = 0;
                for (let mp in this.modifierPrices) {
                    this.modifierPriceTotal += this.modifierPrices[mp];
                }
            },
            clearModifierGroup(groupCode) {
                for (let i in this.modifiers) {
                    if (this.modifiers[i].groupCode === groupCode) {
                        this.groupToClear = i;
                    }
                }
            },
            newModifier(item) {
                this.$set(this.modifiers, item.id, {...item});
                this.valid = true;
                for (let mv in this.modifiers) {
                    this.valid = this.modifiers[mv].valid && this.valid;
                }
            },
            addItemAsk() {
                if (this.hasEmptyToppings) {
                    this.showAreYourSure = true;
                } else {
                    this.addItem();
                }
            },
            addItem() {
                if (!this.valid) {
                    this.showErrors = true;
                    return;
                }

                let addedWhat = {
                    id: this.item.id,
                    code: this.item.code,
                    name: this.item.name,
                    price: this.itemPrice,
                    modifierPrice: this.modifierPriceTotal,
                    quantity: this.quantity,
                    lineTotal: this.totalPrice,
                    modifiers: this.modifiers,
                    categoryCode: this.categoryCode,
                    currency: this.currency
                };

                this.$emit('add-basket', addedWhat);
                this.$emit('close');

                if(typeof window.fbq != 'undefined') {
                  window.fbq('track', 'AddToCart');
                }

            },
            resizedConfig() {
                this.$nextTick(() => {
                    let vh = window.innerHeight * 0.01;
                    document.documentElement.style.setProperty('--vh', `${vh}px`);
                });
            }
        },
        computed: {
            hasEmptyToppings() {
                const me = this;
                let result = false;
                Object.keys(this.modifiers).forEach(key => {
                    let modifier = me.modifiers[key];
                    if (modifier.groupCode === 'toppings' && modifier.modifiers.length === 0) {
                        result = true;
                    }
                });
                return result;
            },
            itemPrice() {
                return this.item.price ? this.item.price[this.currency] : 0;
            },
            totalPrice() {
                return this.item.price ? this.quantity * (this.itemPrice + this.modifierPriceTotal) : 0;
            }
        }
    }
</script>

<style scoped>

    @media (min-width: 480px) {
        .px-sm-loads {
            padding-right: 10vw !important;
            padding-left: 10vw !important;
        }
    }

    @media (min-width: 800px) {
        .text-md-lower {
            text-transform: none;
        }
    }

    .wrap-it {
        max-width: 100%;
        white-space: normal;
    }
    .wrap-it >>> .v-btn__content {
        max-width: 100%;
    }

</style>

<template>
    <v-dialog :value="value" @cancel="cancel" max-width="480">
        <v-card>
            <v-card-text class="text-center">
                <v-icon size="192">mdi-emoticon-sad-outline</v-icon>
                <div class="nolo-subtitle text-center">{{$t('error.oops')}}</div>
                <p class="nolo-body text-center my-8">
                    {{ oopsMessage }}
                </p>
                <v-btn color="secondary" outlined @click="cancel">OK</v-btn>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: 'oops-dialog',
        props: {
            value: {type: Boolean, default: false }
        },
        methods: {
            cancel() {
                this.$emit('input', false);
            }
        },
        computed: {
            oopsMessage() {
                return this.$store.getters['app/oopsMessage'];
            }
        }
    }
</script>

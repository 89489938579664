import vuexStore from '../store/index';


function timeToJSDate(time) {

    let now = new Date(vuexStore.getters['app/now'].jsDate);
    let hours = Math.floor(time / 100);
    let mins = time % 100;

    now.setHours(hours, mins, 0, 0);

    return now;

}

function formatTime(date) {
    return date.toLocaleString('en-GB', { hour: 'numeric', minute: 'numeric'})
}

function openingInfo(store, curbside) {

    let now = new Date(vuexStore.getters['app/now'].jsDate);
    let todayOffset = now.getDay();
    // JS is Sun-Sat, PHP is Mon-Sun
    todayOffset = todayOffset === 0 ? 6 : todayOffset - 1;

    let openData = curbside ? store.hours_curbside : store.hours;
    if (openData === '' || openData[0] === false) {
        openData = store.hours;
    }

    let time = now.getHours() * 100 + now.getMinutes();
    if (time < 500) {
        time += 2400;
        todayOffset--;
        if (todayOffset < 0) todayOffset = 6;
    }

    // openData[todayOffset] = ['0700', '2000'];
    // time = '2200';

    try {

        return {
            open: time >= openData[todayOffset][0] && time <= openData[todayOffset][1],
            opening: time <= openData[todayOffset][1],
            opens: openData[todayOffset][0],
            openDate: timeToJSDate(openData[todayOffset][0]),
            closes: openData[todayOffset][1],
            closeDate: timeToJSDate(openData[todayOffset][1]),
            curbsideSame: store.hours_curbside[todayOffset] === false || JSON.stringify(store.hours_curbside[todayOffset]) === JSON.stringify(store.hours[todayOffset])
        }
    } catch {
        return {
            open: null,
            opening: false,
            opens: null,
            openDate: null,
            closes: null,
            closeDate: null,
            curbsideSame: true
        }
    }

}


function getStores(vue) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'GET',
                url: '/apiui/store/storeList',
            })
            .then(function (response) {
                let stores = response.data.data;
                vue.$store.commit('app/stores', stores);
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

function getLocation(vue, location) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/store/locate',
                data: {
                    location: location
                }
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

function getFulfilmentTimesFor(vue, storeId, orderType) {
    return new Promise(function (resolve, reject) {
        vue.$http(
            {
                method: 'POST',
                url: '/apiui/store/fulfilment_times',
                data: {
                    storeId: storeId,
                    orderType: orderType,
                }
            })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    });
}

export default {
    openingInfo: openingInfo,
    getStores: getStores,
    getLocation: getLocation,
    getFulfilmentTimesFor: getFulfilmentTimesFor,
    formatTime: formatTime
}

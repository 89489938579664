<template>
    <v-layout>
        <v-app-bar color="white" app flat :height="isMobile ? '60' : '80'">
            <v-container class="header-content align-center" d-flex>
                <a class="site-logo" :href="homeURL">
                    <site-logo/>
                </a>
                <v-icon v-if="showLoginStatus" size="32" class="ml-3" :color="isLoggedIn ? 'secondary' : '#DDD'">{{
                    isLoggedIn ? 'mdi-account-outline' : 'mdi-account-off-outline' }}
                </v-icon>
                <v-chip x-small color="info" class="route-guard" v-if="showGuard" @click="versionInfo = true">
                    <v-icon x-small left>mdi-shield-key-outline</v-icon>
                    {{ guardStep }}
                    <v-icon class="ml-3" right x-small :color="isChecked ? 'white' : 'info lighten-1'">mdi-check
                    </v-icon>
                    <v-icon class="ml-3" right x-small :color="isPaid ? 'white' : 'info lighten-1'">
                        mdi-credit-card-check-outline
                    </v-icon>
                    <v-icon class="ml-3" right x-small :color="isComplete ? 'white' : 'info lighten-1'">mdi-cart-check
                    </v-icon>
                </v-chip>
                <v-spacer/>
                <v-layout class="shrink hidden-sm-and-down">
                    <ul id="menu-main-menu" class="main-menu px-0">
                        <li class="menu-item menu-item-type-post_type menu-item-object-page main-menu__item"
                            :class="classForLink(link)"
                            v-for="link in refactoredHeaderLinks" :key="link.name">

                            <a :target="link.target"
                               :class="isOnAccountPage && isAccountLink(link) ? 'current-menu-item' : ''"
                               :href="link.link" class="main-menu__link">{{ link.name }}</a>
                        </li>
                    </ul>
                </v-layout>

                <v-layout class="shrink hidden-md-and-up align-center" v-if="isAppMode">
                    <router-link :to="{name:'account'}">
                        <img :src="require('../../assets/icon-account.svg')"/>
                    </router-link>
                </v-layout>

            </v-container>
        </v-app-bar>


        <button @click.stop="drawer = !drawer" v-if="!isAppMode"
                class="hidden-md-and-up hamburger main-menu__burger" :class="drawer ? ' is-open' : ''"
                style="z-index: 20">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </button>

        <v-navigation-drawer
                v-model="drawer"
                app
                temporary
                left
                width="100vw"
                heigth="100vh"
        >
            <v-row no-gutters id="menu-mobile-menu" class="d-flex fill-height align-center">
                <v-col cols="12">
                    <v-row class="text-center font-weight-black text-uppercase">
                        <v-col cols="12" :class="mobileClassForLink(link)" v-for="link in appLinks.header"
                               :key="link.link">
                            <a :href="link.link" :target="link.target"
                               :class="isOnAccountPage && isAccountLink(link) ? 'current-menu-item' : ''"
                               class="item-link">{{ link.name }}</a>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

        </v-navigation-drawer>

    </v-layout>
</template>

<script>
    import APIOrder from "../../api/APIOrder";
    import SiteLogo from "../UI/SiteLogo";

    export default {
        name: "AppHeader",
        data() {
            return {
                // isAppMode: false,
                drawer: false
            }
        },
        components: {SiteLogo},
        methods: {
            isAccountLink(link) {
                return link.link.indexOf('/#/account') >= 0;
            },
            classForLink(link) {
                if (link.link.indexOf('/#/start-order') >= 0) {
                    return 'main-menu__btn';
                }

                return '';
            },
            mobileClassForLink(link) {
                if (link.link.indexOf('/#/start-order') >= 0) {
                    return 'menu-item-button';
                }

                return 'menu-item';
            }
        },
        computed: {
            homeURL() {
                if (this.isAppMode) {
                    let props = this.$router.resolve({name: 'start-order'});
                    return props.href;
                } else {
                    return this.cmsDomain;
                }
            },
            versionInfo: {
                get() {
                    return this.$store.getters['app/versionInfo']
                },
                set(newValue) {
                    this.$store.commit('app/versionInfo', newValue);
                }
            },
            showGuard() {
                return this.$store.getters['app/showGuard'];
            },
            showLoginStatus() {
                return this.$store.getters['app/showLoginStatus'];
            },
            isChecked() {
                return this.$store.getters['session/isChecked'];
            },
            isPaid() {
                return this.$store.getters['session/isPaid'];
            },
            isComplete() {
                return this.$store.getters['session/isComplete'];
            },
            appLinks() {
                return this.$store.getters['app/links'];
            },
            guardStep() {
                return APIOrder.getProgressText(this.$store.getters['session/orderProgress']);
            },
            isOnAccountPage() {
                return this.$route.path.substring(0, 8) === '/account';
            },
            isMobile() {
                return this.$vuetify.breakpoint.name === 'xs' || this.$vuetify.breakpoint.name === 'sm';
            },
            refactoredHeaderLinks() {
                let result = [];
                for (let h in this.appLinks.header) {
                    let link = JSON.parse(JSON.stringify(this.appLinks.header[h]));
                    const pathStartIndex = link.link.indexOf('/#/');
                    if (window.location.hostname === 'localhost' && pathStartIndex >= 0) {
                        link.link = link.link.substring(pathStartIndex);
                    }
                    result.push(link);
                }
                return result;
            }
        }
    }
</script>

<style scoped>

    .route-guard {
        top: -18px;
        left: -130px;
    }

    @media (min-width: 800px) {
        .route-guard {
            top: 6px;
            left: 6px;
            position: absolute;
        }
    }

    @media (min-width: 1200px) {
        .route-guard {
            top: -26px;
            left: -195px;
            position: relative;
        }
    }

    .header-content {
        padding: 0 16px;
    }

    @media (min-width: 800px) {
        .header-content {
            max-width: 920px;
            padding: 0;
        }
    }

    @media (min-width: 960px) {
        .header-content {
            max-width: 960px;
        }
    }

    @media (min-width: 1200px) {
        .header-content {
            max-width: 1155px;
            padding: 0 18px 0 28px;
        }
    }

    .site-logo {
        width: 130px;
        display: inline-block;
    }

    @media (min-width: 1200px) {
        .site-logo {
            width: 195px;
        }
    }

    .img-fluid, .site-logo svg {
        max-width: 100%
    }

    .img-fluid {
        height: auto;
        vertical-align: middle
    }

    .main-menu {
        list-style-type: none;
        margin: 0
    }

    @media (max-width: 799.98px) {
        .main-menu {
            background-color: #fff;
            z-index: 48;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            padding-top: 100px;
            transform: scaleX(0);
            transition: all .2s ease-in-out;
            transform-origin: right;
        }

        .menu-open .main-menu {
            transform: scaleX(1)
        }
    }

    @media (min-width: 800px) {
        .main-menu {
            display: flex;
            align-items: center;
        }
    }

    @media (max-width: 799.98px) {
        .main-menu__item {
            margin-bottom: 40px
        }
    }

    @media (min-width: 800px) {
        .main-menu__item {
            margin-left: 32px
        }
    }

    .main-menu__link {
        font-size: 14px;
        color: #000;
        text-decoration: none;
        font-weight: 600;
        text-transform: uppercase;
        white-space: nowrap;
        letter-spacing: 0.05em;
        line-height: 20px;
    }

    .main-menu__btn .main-menu__link {
        display: block;
        font-size: 16px;
        font-weight: 900;
        background: var(--v-primary-base);
        color: #fff !important;
        border-radius: 8px;
        padding: 14px 16px;
    }

    .main-menu__btn .main-menu__link:hover {
        background-color: var(--v-nolo-dark-red-base);
    }

    .current-menu-item.main-menu__link, .main-menu__link:hover {
        color: var(--v-primary-base);
    }

    .current-menu-item.item-link {
        color: var(--v-primary-base);
    }

    .main-menu__link:hover::before {
        opacity: 0;
    }

    /* Mobile Nav */

    #menu-mobile-menu {
        align: center;
    }

    #menu-mobile-menu .menu-item {
        margin: 8px 0;
    }

    #menu-mobile-menu .menu-item-button {
        padding: 18px 0;
        margin: 20px 0;
    }

    .item-link {
        color: #000;
        font-size: 20px;
        line-height: 28px;
        text-decoration: none;
        letter-spacing: 0.025em;
    }

    .item-link:not(.item-link-button).router-link-active {
        color: var(--v-primary-base);
    }

    .menu-item-button > .item-link {
        background: var(--v-primary-base);
        color: #fff;
        font-size: 16px;
        line-height: 16px;
        border-radius: 8px;
        padding: 20px 24px;
    }

    /* Hamburger */

    .hamburger {
        position: fixed;
        top: 18px;
        right: 20px;
        width: 20px;
        height: 20px;
        transform: rotate(0deg);
        transition: .3s ease-in-out;
        cursor: pointer;
        background: hsla(0, 0%, 100%, .001);
        border: none
    }

    .hamburger:focus {
        outline: none
    }

    .hamburger span {
        display: block;
        position: absolute;
        height: 4px;
        width: 100%;
        background: var(--v-primary-base);
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .15s ease-in-out
    }

    .hamburger span:first-child {
        top: 0
    }

    .hamburger span:nth-child(2), .hamburger span:nth-child(3) {
        top: 8px
    }

    .hamburger span:nth-child(4) {
        top: 16px
    }

    .hamburger.is-open span:first-child {
        top: 8px;
        width: 0;
        left: 50%
    }

    .hamburger.is-open span:nth-child(2) {
        transform: rotate(45deg)
    }

    .hamburger.is-open span:nth-child(3) {
        transform: rotate(-45deg)
    }

    .hamburger.is-open span:nth-child(4) {
        top: 8px;
        width: 0;
        left: 50%
    }

</style>

<template>
    <v-row class="pt-12" :class="'category-' + categoryNo" v-if="show">
        <v-col cols="12">
            <div class="warning text-center pa-1 nolo-p3 mt-2 mb-4" v-if="$te('category.caution.' + category.code)">
                <v-icon>mdi-alert-circle-outline</v-icon>
                {{ $t('category.caution.' + category.code)}}
            </div>
            <div v-intersect="observer" :data-category="categoryNo" class="nolo-h3">{{ category.name[language] }}</div>
        </v-col>
        <v-col cols="12" v-if="category.description[language]" class="py-0 nolo-p2 nolo-dark-grey--text">
            {{ category.description[language] }}
        </v-col>
        <v-col>
            <v-row class="pt-2">
                <v-col cols="12" lg="6" class="shopItemContainer" v-for="itemId in category.salesItems" :key="itemId">
                    <shop-item :item-id="itemId" :language="language" :currency="currency" :card-style="cardStyle"
                               @selected-item="item => $emit('selected-item', {item: item, category: category})"
                               :on-order="basketQuantity(itemId)" @increase-card-size="increaseCardSize()"
                               :flat-menu="flatMenu"
                    />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import ShopItem from "./ShopItem";

    export default {
        name: "ShopCategory",
        cardStyle:  "shop-item",
        components: {
            ShopItem
        },
        data() {
            return {
                cardStyle: "shop-item",
                observer: {
                    handler: this.catScrolled,
                    options: {
                        rootMargin: '0px',
                        threshold: 1
                    }
                }
            }
        },
        props: {
            show: {type: Boolean, default: true},
            flatMenu: {type: Object, required: true},
            categoryNo: {type: Number, required: true},
            language: {type: String, required: true},
            currency: {type: String, required: true},
            basketQuantities: {
                type: Object, default: () => {
                }
            }
        },
        methods: {
            basketQuantity(itemId) {
                if (typeof this.basketQuantities[itemId] === 'undefined') {
                    return 0;
                } else {
                    return this.basketQuantities[itemId];
                }
            },
            catScrolled(e) {
                let catNo = e[0].target.getAttribute('data-category');
                if (e[0].isIntersecting) this.$emit('scrolled-to', catNo);
            },
            increaseCardSize() {
              this.cardStyle = "shop-item-with-product-info";
              this.$emit('recalculate-card-size');
            },
        },
        computed: {
            category() {
                return this.flatMenu.categories[this.categoryNo];
            }
        }
    }
</script>

<style scoped>

</style>

function httpOptionsWithToken(vue, options) {
    if (typeof options === 'undefined') {
        options = { headers: {} };
    }
    options.headers = this.httpHeadersWithToken(vue, options.headers);
    return options;

}

function httpHeadersWithToken(vue, headers) {
    let allHeaders = {};
    if (token(vue) !== null && token(vue) !== "null") {
        allHeaders['X-NOLO-Token'] = token(vue);
    }
    if (sessionId(vue) !== null && sessionId(vue) !== "null") {
        allHeaders['X-NOLO-Session'] = sessionId(vue);
    }

    if (typeof headers !== 'undefined') Object.assign(allHeaders, headers);
    return allHeaders
}

function sessionTracker(vue, response) {

    if (typeof (response.data) === 'undefined' || typeof response.data.session === 'undefined') return;

    vue.$store.commit('session/sessionId', response.data.session)
}

function token(vue) {
    return vue.$store.getters['session/token'];
}

function sessionId(vue) {
    return vue.$store.getters['session/sessionId'];
}

function simpleLanguage(language) {

    let simpleLang = language.split('-')[0];
    switch (simpleLang) {
        case 'en':
        case 'es':
        case 'de':
        case 'fr':
            return simpleLang;
        default:
            // if all else fails english (or whatever is in VUE_APP_LOCALE) is our friend.
            return process.env.VUE_APP_LOCALE || 'en';
    }

}


export default {
    token: token,
    httpHeadersWithToken: httpHeadersWithToken,
    httpOptionsWithToken: httpOptionsWithToken,
    sessionTracker: sessionTracker,
    simpleLanguage: simpleLanguage
}
